import { TABLE_COLUMN_TYPES, claimSubmitStatusColorOpts } from "constant";
import { mergePersonlizationColumns } from "utils";

export const DEFAULT_PERSONALIZE = [
  {
    itemKey: "createdAt",
    title: "Claim Date",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 1,
    width: "100%",
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: "pat_name_f",
    title: "First Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 2,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "pat_name_l",
    title: "Last Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 3,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "pat_email",
    title: "Email",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 4,
    width: "100%",
    type: TABLE_COLUMN_TYPES.email,
  },
  {
    itemKey: "pat_phone",
    title: "Phone Number",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 5,
    width: "100%",
    type: TABLE_COLUMN_TYPES.phone,
  },

  {
    itemKey: "ref_provider",
    title: "Ref Provider",
    filterKey: "ref_npi",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 50,
    width: "100%",
    customCellRenderer: true,
  },

  {
    itemKey: "pat_addr_1",
    title: "Address",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 6,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },

  {
    itemKey: "pat_addr_2",
    title: "Apt/Unit#",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 7,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "pat_city",
    title: "City",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 8,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "pat_state",
    title: "State",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 9,
    width: "100%",
    type: TABLE_COLUMN_TYPES.upperCaseText,
  },
  {
    itemKey: "pat_zip",
    title: "Zip Code",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 10,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "pat_dob",
    title: "DOB",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 11,
    width: "100%",
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: "pat_sex",
    title: "Gender",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 12,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "payer_name",
    filterKey: "payerid",
    title: "Payer",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 13,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "ins_number",
    title: "Member ID",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 14,
    width: "100%",
    type: TABLE_COLUMN_TYPES.upperCaseText,
  },
  {
    itemKey: "claimNo",
    title: "Claim ID",
    textAlign: "left",
    isCheck: true,
    textOverflow: "visible",
    id: 15,
    width: "100%",
    type: TABLE_COLUMN_TYPES.upperCaseText,
  },
  {
    itemKey: "total_charge",
    title: "Claim Amount",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 16,
    width: "100%",
    type: TABLE_COLUMN_TYPES.currency,
  },
  {
    itemKey: "adjustedAmount",
    title: "Adjusted Amount",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 17,
    width: "100%",
    type: TABLE_COLUMN_TYPES.currency,
  },
  {
    itemKey: "paidAmount",
    title: "Paid Amount",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 18,
    width: "100%",
    type: TABLE_COLUMN_TYPES.currency,
  },
  {
    itemKey: "eraIds",
    title: "ERA",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 19,
    width: "100%",
    type: TABLE_COLUMN_TYPES.image,
  },
  {
    itemKey: "from_date_1",
    title: "DOS",
    textAlign: "left",
    isCheck: true,
    textOverflow: "visible",
    id: 20,
    width: "100%",
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: "paid_ins_processed",
    title: "Paid By",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 21,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "status",
    title: "Status",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 22,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
    colorObj: claimSubmitStatusColorOpts,
    customCellRenderer: true,
  },
  {
    itemKey: "remote_claimid",
    title: "B-ID",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 23,
    width: "100%",
    type: TABLE_COLUMN_TYPES.labelID,
  },
  {
    itemKey: "submittedByName",
    title: "Processed By",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 24,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "checkDate",
    title: "Paid Date",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 25,
    width: "100%",
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: "paymentCheckNo",
    title: "Check/ERA #",
    textAlign: "left",
    isCheck: true,
    textOverflow: "visible",
    id: 26,
    width: "100%",
    type: TABLE_COLUMN_TYPES.upperCaseText,
  },
  {
    itemKey: "accNo",
    title: "Acc. No",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 27,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "invoiceID",
    title: "Invoice ID",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 28,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "invoiceStatus",
    title: "Invoice Status",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 29,
    width: "100%",
    colorObj: claimSubmitStatusColorOpts,
    customCellRenderer: true,
  },
  {
    itemKey: "locationName",
    title: "Client",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 50,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
];

const SENT_COLUMN = [
  {
    itemKey: "reSubmissionDate",
    title: "Date Sent",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 30,
    width: "100%",
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: "daysOpen",
    title: "Days Open",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 31,
    width: "100%",
    type: TABLE_COLUMN_TYPES.number,
  },
  {
    itemKey: "avg_days",
    title: "Avg Days to Pay",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 32,
    width: "100%",
    type: TABLE_COLUMN_TYPES.number,
  },
];
const CLIENT_COLUMN = [
  {
    itemKey: "payer_name",
    title: "Payer",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 13,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "status",
    title: "Claim Status",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 22,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
    colorObj: claimSubmitStatusColorOpts,
    customCellRenderer: true,
  },
  {
    itemKey: "invoiceAmount",
    title: "Invoice Amount",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 30,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
];

const DENIED_COLUMN = [
  {
    itemKey: "paid_ins_processed",
    title: "Denied By",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 21,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "status",
    title: "Claim Status",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 22,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
    colorObj: claimSubmitStatusColorOpts,
    customCellRenderer: true,
  },
  {
    itemKey: "checkDate",
    title: "Date of Denial",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 25,
    width: "100%",
    type: TABLE_COLUMN_TYPES.shortDate,
  },
  {
    itemKey: "appeal_days",
    title: "Days to Appeal",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 30,
    width: "100%",
    type: TABLE_COLUMN_TYPES.number,
  },
  {
    itemKey: "appeal_status",
    title: "Appeal Status",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 31,
    width: "100%",
    type: TABLE_COLUMN_TYPES.text,
  },
];

export const CLAIM_PERSONALIZATION = {
  default: DEFAULT_PERSONALIZE,
  draft: DEFAULT_PERSONALIZE,
  rejected: DEFAULT_PERSONALIZE,
  paid: DEFAULT_PERSONALIZE,
  denied: mergePersonlizationColumns(DEFAULT_PERSONALIZE, DENIED_COLUMN),
  cancelled: DEFAULT_PERSONALIZE,
  submitted: [...DEFAULT_PERSONALIZE, ...SENT_COLUMN],
  patientClient: mergePersonlizationColumns(DEFAULT_PERSONALIZE, CLIENT_COLUMN),
};
