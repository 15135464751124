import { MESSAGE_MODES } from "constant";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { CSVReader } from "react-papaparse";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { setMessage } from "store/features/general/generalAction";
import { userCompanyID } from "utils";

const LocationImportModal = (props) => {
  const { handleClose, handleImport, appContext, importType, handleDownloadSample } = props;
  const dispatch = useDispatch();
  const getSelectedCompany = () =>  userCompanyID.get();

  const [selectedClient, setSelectedClient] = useState(getSelectedCompany());
  
  const handleOnDrop = (data) => {
    if (!selectedClient) {
      dispatch(setMessage("Please select the client first then import", MESSAGE_MODES.error));
      return;
    }
    handleImport(data, selectedClient, importType);
  };
  const handleOnError = (err) => {
    console.log(err);
  };

  const handleOnRemoveFile = (data) => {
    console.log("Remove Data", data);
  };

  return (
    <Modal show animation={true} backdrop="static" onHide={() => handleClose()} centered size={"lg"}>
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Import Client Data
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="form-group-wrapper d-block">

        {handleDownloadSample && (
            <div className="d-flex align-items-center justify-content-around mb-3">
              <div
                style={{
                  textAlign: "right",
                  color: "#42cef5",
                  textAlign: "center",
                  cursor: "Pointer",
                }}
                onClick={() => handleDownloadSample(1)}
              >
                Download Template Info
              </div>
              <div
                style={{
                  textAlign: "right",
                  color: "#42cef5",
                  textAlign: "center",
                  cursor: "Pointer",
                }}
                onClick={() => handleDownloadSample(2)}
              >
                Download Upload Template
              </div>
            </div>
          )}
          {!selectedClient && (
            // {(appContext.user?.isAdmin() || appContext.user?.isClient()) && (
            <div className="col-lg-12 col-12 mb-3">
              <label className="modalLineHeaders mt-0">Clients:</label>
              <Select
                options={appContext.companies.map((s) => {
                  return { ...s, label: s.name, value: s.id };
                })}
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
                blurInputOnSelect={true}
                menuPlacement="auto"
                placeholder="Select Client"
                className="w-100"
                onChange={(e) => setSelectedClient(e.value)}
              />
            </div>
          )}

          <CSVReader
            onDrop={handleOnDrop}
            onError={handleOnError}
            addRemoveButton
            config={{
              header: true,
              skipEmptyLines: true,
            }}
            onRemoveFile={handleOnRemoveFile}
          >
            <span>Drop CSV file here or click to upload.</span>
          </CSVReader>
        </div>
      </Modal.Body>

      <Modal.Footer className="flex-row-reverse">
        <Button variant="secondary" className="headerButton btn-fill" onClick={() => handleClose()}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LocationImportModal;
