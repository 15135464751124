import React, { useState, useMemo } from "react";
import { Button, Modal } from "react-bootstrap";
import { CUSTOM_INS, MEDICATRE_IDS } from "constant";
import { isValidIDNumber, convertToUpper } from "utils";
import ErrorMessage from "components/Message/ErrorMessage";
import InputField from "components/InputField/InputField";
import SwitchToggle from "components/SwitchToggle/SwitchToggle";
import api from "api";
import { useSelector } from "react-redux";
import { selectLoginUser } from "store/features/authentication/authenticationSelectors";
import { insurnaceSelector } from "store/features/insurances/insuranceSelectors";
import CurrencyInput from "components/CurrencyInput/CurrencyInput";
import Loader from "components/Loader/Loader";

const InsuranceDetailsModal = (props) => {
  const { handleClose, hanndleAddIns, handleUpdateIns, title, type, insuranceDetails, insuranceList, user } = props;

  console.log("insuranceDetails", insuranceDetails);

  const [loading, setLoading] = useState(false);

  const loginUser = useSelector(selectLoginUser);

  const { payerList } = useSelector(insurnaceSelector);

  const insuranceCompanies = ["00001", "00002", "00003"];

  const [insuranceData, setInsuranceData] = useState(insuranceDetails);

  const [showError, setShowError] = useState("");

  const InsuranceObject = useMemo(() => {
    if (insuranceData.insuranceCompany) {
      const item = payerList.find((i) => i.value === insuranceData.insuranceCompany);
      if (item) return item;
    }
    return "";
  }, [insuranceData]);

  const alreadyExistIns = (ins, isUpdate) => {
    const existIns = insuranceList.filter((f) => {
      if (isUpdate)
        return (
          f.insuranceCompany === ins.insuranceCompany && f.medicalNo === ins.medicalNo && f.id !== insuranceDetails.id
        );
      return f.insuranceCompany === ins.insuranceCompany && f.medicalNo === ins.medicalNo;
    });
    if (existIns.length > 0) {
      return true;
    }
    return false;
  };

  const handleInsValidation = (insuranceData) => {
    if (!insuranceData.insuranceCompany) {
      setShowError("Payer is required");
      return;
    }

    if (!CUSTOM_INS.includes(insuranceData.insuranceCompany)) {
      if (!insuranceData.medicalNo || !insuranceData.medicalNo.trim()) {
        setShowError(`Medicare ID is required`);
        return;
      }

      if (!isValidIDNumber(insuranceData.medicalNo)) {
        setShowError(`Medicare ID is Invalid`);
        return;
      }

      if (insuranceData.isMedicare === "Y" && !insuranceData.medicareNo) {
        setShowError("Medicare Member ID Not be Empty");
        return;
      }

      if (alreadyExistIns(insuranceData, insuranceDetails.id ? true : false)) {
        setShowError("Already add Same Payer!");
        return;
      }
    }
    return true;
  };

  const handelInsuracneChange = async (e) => {
    setInsuranceData({
      ...insuranceData,
      insuranceCompany: e.value,
      insuranceCompanyCode: e.Name,
      insuranceType: e.payer_type,
      insuranceGroupId: "",
      isMedicare: "N",
      eEligibility: e?.eEligibility || false,
    });
  };

  const handleSave = async () => {
    let eligibiltyResponse = null;

    // if (!CUSTOM_INS.includes(insuranceData.insuranceCompany) && !user.isNew) {
    //   const modifiedEmp = {
    //     ...user,
    //     insuranceCompany: insuranceData.insuranceCompany,
    //     medicalNo: insuranceData.medicalNo,
    //     eEligibility: insuranceData?.eEligibility || false,
    //   };

    //   if (!modifiedEmp.eEligibility) {
    //     setShowError(`You can add deductable and copay manualy.`);
    //     return;
    //   }
    //   try {
    //     setLoading(true);

    //     const { data } = await api.checkManualEligibility(modifiedEmp, loginUser);
    //     console.log("DataInEligibility", data);
    //     setLoading(false);

    //     if (data) {
    //       setInsuranceData({
    //         ...insuranceData,
    //         copay: data.copay,
    //         deductible: data.deductible,
    //         coinsurnace: data.coinsurnace,
    //         out_coinsurnace: data.out_coinsurnace,
    //         out_copay: data.out_copay,
    //         out_copay_specialist: data.out_copay_specialist,
    //         copay_specialist: data.copay_specialist,
    //       });

    //       eligibiltyResponse = {
    //         copay: data.copay,
    //         deductible: data.deductible,
    //         coinsurnace: data.coinsurnace,
    //         out_coinsurnace: data.out_coinsurnace,
    //         out_copay: data.out_copay,
    //         out_copay_specialist: data.out_copay_specialist,
    //         copay_specialist: data.copay_specialist,
    //       };
    //     }
    //   } catch (error) {
    //     console.log("error in check manula eligibility");
    //     setLoading(false);
    //   }
    // }

    const insurance = {
      copay: 0,
      deductible: 0,
      isActive: true,
      medicalNo: "",
      medicareNo: "",
    };

    const insuranceCopy = insuranceCompanies.includes(insuranceData.insuranceCompany)
      ? { ...insuranceData, ...insurance }
      : {
          ...insuranceData,
          ...(eligibiltyResponse && { ...eligibiltyResponse }),
        };

    if (!handleInsValidation(insuranceCopy)) return;
    const insData = { ...insuranceCopy };

    if (insuranceCopy.isNew) {
      Object.assign(insData, { id: "prefix_" + Math.random().toString(36).slice(2, 9) });
      hanndleAddIns(insData);
    } else {
      handleUpdateIns(insData);
    }

    handleClose();
  };

  const showInsuranceDetails = useMemo(
    () => !insuranceCompanies.includes(insuranceData.insuranceCompany),
    [insuranceData.insuranceCompany]
  );

  const handelAdvantatePlan = (e) => {
    const isChecked = e.target.checked;
    const label = isChecked ? "Y" : "N";
    setInsuranceData({
      ...insuranceData,
      isMedicare: label,
    });
  };

  return (
    <>
      <Modal
        animation={true}
        onHide={() => {
          handleClose();
        }}
        className="seperateModal"
        show={true}
        style={{ paddingLeft: "0" }}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: 0,
          }}
        >
          {loading && <Loader />}

          <div className="createClientsWrapper d-block">
            <InputField
              type="dropDown"
              groupWrapper="form-group-wrapper me-2 mt-4"
              inputStyle="w-100"
              labelStyle="mt-0 modalLineHeaders text-capitalize"
              label="Payer"
              options={payerList}
              value={InsuranceObject}
              index="insuranceCompany"
              placeholder="Select Payer"
              handleChange={handelInsuracneChange}
              required
            />
            {!["00002", "00001"].includes(insuranceData.insuranceCompany) && (
              <>
                <InputField
                  type="text"
                  groupWrapper="form-group-wrapper me-2"
                  inputStyle="modalInput"
                  labelStyle="mt-0 modalLineHeaders text-capitalize"
                  label="Member ID"
                  value={insuranceData.medicalNo}
                  index="medicalNo"
                  maxLength={50}
                  placeholder="Member ID"
                  handleChange={(e) =>
                    setInsuranceData({
                      ...insuranceData,
                      medicalNo: convertToUpper(e.target.value),
                    })
                  }
                />

                <InputField
                  type="text"
                  groupWrapper="form-group-wrapper me-2"
                  inputStyle="modalInput"
                  labelStyle="mt-0 modalLineHeaders text-capitalize"
                  label="Group ID"
                  value={insuranceData.insuranceGroupId}
                  index="insuranceGroupId"
                  maxLength={50}
                  placeholder="Enter Group ID"
                  handleChange={(e) =>
                    setInsuranceData({
                      ...insuranceData,
                      insuranceGroupId: convertToUpper(e.target.value),
                    })
                  }
                />
              </>
            )}

            {insuranceData.isMedicare === "Y" && showInsuranceDetails && (
              <InputField
                type="text"
                groupWrapper="form-group-wrapper me-2"
                inputStyle="modalInput"
                labelStyle="mt-0 modalLineHeaders text-capitalize"
                label="Medicare Number"
                value={insuranceData.medicareNo}
                index="medicareNo"
                maxLength={50}
                placeholder="Enter Medicare Number"
                handleChange={(e) =>
                  setInsuranceData({
                    ...insuranceData,
                    medicareNo: convertToUpper(e.target.value),
                  })
                }
              />
            )}

            {insuranceData.insuranceCompany && showInsuranceDetails && (
              <>
                <CurrencyInput
                  value={insuranceData.copay}
                  onChange={(e) => {
                    setInsuranceData({
                      ...insuranceData,
                      copay: e,
                    });
                  }}
                  label="Copay"
                  placeholder="Copay"
                />
                <CurrencyInput
                  value={insuranceData.deductible}
                  onChange={(e) => {
                    setInsuranceData({
                      ...insuranceData,
                      deductible: e,
                    });
                  }}
                  label="Deductible"
                  placeholder="CoDeductiblepay"
                />
              </>
            )}
            {insuranceData?.insuranceCompany &&
              !CUSTOM_INS.includes(insuranceData?.insuranceCompany) &&
              !MEDICATRE_IDS.includes(insuranceData.insuranceCompany) && (
                <div className="d-flex align-items-center justify-content-between column-gap-10 profile-detail mb-3 mr-50-per">
                  <label>Advantage Plan: </label>
                  <SwitchToggle
                    checked={insuranceData.isMedicare === "Y" ? true : false}
                    handleChange={handelAdvantatePlan}
                    color={"blue"}
                  />
                </div>
              )}
            <div className="d-flex align-items-center justify-content-between column-gap-10 profile-detail mb-3 mr-50-per">
              <label>Primary: </label>
              <SwitchToggle
                checked={insuranceData.isPrimary}
                handleChange={(e) =>
                  !insuranceDetails.isPrimary &&
                  setInsuranceData({
                    ...insuranceData,
                    isPrimary: e.target.checked,
                  })
                }
                color={"blue"}
              />
            </div>
          </div>
        </Modal.Body>
        <div className="px-4 my-2" style={{ height: "2vh" }}>
          {showError && <ErrorMessage error={showError} handleChange={() => setShowError("")} />}
        </div>
        <Modal.Footer>
          <div className="saveButton">
            <Button
              style={{ marginBottom: 10 }}
              variant="primary"
              className="modalButtons headerButton btn-fill"
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </Button>
            <Button
              style={{ marginBottom: 10 }}
              variant="secondary"
              className="modalButtons headerButton btn-fill"
              onClick={() => {
                handleSave();
              }}
            >
              {insuranceDetails.isNew ? "Add" : "Update"}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InsuranceDetailsModal;
