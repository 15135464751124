/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component, useEffect, useState, useLayoutEffect } from "react";
import { useLocation, NavLink, useHistory } from "react-router-dom";
import checkoutImage from "../../assets/img/checkout.png";

import { Nav } from "react-bootstrap";

import logo from "assets/img/reactlogo.png";
import API from "api";
import { useRendersCount } from "react-use";
import { DataStore } from "@aws-amplify/datastore";
import { Auth } from "aws-amplify";
import { medFlowInLocalStorage, userCompanyID } from "../../utils";
import { logOut } from "store/features/authentication/authenticationSlice";
import { useDispatch } from "react-redux";
import moment from "moment";


function Sidebar({ color, image, routes, user, setTestFilter, testFilter, imageName }) {
  const location = useLocation();
  let history = useHistory();
  const [tests, setTests] = useState([]);
  const dispatch = useDispatch();

  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  const isUserAllowed = (user, roles) => {
    let allowed = false;
    roles.forEach((role) => {
      if (user?.[role]()) {
        allowed = true;
      }
    });
    return allowed;
  };

  const handleNavClick = () => {
    if (testFilter) setTestFilter(null);
  };

  return (
    <div className="sidebar" data-image={image} data-color={color}>
      <div
        className="sidebar-background"
        style={{
          backgroundImage: "url(" + image + ")",
        }}
      />
      <div className="sidebar-wrapper">
        <div
          className="logo d-flex align-items-center justify-content-center"
          // style={{ backgroundColor: "#F7F7F8", borderColor: "#A82632", borderWidth: 4 }}
        >
          {/* <a
            href="https://www.creative-tim.com?ref=lbd-sidebar"
            className="simple-text logo-mini mx-1"
          >
            <div className="logo-img">
              <img
                src={require("assets/img/reactlogo.png").default}
                alt="..."
              />
            </div>
          </a> */}
          <a className="simple-text" href="https://www.gosafecamp.com/" target="_blank">
            <img
              src={imageName instanceof Object ? URL.createObjectURL(imageName) : imageName}
              alt="..."
              style={{ width: 180 }}
            />
          </a>
          <div
            className="cursor-pointer sidebar-checkout-img"
            onClick={async (e) => {
              e.preventDefault();
              await DataStore.clear();
              await Auth.signOut();
              dispatch(logOut());
              medFlowInLocalStorage.clear();
              userCompanyID.clear();
              history.push("/login");
            }}
          >
            <img src={checkoutImage} alt="checkout-img" />
          </div>
        </div>
        <Nav>
          {routes
            .filter((e) => e.path !== "/profile")
            .map((prop, key) => {
              if (prop.disabled) {
                return (
                  <li className={prop.upgrade ? "active active-pro" : activeRoute(prop.layout + prop.path)} key={key}>
                    <div className="nav-link nav-disabled">
                      {prop.src ? <img src={prop.src} /> : <i className={prop.icon} />}
                      <p>{prop.name} </p>
                      {prop.name === "Incident Mgt" && (
                        <span
                          style={{
                            backgroundColor: "#A82632",
                            color: "white",
                            marginLeft: 5,
                            fontWeight: "bold",
                            fontSize: "13px",
                            width: "20px",
                            textAlign: "center",
                            borderRadius: "3px",
                            boxShadow: "1px 2px #1A1919",
                            padding: "5px",
                          }}
                        >
                          3
                        </span>
                      )}
                    </div>
                  </li>
                );
              }
              if (!prop.redirect)
                return (
                  <li className={prop.upgrade ? "active active-pro" : activeRoute(prop.layout + prop.path)} key={key}>
                    <NavLink
                      to={prop.layout + prop.path}
                      className="nav-link"
                      activeClassName="active"
                      onClick={handleNavClick}
                    >
                      {prop.src ? (
                        <img src={prop.src} alt="icons" className="newListingIcons" />
                      ) : (
                        <i className={prop.icon} />
                      )}
                      {/*<i className={prop.icon} />*/}
                      <p>{prop.name} </p>
                    </NavLink>
                  </li>
                );
              return null;
            })}
          <div className="navFooter">
            <div>Version 1.0.0</div>
            <div>{"07/31/2024 12:00AM"}</div>
          </div>
        </Nav>
      </div>
    </div>
  );
}

export default Sidebar;
