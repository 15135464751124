/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateEmployee = /* GraphQL */ `
  subscription OnCreateEmployee($filter: ModelSubscriptionEmployeeFilterInput) {
    onCreateEmployee(filter: $filter) {
      id
      firstName
      middleName
      lastName
      employeeFND
      jobTitle
      picture
      dob
      schrID
      pcn
      street
      street2
      city
      state
      countryCode
      country
      region
      location
      zip
      phoneNumber
      sex
      email
      isVaccinated
      subID
      idNumber
      department
      isNew
      employeeType
      vaccinationCardUpload
      vaccinationType
      vaccinationDate
      vaccinationLocation
      vaccinationDateS
      vaccinationLocationS
      vaccinationFile
      isBooster
      boosterType
      boosterDate
      boosterLocation
      isExternalTest
      externalTestDate
      externalTestType
      externalTestResult
      externalTestFile
      companyID
      clientID
      subAgent
      unionNo
      localNo
      empTZ
      insuranceCompany
      insuranceCompanyCode
      medicalNo
      insuranceDetails
      medicareNo
      insuranceGroupId
      insuranceCardFront
      insuranceCardBack
      primaryInsurance
      connectedTo
      testOrdered
      totalOrderd
      testAvailable
      updatedBy
      updatedByName
      isSignUp
      autoShipment
      loginID
      createdBy
      createdAt
      note
      noteAddedBy
      status
      isHospice
      snf
      isInsHousing
      eligibilityStatus
      requestedDetails
      primaryCarePhysician
      referringPyhsician
      renderingProvider
      referringProvider
      orderingProvider
      codes
      postGridID
      sendSMS
      sendEmail
      testTwo
      setting
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateEmployee = /* GraphQL */ `
  subscription OnUpdateEmployee($filter: ModelSubscriptionEmployeeFilterInput) {
    onUpdateEmployee(filter: $filter) {
      id
      firstName
      middleName
      lastName
      employeeFND
      jobTitle
      picture
      dob
      schrID
      pcn
      street
      street2
      city
      state
      countryCode
      country
      region
      location
      zip
      phoneNumber
      sex
      email
      isVaccinated
      subID
      idNumber
      department
      isNew
      employeeType
      vaccinationCardUpload
      vaccinationType
      vaccinationDate
      vaccinationLocation
      vaccinationDateS
      vaccinationLocationS
      vaccinationFile
      isBooster
      boosterType
      boosterDate
      boosterLocation
      isExternalTest
      externalTestDate
      externalTestType
      externalTestResult
      externalTestFile
      companyID
      clientID
      subAgent
      unionNo
      localNo
      empTZ
      insuranceCompany
      insuranceCompanyCode
      medicalNo
      insuranceDetails
      medicareNo
      insuranceGroupId
      insuranceCardFront
      insuranceCardBack
      primaryInsurance
      connectedTo
      testOrdered
      totalOrderd
      testAvailable
      updatedBy
      updatedByName
      isSignUp
      autoShipment
      loginID
      createdBy
      createdAt
      note
      noteAddedBy
      status
      isHospice
      snf
      isInsHousing
      eligibilityStatus
      requestedDetails
      primaryCarePhysician
      referringPyhsician
      renderingProvider
      referringProvider
      orderingProvider
      codes
      postGridID
      sendSMS
      sendEmail
      testTwo
      setting
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteEmployee = /* GraphQL */ `
  subscription OnDeleteEmployee($filter: ModelSubscriptionEmployeeFilterInput) {
    onDeleteEmployee(filter: $filter) {
      id
      firstName
      middleName
      lastName
      employeeFND
      jobTitle
      picture
      dob
      schrID
      pcn
      street
      street2
      city
      state
      countryCode
      country
      region
      location
      zip
      phoneNumber
      sex
      email
      isVaccinated
      subID
      idNumber
      department
      isNew
      employeeType
      vaccinationCardUpload
      vaccinationType
      vaccinationDate
      vaccinationLocation
      vaccinationDateS
      vaccinationLocationS
      vaccinationFile
      isBooster
      boosterType
      boosterDate
      boosterLocation
      isExternalTest
      externalTestDate
      externalTestType
      externalTestResult
      externalTestFile
      companyID
      clientID
      subAgent
      unionNo
      localNo
      empTZ
      insuranceCompany
      insuranceCompanyCode
      medicalNo
      insuranceDetails
      medicareNo
      insuranceGroupId
      insuranceCardFront
      insuranceCardBack
      primaryInsurance
      connectedTo
      testOrdered
      totalOrderd
      testAvailable
      updatedBy
      updatedByName
      isSignUp
      autoShipment
      loginID
      createdBy
      createdAt
      note
      noteAddedBy
      status
      isHospice
      snf
      isInsHousing
      eligibilityStatus
      requestedDetails
      primaryCarePhysician
      referringPyhsician
      renderingProvider
      referringProvider
      orderingProvider
      codes
      postGridID
      sendSMS
      sendEmail
      testTwo
      setting
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateSite = /* GraphQL */ `
  subscription OnCreateSite($filter: ModelSubscriptionSiteFilterInput) {
    onCreateSite(filter: $filter) {
      id
      clientID
      name
      contact_name
      phone_number
      email
      contact_email
      contact_fax
      street
      street2
      city
      state
      countryCode
      country
      zip
      testOrderCategory
      testLimit
      minTestOrderQty
      preRegistration
      totalOrders
      monthToDate
      yearToDate
      inceptionDate
      createdBy
      createdByName
      contactName
      webSite
      associatedClients
      defaultProvider
      practiceID
      fax
      isActive
      isArchive
      discount
      commission
      totalBilledAmount
      claimAmount
      openAmount
      paid
      lastOrder
      isDefault
      note
      setting
      labLocalID
      excludedCPT
      npi
      licProvNo
      clientGroup
      updatedByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateSite = /* GraphQL */ `
  subscription OnUpdateSite($filter: ModelSubscriptionSiteFilterInput) {
    onUpdateSite(filter: $filter) {
      id
      clientID
      name
      contact_name
      phone_number
      email
      contact_email
      contact_fax
      street
      street2
      city
      state
      countryCode
      country
      zip
      testOrderCategory
      testLimit
      minTestOrderQty
      preRegistration
      totalOrders
      monthToDate
      yearToDate
      inceptionDate
      createdBy
      createdByName
      contactName
      webSite
      associatedClients
      defaultProvider
      practiceID
      fax
      isActive
      isArchive
      discount
      commission
      totalBilledAmount
      claimAmount
      openAmount
      paid
      lastOrder
      isDefault
      note
      setting
      labLocalID
      excludedCPT
      npi
      licProvNo
      clientGroup
      updatedByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteSite = /* GraphQL */ `
  subscription OnDeleteSite($filter: ModelSubscriptionSiteFilterInput) {
    onDeleteSite(filter: $filter) {
      id
      clientID
      name
      contact_name
      phone_number
      email
      contact_email
      contact_fax
      street
      street2
      city
      state
      countryCode
      country
      zip
      testOrderCategory
      testLimit
      minTestOrderQty
      preRegistration
      totalOrders
      monthToDate
      yearToDate
      inceptionDate
      createdBy
      createdByName
      contactName
      webSite
      associatedClients
      defaultProvider
      practiceID
      fax
      isActive
      isArchive
      discount
      commission
      totalBilledAmount
      claimAmount
      openAmount
      paid
      lastOrder
      isDefault
      note
      setting
      labLocalID
      excludedCPT
      npi
      licProvNo
      clientGroup
      updatedByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateClient = /* GraphQL */ `
  subscription OnCreateClient($filter: ModelSubscriptionClientFilterInput) {
    onCreateClient(filter: $filter) {
      id
      name
      contact_name
      phone_number
      contact_email
      street
      street2
      city
      state
      countryCode
      country
      zip
      testOrderCategory
      testLimit
      totalOrders
      monthToDate
      yearToDate
      inceptionDate
      minTestOrderQty
      preRegistration
      createdBy
      companyID
      createdByName
      contactName
      webSite
      logo
      stats
      mdSetting
      npi
      licProvNo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateClient = /* GraphQL */ `
  subscription OnUpdateClient($filter: ModelSubscriptionClientFilterInput) {
    onUpdateClient(filter: $filter) {
      id
      name
      contact_name
      phone_number
      contact_email
      street
      street2
      city
      state
      countryCode
      country
      zip
      testOrderCategory
      testLimit
      totalOrders
      monthToDate
      yearToDate
      inceptionDate
      minTestOrderQty
      preRegistration
      createdBy
      companyID
      createdByName
      contactName
      webSite
      logo
      stats
      mdSetting
      npi
      licProvNo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteClient = /* GraphQL */ `
  subscription OnDeleteClient($filter: ModelSubscriptionClientFilterInput) {
    onDeleteClient(filter: $filter) {
      id
      name
      contact_name
      phone_number
      contact_email
      street
      street2
      city
      state
      countryCode
      country
      zip
      testOrderCategory
      testLimit
      totalOrders
      monthToDate
      yearToDate
      inceptionDate
      minTestOrderQty
      preRegistration
      createdBy
      companyID
      createdByName
      contactName
      webSite
      logo
      stats
      mdSetting
      npi
      licProvNo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateEmployeeOrder = /* GraphQL */ `
  subscription OnCreateEmployeeOrder(
    $filter: ModelSubscriptionEmployeeOrderFilterInput
  ) {
    onCreateEmployeeOrder(filter: $filter) {
      id
      empID
      employeeID
      firstName
      lastName
      dob
      gender
      medicalNo
      phoneNumber
      email
      street
      street2
      city
      state
      zip
      countryCode
      country
      siteID
      locationID
      clientID
      subAgentID
      clientName
      locationName
      subAgentName
      siteName
      details
      test_type
      orderDate
      orderId
      eligibilityApiResponse
      orderStatus
      trackingURL
      trackingNumber
      testQty
      isActive
      payerId
      payerName
      pcn
      employeeClaimID
      claimFileID
      claimStatus
      claimSubmitDate
      claimAmount
      claimMessage
      amountRecieved
      amountRecievedDate
      expectedAmount
      chequeNo
      approvedRemarks
      InsuranceStatus
      InsuranceMessage
      requestedBySelf
      requestedBy
      requestedByName
      createdAt
      note
      dos
      files
      updatedSource
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateEmployeeOrder = /* GraphQL */ `
  subscription OnUpdateEmployeeOrder(
    $filter: ModelSubscriptionEmployeeOrderFilterInput
  ) {
    onUpdateEmployeeOrder(filter: $filter) {
      id
      empID
      employeeID
      firstName
      lastName
      dob
      gender
      medicalNo
      phoneNumber
      email
      street
      street2
      city
      state
      zip
      countryCode
      country
      siteID
      locationID
      clientID
      subAgentID
      clientName
      locationName
      subAgentName
      siteName
      details
      test_type
      orderDate
      orderId
      eligibilityApiResponse
      orderStatus
      trackingURL
      trackingNumber
      testQty
      isActive
      payerId
      payerName
      pcn
      employeeClaimID
      claimFileID
      claimStatus
      claimSubmitDate
      claimAmount
      claimMessage
      amountRecieved
      amountRecievedDate
      expectedAmount
      chequeNo
      approvedRemarks
      InsuranceStatus
      InsuranceMessage
      requestedBySelf
      requestedBy
      requestedByName
      createdAt
      note
      dos
      files
      updatedSource
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteEmployeeOrder = /* GraphQL */ `
  subscription OnDeleteEmployeeOrder(
    $filter: ModelSubscriptionEmployeeOrderFilterInput
  ) {
    onDeleteEmployeeOrder(filter: $filter) {
      id
      empID
      employeeID
      firstName
      lastName
      dob
      gender
      medicalNo
      phoneNumber
      email
      street
      street2
      city
      state
      zip
      countryCode
      country
      siteID
      locationID
      clientID
      subAgentID
      clientName
      locationName
      subAgentName
      siteName
      details
      test_type
      orderDate
      orderId
      eligibilityApiResponse
      orderStatus
      trackingURL
      trackingNumber
      testQty
      isActive
      payerId
      payerName
      pcn
      employeeClaimID
      claimFileID
      claimStatus
      claimSubmitDate
      claimAmount
      claimMessage
      amountRecieved
      amountRecievedDate
      expectedAmount
      chequeNo
      approvedRemarks
      InsuranceStatus
      InsuranceMessage
      requestedBySelf
      requestedBy
      requestedByName
      createdAt
      note
      dos
      files
      updatedSource
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAppSetting = /* GraphQL */ `
  subscription OnCreateAppSetting(
    $filter: ModelSubscriptionAppSettingFilterInput
  ) {
    onCreateAppSetting(filter: $filter) {
      id
      code
      employeeCounter
      orderId
      orderCounter
      invoiceCounter
      testLimit
      totalOrders
      minTestOrderQty
      bill_id
      name
      npi
      taxid
      taxonomyType
      email
      phoneNumber
      street
      street2
      city
      state
      zip
      alertSetting
      cliaNumber
      defaultSetting
      otherNPI
      renderingProvider
      isActive
      clientID
      facilityID
      subAgentID
      ltcLink
      status
      appId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateAppSetting = /* GraphQL */ `
  subscription OnUpdateAppSetting(
    $filter: ModelSubscriptionAppSettingFilterInput
  ) {
    onUpdateAppSetting(filter: $filter) {
      id
      code
      employeeCounter
      orderId
      orderCounter
      invoiceCounter
      testLimit
      totalOrders
      minTestOrderQty
      bill_id
      name
      npi
      taxid
      taxonomyType
      email
      phoneNumber
      street
      street2
      city
      state
      zip
      alertSetting
      cliaNumber
      defaultSetting
      otherNPI
      renderingProvider
      isActive
      clientID
      facilityID
      subAgentID
      ltcLink
      status
      appId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteAppSetting = /* GraphQL */ `
  subscription OnDeleteAppSetting(
    $filter: ModelSubscriptionAppSettingFilterInput
  ) {
    onDeleteAppSetting(filter: $filter) {
      id
      code
      employeeCounter
      orderId
      orderCounter
      invoiceCounter
      testLimit
      totalOrders
      minTestOrderQty
      bill_id
      name
      npi
      taxid
      taxonomyType
      email
      phoneNumber
      street
      street2
      city
      state
      zip
      alertSetting
      cliaNumber
      defaultSetting
      otherNPI
      renderingProvider
      isActive
      clientID
      facilityID
      subAgentID
      ltcLink
      status
      appId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateInsuranceCompanies = /* GraphQL */ `
  subscription OnCreateInsuranceCompanies(
    $filter: ModelSubscriptionInsuranceCompaniesFilterInput
  ) {
    onCreateInsuranceCompanies(filter: $filter) {
      id
      clientID
      payerId
      code
      payerType
      name
      shortName
      idFormat
      address
      city
      state
      phone
      fax
      eClaim
      eFax
      manualTerm
      manualAmount
      manualMail
      eEligibility
      EDI
      ERA
      eraMail
      claimAddress
      claimCity
      claimState
      claimPhone
      claimFax
      updatedBy
      updatedByName
      createdByName
      claims
      claimAmount
      openAmount
      paid
      totalAppeal
      avgTime
      lastClaim
      days30
      days60
      days90
      older
      npi
      cliaNumber
      network
      isActive
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateInsuranceCompanies = /* GraphQL */ `
  subscription OnUpdateInsuranceCompanies(
    $filter: ModelSubscriptionInsuranceCompaniesFilterInput
  ) {
    onUpdateInsuranceCompanies(filter: $filter) {
      id
      clientID
      payerId
      code
      payerType
      name
      shortName
      idFormat
      address
      city
      state
      phone
      fax
      eClaim
      eFax
      manualTerm
      manualAmount
      manualMail
      eEligibility
      EDI
      ERA
      eraMail
      claimAddress
      claimCity
      claimState
      claimPhone
      claimFax
      updatedBy
      updatedByName
      createdByName
      claims
      claimAmount
      openAmount
      paid
      totalAppeal
      avgTime
      lastClaim
      days30
      days60
      days90
      older
      npi
      cliaNumber
      network
      isActive
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteInsuranceCompanies = /* GraphQL */ `
  subscription OnDeleteInsuranceCompanies(
    $filter: ModelSubscriptionInsuranceCompaniesFilterInput
  ) {
    onDeleteInsuranceCompanies(filter: $filter) {
      id
      clientID
      payerId
      code
      payerType
      name
      shortName
      idFormat
      address
      city
      state
      phone
      fax
      eClaim
      eFax
      manualTerm
      manualAmount
      manualMail
      eEligibility
      EDI
      ERA
      eraMail
      claimAddress
      claimCity
      claimState
      claimPhone
      claimFax
      updatedBy
      updatedByName
      createdByName
      claims
      claimAmount
      openAmount
      paid
      totalAppeal
      avgTime
      lastClaim
      days30
      days60
      days90
      older
      npi
      cliaNumber
      network
      isActive
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateUsers = /* GraphQL */ `
  subscription OnCreateUsers($filter: ModelSubscriptionUsersFilterInput) {
    onCreateUsers(filter: $filter) {
      id
      roles
      firstName
      lastName
      note
      email
      personalization
      phone_number
      userRole
      companyID
      clientID
      connectedID
      locationID
      subAgentID
      status
      systemUser
      appType
      userPermission
      is2FaEnabled
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateUsers = /* GraphQL */ `
  subscription OnUpdateUsers($filter: ModelSubscriptionUsersFilterInput) {
    onUpdateUsers(filter: $filter) {
      id
      roles
      firstName
      lastName
      note
      email
      personalization
      phone_number
      userRole
      companyID
      clientID
      connectedID
      locationID
      subAgentID
      status
      systemUser
      appType
      userPermission
      is2FaEnabled
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteUsers = /* GraphQL */ `
  subscription OnDeleteUsers($filter: ModelSubscriptionUsersFilterInput) {
    onDeleteUsers(filter: $filter) {
      id
      roles
      firstName
      lastName
      note
      email
      personalization
      phone_number
      userRole
      companyID
      clientID
      connectedID
      locationID
      subAgentID
      status
      systemUser
      appType
      userPermission
      is2FaEnabled
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateSubAgent = /* GraphQL */ `
  subscription OnCreateSubAgent($filter: ModelSubscriptionSubAgentFilterInput) {
    onCreateSubAgent(filter: $filter) {
      id
      clientID
      locationID
      name
      contact_name
      phone_number
      contact_email
      street
      street2
      city
      state
      countryCode
      country
      zip
      testOrderCategory
      testLimit
      minTestOrderQty
      preRegistration
      totalOrders
      monthToDate
      yearToDate
      inceptionDate
      createdBy
      isDefault
      createdByName
      contactName
      webSite
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateSubAgent = /* GraphQL */ `
  subscription OnUpdateSubAgent($filter: ModelSubscriptionSubAgentFilterInput) {
    onUpdateSubAgent(filter: $filter) {
      id
      clientID
      locationID
      name
      contact_name
      phone_number
      contact_email
      street
      street2
      city
      state
      countryCode
      country
      zip
      testOrderCategory
      testLimit
      minTestOrderQty
      preRegistration
      totalOrders
      monthToDate
      yearToDate
      inceptionDate
      createdBy
      isDefault
      createdByName
      contactName
      webSite
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteSubAgent = /* GraphQL */ `
  subscription OnDeleteSubAgent($filter: ModelSubscriptionSubAgentFilterInput) {
    onDeleteSubAgent(filter: $filter) {
      id
      clientID
      locationID
      name
      contact_name
      phone_number
      contact_email
      street
      street2
      city
      state
      countryCode
      country
      zip
      testOrderCategory
      testLimit
      minTestOrderQty
      preRegistration
      totalOrders
      monthToDate
      yearToDate
      inceptionDate
      createdBy
      isDefault
      createdByName
      contactName
      webSite
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateEligibiliyFiles = /* GraphQL */ `
  subscription OnCreateEligibiliyFiles(
    $filter: ModelSubscriptionEligibiliyFilesFilterInput
  ) {
    onCreateEligibiliyFiles(filter: $filter) {
      id
      clientID
      locationID
      subAgentID
      clientName
      locationName
      subAgentName
      fileName
      finalFile
      filesName
      status
      message
      totalRecord
      totalSuccess
      totalFail
      fileDownloaded
      fileType
      requestedBy
      requestedByName
      updatedBy
      updatedByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateEligibiliyFiles = /* GraphQL */ `
  subscription OnUpdateEligibiliyFiles(
    $filter: ModelSubscriptionEligibiliyFilesFilterInput
  ) {
    onUpdateEligibiliyFiles(filter: $filter) {
      id
      clientID
      locationID
      subAgentID
      clientName
      locationName
      subAgentName
      fileName
      finalFile
      filesName
      status
      message
      totalRecord
      totalSuccess
      totalFail
      fileDownloaded
      fileType
      requestedBy
      requestedByName
      updatedBy
      updatedByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteEligibiliyFiles = /* GraphQL */ `
  subscription OnDeleteEligibiliyFiles(
    $filter: ModelSubscriptionEligibiliyFilesFilterInput
  ) {
    onDeleteEligibiliyFiles(filter: $filter) {
      id
      clientID
      locationID
      subAgentID
      clientName
      locationName
      subAgentName
      fileName
      finalFile
      filesName
      status
      message
      totalRecord
      totalSuccess
      totalFail
      fileDownloaded
      fileType
      requestedBy
      requestedByName
      updatedBy
      updatedByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateClaimFiles = /* GraphQL */ `
  subscription OnCreateClaimFiles(
    $filter: ModelSubscriptionClaimFilesFilterInput
  ) {
    onCreateClaimFiles(filter: $filter) {
      id
      clientID
      locationID
      subAgentID
      clientName
      locationName
      subAgentName
      fileName
      finalFile
      filesName
      status
      message
      totalRecord
      totalSuccess
      totalFail
      fileDownloaded
      fileType
      requestedBy
      requestedByName
      updatedBy
      updatedByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateClaimFiles = /* GraphQL */ `
  subscription OnUpdateClaimFiles(
    $filter: ModelSubscriptionClaimFilesFilterInput
  ) {
    onUpdateClaimFiles(filter: $filter) {
      id
      clientID
      locationID
      subAgentID
      clientName
      locationName
      subAgentName
      fileName
      finalFile
      filesName
      status
      message
      totalRecord
      totalSuccess
      totalFail
      fileDownloaded
      fileType
      requestedBy
      requestedByName
      updatedBy
      updatedByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteClaimFiles = /* GraphQL */ `
  subscription OnDeleteClaimFiles(
    $filter: ModelSubscriptionClaimFilesFilterInput
  ) {
    onDeleteClaimFiles(filter: $filter) {
      id
      clientID
      locationID
      subAgentID
      clientName
      locationName
      subAgentName
      fileName
      finalFile
      filesName
      status
      message
      totalRecord
      totalSuccess
      totalFail
      fileDownloaded
      fileType
      requestedBy
      requestedByName
      updatedBy
      updatedByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateEmployeeClaims = /* GraphQL */ `
  subscription OnCreateEmployeeClaims(
    $filter: ModelSubscriptionEmployeeClaimsFilterInput
  ) {
    onCreateEmployeeClaims(filter: $filter) {
      id
      clientID
      locationID
      subAgentID
      employeeID
      clientName
      locationName
      subAgentName
      status
      message
      orderId
      orderDate
      localOrderId
      fileid
      claimmd_id
      accept_assign
      bill_addr_1
      bill_addr_2
      bill_city
      bill_id
      bill_name
      bill_npi
      bill_phone
      bill_state
      bill_taxid
      bill_taxid_type
      bill_zip
      diag_1
      diag_2
      diag_3
      diag_4
      diag_5
      diag_6
      diag_7
      diag_8
      mod1_1
      mod2_1
      mod3_1
      mod4_1
      narrative_1
      mod1_2
      mod2_2
      mod3_2
      mod4_2
      narrative_2
      employment_related
      ins_addr_1
      ins_addr_2
      ins_city
      ins_dob
      ins_name_f
      ins_name_l
      ins_name_m
      ins_number
      ins_sex
      ins_state
      ins_zip
      pat_addr_1
      pat_addr_2
      pat_city
      pat_country
      pat_dob
      pat_name_f
      pat_name_l
      pat_name_m
      pat_phone
      pat_rel
      pat_sex
      pat_ssn
      pat_state
      pat_zip
      payer_name
      payer_order
      payerid
      pcn
      remote_claimid
      remote_fileid
      remote_batchid
      total_charge
      charge_1
      diag_ref_1
      from_date_1
      place_of_service_1
      place_of_service_2
      proc_code_1
      proc_code_2
      thru_date_1
      units_1
      remote_chgid_1
      remote_chgid_2
      thru_date_2
      units_2
      paymentMethod
      paymentCheckNo
      checkDate
      claimNo
      allowedPayment
      paidAmount
      adjustedAmount
      overPaidAmount
      submissionDate
      reSubmissionDate
      insuranceProcessDate
      isPost
      plan_type
      patient_responsibility
      total_paid
      crossover_carrier
      crossover_id
      prov_npi
      prov_id
      claim_received_date
      status_code
      payer_icn
      filing_code
      requestedBy
      requestedByName
      updatedBy
      updatedByName
      createdAt
      proc_array
      provider
      era
      clia_number
      note
      eraIds
      isNewLogs
      acknowledgeBy
      pat_email
      paid_insurance
      assign_details
      submittedBy
      submittedByName
      updatedSource
      testID
      openBalance
      openStatus
      billTo
      accNo
      isHospice
      snf
      isInsHousing
      homeBound
      toData
      write_off
      appealID
      createdBy
      createdByName
      eligibility
      additional
      isInjury
      isAccident
      injuryDate
      stateOfInjury
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateEmployeeClaims = /* GraphQL */ `
  subscription OnUpdateEmployeeClaims(
    $filter: ModelSubscriptionEmployeeClaimsFilterInput
  ) {
    onUpdateEmployeeClaims(filter: $filter) {
      id
      clientID
      locationID
      subAgentID
      employeeID
      clientName
      locationName
      subAgentName
      status
      message
      orderId
      orderDate
      localOrderId
      fileid
      claimmd_id
      accept_assign
      bill_addr_1
      bill_addr_2
      bill_city
      bill_id
      bill_name
      bill_npi
      bill_phone
      bill_state
      bill_taxid
      bill_taxid_type
      bill_zip
      diag_1
      diag_2
      diag_3
      diag_4
      diag_5
      diag_6
      diag_7
      diag_8
      mod1_1
      mod2_1
      mod3_1
      mod4_1
      narrative_1
      mod1_2
      mod2_2
      mod3_2
      mod4_2
      narrative_2
      employment_related
      ins_addr_1
      ins_addr_2
      ins_city
      ins_dob
      ins_name_f
      ins_name_l
      ins_name_m
      ins_number
      ins_sex
      ins_state
      ins_zip
      pat_addr_1
      pat_addr_2
      pat_city
      pat_country
      pat_dob
      pat_name_f
      pat_name_l
      pat_name_m
      pat_phone
      pat_rel
      pat_sex
      pat_ssn
      pat_state
      pat_zip
      payer_name
      payer_order
      payerid
      pcn
      remote_claimid
      remote_fileid
      remote_batchid
      total_charge
      charge_1
      diag_ref_1
      from_date_1
      place_of_service_1
      place_of_service_2
      proc_code_1
      proc_code_2
      thru_date_1
      units_1
      remote_chgid_1
      remote_chgid_2
      thru_date_2
      units_2
      paymentMethod
      paymentCheckNo
      checkDate
      claimNo
      allowedPayment
      paidAmount
      adjustedAmount
      overPaidAmount
      submissionDate
      reSubmissionDate
      insuranceProcessDate
      isPost
      plan_type
      patient_responsibility
      total_paid
      crossover_carrier
      crossover_id
      prov_npi
      prov_id
      claim_received_date
      status_code
      payer_icn
      filing_code
      requestedBy
      requestedByName
      updatedBy
      updatedByName
      createdAt
      proc_array
      provider
      era
      clia_number
      note
      eraIds
      isNewLogs
      acknowledgeBy
      pat_email
      paid_insurance
      assign_details
      submittedBy
      submittedByName
      updatedSource
      testID
      openBalance
      openStatus
      billTo
      accNo
      isHospice
      snf
      isInsHousing
      homeBound
      toData
      write_off
      appealID
      createdBy
      createdByName
      eligibility
      additional
      isInjury
      isAccident
      injuryDate
      stateOfInjury
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteEmployeeClaims = /* GraphQL */ `
  subscription OnDeleteEmployeeClaims(
    $filter: ModelSubscriptionEmployeeClaimsFilterInput
  ) {
    onDeleteEmployeeClaims(filter: $filter) {
      id
      clientID
      locationID
      subAgentID
      employeeID
      clientName
      locationName
      subAgentName
      status
      message
      orderId
      orderDate
      localOrderId
      fileid
      claimmd_id
      accept_assign
      bill_addr_1
      bill_addr_2
      bill_city
      bill_id
      bill_name
      bill_npi
      bill_phone
      bill_state
      bill_taxid
      bill_taxid_type
      bill_zip
      diag_1
      diag_2
      diag_3
      diag_4
      diag_5
      diag_6
      diag_7
      diag_8
      mod1_1
      mod2_1
      mod3_1
      mod4_1
      narrative_1
      mod1_2
      mod2_2
      mod3_2
      mod4_2
      narrative_2
      employment_related
      ins_addr_1
      ins_addr_2
      ins_city
      ins_dob
      ins_name_f
      ins_name_l
      ins_name_m
      ins_number
      ins_sex
      ins_state
      ins_zip
      pat_addr_1
      pat_addr_2
      pat_city
      pat_country
      pat_dob
      pat_name_f
      pat_name_l
      pat_name_m
      pat_phone
      pat_rel
      pat_sex
      pat_ssn
      pat_state
      pat_zip
      payer_name
      payer_order
      payerid
      pcn
      remote_claimid
      remote_fileid
      remote_batchid
      total_charge
      charge_1
      diag_ref_1
      from_date_1
      place_of_service_1
      place_of_service_2
      proc_code_1
      proc_code_2
      thru_date_1
      units_1
      remote_chgid_1
      remote_chgid_2
      thru_date_2
      units_2
      paymentMethod
      paymentCheckNo
      checkDate
      claimNo
      allowedPayment
      paidAmount
      adjustedAmount
      overPaidAmount
      submissionDate
      reSubmissionDate
      insuranceProcessDate
      isPost
      plan_type
      patient_responsibility
      total_paid
      crossover_carrier
      crossover_id
      prov_npi
      prov_id
      claim_received_date
      status_code
      payer_icn
      filing_code
      requestedBy
      requestedByName
      updatedBy
      updatedByName
      createdAt
      proc_array
      provider
      era
      clia_number
      note
      eraIds
      isNewLogs
      acknowledgeBy
      pat_email
      paid_insurance
      assign_details
      submittedBy
      submittedByName
      updatedSource
      testID
      openBalance
      openStatus
      billTo
      accNo
      isHospice
      snf
      isInsHousing
      homeBound
      toData
      write_off
      appealID
      createdBy
      createdByName
      eligibility
      additional
      isInjury
      isAccident
      injuryDate
      stateOfInjury
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateProviderInfo = /* GraphQL */ `
  subscription OnCreateProviderInfo(
    $filter: ModelSubscriptionProviderInfoFilterInput
  ) {
    onCreateProviderInfo(filter: $filter) {
      id
      clientID
      name
      firstName
      lastName
      middleName
      code
      npi
      npiType
      taxid
      speciality
      email
      phoneNumber
      fax
      street
      street2
      city
      state
      zip
      isDefault
      orders
      claimAmount
      openAmount
      paid
      lastOrder
      note
      isActive
      associatedClient
      setting
      subID
      createdBy
      createdByName
      updatedByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateProviderInfo = /* GraphQL */ `
  subscription OnUpdateProviderInfo(
    $filter: ModelSubscriptionProviderInfoFilterInput
  ) {
    onUpdateProviderInfo(filter: $filter) {
      id
      clientID
      name
      firstName
      lastName
      middleName
      code
      npi
      npiType
      taxid
      speciality
      email
      phoneNumber
      fax
      street
      street2
      city
      state
      zip
      isDefault
      orders
      claimAmount
      openAmount
      paid
      lastOrder
      note
      isActive
      associatedClient
      setting
      subID
      createdBy
      createdByName
      updatedByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteProviderInfo = /* GraphQL */ `
  subscription OnDeleteProviderInfo(
    $filter: ModelSubscriptionProviderInfoFilterInput
  ) {
    onDeleteProviderInfo(filter: $filter) {
      id
      clientID
      name
      firstName
      lastName
      middleName
      code
      npi
      npiType
      taxid
      speciality
      email
      phoneNumber
      fax
      street
      street2
      city
      state
      zip
      isDefault
      orders
      claimAmount
      openAmount
      paid
      lastOrder
      note
      isActive
      associatedClient
      setting
      subID
      createdBy
      createdByName
      updatedByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateCompanyStats = /* GraphQL */ `
  subscription OnCreateCompanyStats(
    $filter: ModelSubscriptionCompanyStatsFilterInput
  ) {
    onCreateCompanyStats(filter: $filter) {
      id
      month
      draft
      submitted
      rejectted
      approved
      denied
      paid
      cancelled
      partialProcessed
      newPatients
      draftAmount
      submittedAmount
      rejecttedAmount
      deniedAmount
      paidAmount
      partialProcessedAmount
      refundAmount
      approvedAmount
      cancelledAmount
      daysStats
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateCompanyStats = /* GraphQL */ `
  subscription OnUpdateCompanyStats(
    $filter: ModelSubscriptionCompanyStatsFilterInput
  ) {
    onUpdateCompanyStats(filter: $filter) {
      id
      month
      draft
      submitted
      rejectted
      approved
      denied
      paid
      cancelled
      partialProcessed
      newPatients
      draftAmount
      submittedAmount
      rejecttedAmount
      deniedAmount
      paidAmount
      partialProcessedAmount
      refundAmount
      approvedAmount
      cancelledAmount
      daysStats
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteCompanyStats = /* GraphQL */ `
  subscription OnDeleteCompanyStats(
    $filter: ModelSubscriptionCompanyStatsFilterInput
  ) {
    onDeleteCompanyStats(filter: $filter) {
      id
      month
      draft
      submitted
      rejectted
      approved
      denied
      paid
      cancelled
      partialProcessed
      newPatients
      draftAmount
      submittedAmount
      rejecttedAmount
      deniedAmount
      paidAmount
      partialProcessedAmount
      refundAmount
      approvedAmount
      cancelledAmount
      daysStats
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateInvoices = /* GraphQL */ `
  subscription OnCreateInvoices($filter: ModelSubscriptionInvoicesFilterInput) {
    onCreateInvoices(filter: $filter) {
      id
      invoiceNo
      invoiceDate
      invoiceSendDate
      invoiceReceived
      details
      firstName
      middleName
      lastName
      street
      street2
      city
      state
      country
      zip
      phoneNumber
      email
      sendToType
      sendTo
      employeeID
      claimID
      clientID
      companyID
      subAgentID
      dueDate
      invoiceAmount
      minAmount
      discount
      status
      postGridID
      postGridStatus
      postGridMessage
      transcation_id
      url
      note
      message
      paymentMethod
      paidAmount
      paidDate
      createdBy
      createdByName
      updatedBy
      updatedByName
      isActive
      sendBy
      sendByName
      cancelBy
      cancelByName
      mailingClass
      toData
      fromData
      discountType
      totalPayable
      viewType
      sentData
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateInvoices = /* GraphQL */ `
  subscription OnUpdateInvoices($filter: ModelSubscriptionInvoicesFilterInput) {
    onUpdateInvoices(filter: $filter) {
      id
      invoiceNo
      invoiceDate
      invoiceSendDate
      invoiceReceived
      details
      firstName
      middleName
      lastName
      street
      street2
      city
      state
      country
      zip
      phoneNumber
      email
      sendToType
      sendTo
      employeeID
      claimID
      clientID
      companyID
      subAgentID
      dueDate
      invoiceAmount
      minAmount
      discount
      status
      postGridID
      postGridStatus
      postGridMessage
      transcation_id
      url
      note
      message
      paymentMethod
      paidAmount
      paidDate
      createdBy
      createdByName
      updatedBy
      updatedByName
      isActive
      sendBy
      sendByName
      cancelBy
      cancelByName
      mailingClass
      toData
      fromData
      discountType
      totalPayable
      viewType
      sentData
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteInvoices = /* GraphQL */ `
  subscription OnDeleteInvoices($filter: ModelSubscriptionInvoicesFilterInput) {
    onDeleteInvoices(filter: $filter) {
      id
      invoiceNo
      invoiceDate
      invoiceSendDate
      invoiceReceived
      details
      firstName
      middleName
      lastName
      street
      street2
      city
      state
      country
      zip
      phoneNumber
      email
      sendToType
      sendTo
      employeeID
      claimID
      clientID
      companyID
      subAgentID
      dueDate
      invoiceAmount
      minAmount
      discount
      status
      postGridID
      postGridStatus
      postGridMessage
      transcation_id
      url
      note
      message
      paymentMethod
      paidAmount
      paidDate
      createdBy
      createdByName
      updatedBy
      updatedByName
      isActive
      sendBy
      sendByName
      cancelBy
      cancelByName
      mailingClass
      toData
      fromData
      discountType
      totalPayable
      viewType
      sentData
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateClientTestSetting = /* GraphQL */ `
  subscription OnCreateClientTestSetting(
    $filter: ModelSubscriptionClientTestSettingFilterInput
  ) {
    onCreateClientTestSetting(filter: $filter) {
      id
      clientID
      companyID
      subAgentID
      test
      charge
      defaultCPT
      defaultICT
      defaultProvider
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateClientTestSetting = /* GraphQL */ `
  subscription OnUpdateClientTestSetting(
    $filter: ModelSubscriptionClientTestSettingFilterInput
  ) {
    onUpdateClientTestSetting(filter: $filter) {
      id
      clientID
      companyID
      subAgentID
      test
      charge
      defaultCPT
      defaultICT
      defaultProvider
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteClientTestSetting = /* GraphQL */ `
  subscription OnDeleteClientTestSetting(
    $filter: ModelSubscriptionClientTestSettingFilterInput
  ) {
    onDeleteClientTestSetting(filter: $filter) {
      id
      clientID
      companyID
      subAgentID
      test
      charge
      defaultCPT
      defaultICT
      defaultProvider
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateLedger = /* GraphQL */ `
  subscription OnCreateLedger($filter: ModelSubscriptionLedgerFilterInput) {
    onCreateLedger(filter: $filter) {
      id
      clientID
      companyID
      subAgentID
      employeeID
      cr
      dr
      payment_method
      payment_type
      payment_no
      date_of_service
      date_of_payment
      transcation_id
      invoice_no
      transcation_status
      description
      isActive
      createdBy
      createdByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateLedger = /* GraphQL */ `
  subscription OnUpdateLedger($filter: ModelSubscriptionLedgerFilterInput) {
    onUpdateLedger(filter: $filter) {
      id
      clientID
      companyID
      subAgentID
      employeeID
      cr
      dr
      payment_method
      payment_type
      payment_no
      date_of_service
      date_of_payment
      transcation_id
      invoice_no
      transcation_status
      description
      isActive
      createdBy
      createdByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteLedger = /* GraphQL */ `
  subscription OnDeleteLedger($filter: ModelSubscriptionLedgerFilterInput) {
    onDeleteLedger(filter: $filter) {
      id
      clientID
      companyID
      subAgentID
      employeeID
      cr
      dr
      payment_method
      payment_type
      payment_no
      date_of_service
      date_of_payment
      transcation_id
      invoice_no
      transcation_status
      description
      isActive
      createdBy
      createdByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateMessageTemplate = /* GraphQL */ `
  subscription OnCreateMessageTemplate(
    $filter: ModelSubscriptionMessageTemplateFilterInput
  ) {
    onCreateMessageTemplate(filter: $filter) {
      id
      clientID
      companyID
      subAgentID
      name
      category
      subject
      message
      isActive
      criteria
      updatedBy
      updatedByName
      createdBy
      createdByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateMessageTemplate = /* GraphQL */ `
  subscription OnUpdateMessageTemplate(
    $filter: ModelSubscriptionMessageTemplateFilterInput
  ) {
    onUpdateMessageTemplate(filter: $filter) {
      id
      clientID
      companyID
      subAgentID
      name
      category
      subject
      message
      isActive
      criteria
      updatedBy
      updatedByName
      createdBy
      createdByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteMessageTemplate = /* GraphQL */ `
  subscription OnDeleteMessageTemplate(
    $filter: ModelSubscriptionMessageTemplateFilterInput
  ) {
    onDeleteMessageTemplate(filter: $filter) {
      id
      clientID
      companyID
      subAgentID
      name
      category
      subject
      message
      isActive
      criteria
      updatedBy
      updatedByName
      createdBy
      createdByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateUploadRequestMain = /* GraphQL */ `
  subscription OnCreateUploadRequestMain(
    $filter: ModelSubscriptionUploadRequestMainFilterInput
  ) {
    onCreateUploadRequestMain(filter: $filter) {
      id
      filesStatus
      employeeID
      clientID
      siteID
      fileName
      fileType
      title
      uploadCallType
      status
      createdBy
      createdByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateUploadRequestMain = /* GraphQL */ `
  subscription OnUpdateUploadRequestMain(
    $filter: ModelSubscriptionUploadRequestMainFilterInput
  ) {
    onUpdateUploadRequestMain(filter: $filter) {
      id
      filesStatus
      employeeID
      clientID
      siteID
      fileName
      fileType
      title
      uploadCallType
      status
      createdBy
      createdByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteUploadRequestMain = /* GraphQL */ `
  subscription OnDeleteUploadRequestMain(
    $filter: ModelSubscriptionUploadRequestMainFilterInput
  ) {
    onDeleteUploadRequestMain(filter: $filter) {
      id
      filesStatus
      employeeID
      clientID
      siteID
      fileName
      fileType
      title
      uploadCallType
      status
      createdBy
      createdByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateUploadRequest = /* GraphQL */ `
  subscription OnCreateUploadRequest(
    $filter: ModelSubscriptionUploadRequestFilterInput
  ) {
    onCreateUploadRequest(filter: $filter) {
      id
      employeeID
      clientID
      siteID
      requestID
      fileID
      fileName
      fileType
      floorNo
      uploadCallType
      data
      status
      reason
      processingStatus
      createdBy
      createdByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateUploadRequest = /* GraphQL */ `
  subscription OnUpdateUploadRequest(
    $filter: ModelSubscriptionUploadRequestFilterInput
  ) {
    onUpdateUploadRequest(filter: $filter) {
      id
      employeeID
      clientID
      siteID
      requestID
      fileID
      fileName
      fileType
      floorNo
      uploadCallType
      data
      status
      reason
      processingStatus
      createdBy
      createdByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteUploadRequest = /* GraphQL */ `
  subscription OnDeleteUploadRequest(
    $filter: ModelSubscriptionUploadRequestFilterInput
  ) {
    onDeleteUploadRequest(filter: $filter) {
      id
      employeeID
      clientID
      siteID
      requestID
      fileID
      fileName
      fileType
      floorNo
      uploadCallType
      data
      status
      reason
      processingStatus
      createdBy
      createdByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
