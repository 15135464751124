import { COLUMNS } from "constant";
import React, { useState, useEffect } from "react";
import TrashIcon from "assets/img/trash-icon.png";
import { Card, CloseButton, Col, Row } from "react-bootstrap";
import SwitchToggle from "../SwitchToggle/SwitchToggle";
import EditIcon from "assets/img/edit-icon.png";

const InsuranceViewCard = (props) => {
  const {
    insurance,
    handleUpdateIns,
    index,
    handleEdit,
    cssClass,
    handleRemove,
    handleDragStart,
    handleDragOver,
    handleDragEnd,
  } = props;
  const [insObj, setInsObj] = useState(insurance);

  useEffect(() => {
    setInsObj(insurance);
  }, [insurance]);
  return (
    <Card className={cssClass || ""} key={index}>
      <Card.Body className="pb-3">
        <Row className="align-items-center">
          <Col md={6} style={{ lineHeight: "1" }}>
            <span>{`${COLUMNS[index] || ""}`}</span>
          </Col>
          <Col md={6} className="d-flex align-items-center gap-2 justify-content-end">
            <div style={{ lineHeight: "1" }}>
              <SwitchToggle
                checked={insObj.isActive}
                handleChange={(e) => handleUpdateIns({ ...insObj, isActive: e.target.checked })}
                color={"blue"}
              />
            </div>
            <div style={{ lineHeight: "1" }}>
              <img
                src={EditIcon}
                width="18"
                alt="edit icon"
                onMouseLeave={(e) => (e.target.style.color = "black")}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                aria-hidden="true"
                // className="mb-1"
                onClick={(e) => handleEdit(insObj)}
              />
            </div>
            <div style={{ lineHeight: "1" }}>
              <img
                src={TrashIcon}
                width="18"
                alt="Trash icon"
                onMouseLeave={(e) => (e.target.style.color = "black")}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                aria-hidden="true"
                onClick={(e) => handleRemove(insObj)}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <span className="fw-bold fs-6">{insObj.insuranceCompanyCode}</span>
          </Col>
        </Row>
        <Row>
          <Col>
            <span>{insObj.medicalNo}</span>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default InsuranceViewCard;
