import React, { useState, useEffect, useCallback, useRef, useContext } from "react";
import { Button, Form, Modal, Card, Table, Row, Col } from "react-bootstrap";
import { TEST_TYPES, CONFIG } from "constant";

// import DatePicker from "react-date-picker";
import { debounce } from "debounce";
import API from "api";
import Loader from "components/Loader/Loader";
import { parseJSONObject, mergeArraysById } from "utils";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllEmployeesApi } from "store/features/employees/employeesAction";

// import FilterPopOver from "components/FilterPopOver";
import Icon from "components/Icon";
import { COMBINE_PROFILE } from "constant";
import { AppContext } from "../../context/app-context";
import ProfileListItems from "./ProfileRelationListItem";
import Filter from "components/Filter";
import { employeesSelector } from "store/features/employees/employeesSelectors";
import RelationView from "./RelationView";

const ProfileRelationModal = (props) => {
  const { handleClose, employeesId, handleChangeRelations, existingRelations, handleChangeConnceted, patient } = props;
  const appContext = useContext(AppContext);
  const [users, setUsers] = useState([]);
  const [filter, setFilter] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [triggerFilter, setTriggerFilter] = useState();
  const [relationOptions, setRelationOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [timeFilter, setTimeFilter] = useState("All Users");
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const [relations, setRelations] = useState();

  useEffect(() => {
    setRelations(existingRelations);
  }, [existingRelations]);

  const dispatch = useDispatch();

  const { filteredEmployees } = useSelector(employeesSelector);

  useEffect(() => {
    getRelationsData();
  }, []);

  const getRelationsData = async () => {
    const data = await API.getProxyContactData();
    const dataToSave = data?.map((d) => {
      return { value: d.name, label: d.name };
    });
    setRelationOptions(dataToSave);
  };

  const handleMakeRelation = (item, relation) => {
    if (item.id && relation) {
      const isExists = relations.find((f) => f.id === item.id);
      if (isExists) return;
      const newRelationList = [...relations, { ...item, relation: relation }];
      setRelations(newRelationList);
      setFilteredUsers(filteredUsers.filter((f) => f.id !== item.id));
      setUsers(users.filter((f) => f.id !== item.id));
    }
  };

  const handleDeleteRelation = (item) => {
    const modifiedRelation = relations.filter((f) => f.id !== item.id);
    setRelations(modifiedRelation);
    const users = [...filteredUsers];
    users.unshift(item);
    setFilteredUsers(users);
    setUsers((prev) => [item, ...prev]);
  };

  const searchTerms = ["First Name", "Last Name", "Email", "Phone"];

  useEffect(() => {
    if (filteredEmployees.length > 0) {
      setFilteredUsers(filteredEmployees);
    }
  }, [filteredEmployees]);

  const handleSave = async () => {
    try {
      setLoading(true);
      const relationList = relations.filter((f) => f.relation !== COMBINE_PROFILE);
      const combineList = relations.filter((f) => f.relation === COMBINE_PROFILE);

      let result = await API.updateRelations({
        id: employeesId,
        connectedTo: relationList.map((m) => ({ id: m.id, relation: m.relation, relationType: "Child" })),
      });

      const conntedTo = parseJSONObject(result?.updatedEmployee?.connectedTo || []);
      handleChangeConnceted(conntedTo);
      const proxyProfiles = mergeArraysById(conntedTo, result?.updatedEmployee?.proxyProfiles);
      handleChangeRelations(proxyProfiles);

      let logData = {
        employeeID: employeesId,
        slug: "Proxy Update",
        auditType: "ProxyProfile",
        action: "View Details",
        relations: JSON.stringify(
          relationList.map((m) => ({
            id: m.id,
            firstName: m.firstName,
            lastName: m.lastName,
            relation: m.relation,
          }))
        ),
        companyID: CONFIG.clientID,
        userName: appContext?.user?.name,
        userID: appContext.user.sub,
      };

      await API.addlogs(logData);
      if (combineList.length > 0) {
        const res = await API.mergeEmployeeLogs(
          employeesId,
          combineList.map((m) => m.id)
        );
        if (res.data) {
          for (const emp of combineList) {
            await API.deleteEmployee(emp.id);
          }

          let logData = {
            slug: "Proxy Update",
            employeeID: employeesId,
            auditType: "CombineProfile",
            action: "View Details",
            deletedIds: JSON.stringify(
              combineList.map((m) => ({
                id: m.id,
                firstName: m.firstName,
                lastName: m.lastName,
                phoneNumber: m.phoneNumber || m.phone_number,
              }))
            ),
            companyID: appContext.company.id,
            userName: appContext.user.name,
            userID: appContext.user.sub,
          };
          await API.addlogs(logData);
        }
      }
      setLoading(false);
      appContext.showSuccessMessage("Proxy relation updated successfully!");
      handleClose();
    } catch (err) {
      setLoading(false);
      console.log("Error:-", err);
    }
  };

  useEffect(() => {
    setPageNo(1);
    fetchFromApi({ ...filter }, { ...timeFilter }, 1, sortBy, sortDescending);
  }, [filter, timeFilter, sortBy, sortDescending]);

  useEffect(() => {
    if (pageNo > 1) {
      loadEmployees(Object.keys(filter).length === 0 ? { id: 1 } : filter, timeFilter, pageNo, sortBy, sortDescending);
    }
  }, [pageNo]);

  const fetchFromApi = useCallback(
    debounce((filter, timeFilter, page, sortBy, sortDescending) => {
      loadEmployees(filter, timeFilter, page, sortBy, sortDescending);
    }, 800),
    [relations]
  );

  const loadEmployees = async (params) => {
    setLoading(true);
    try {
      dispatch(fetchAllEmployeesApi(null, null, params, true));
      const filterEmployeesData = filteredEmployees.filter(
        (f) => f.id !== employeesId && relations.findIndex((i) => i.id === f.id) === -1
      );
      setUsers(filterEmployeesData);
      setFilteredUsers(filterEmployeesData);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <Modal show onHide={handleClose} animation={true} size={"2xl"} scrollable keyboard={true} className="second-modal">
      <Modal.Header closeButton>
        <Modal.Title className="my-0">{`Manage Proxy - ${patient.firstName} ${patient.lastName}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className="mt-0 fw-bold">{`Proxy Profiles (${relations?.length})`}</h4>

        <RelationView relations={relations} handleDeleteRelation={handleDeleteRelation} />

        <h4 className="mt-0 fw-bold">{`All Profiles (${filteredUsers.length})`}</h4>
        <Card className="strpied-tabled-with-hover">
          <Card.Body className="table-full-width desktop-noScroll">
            <Icon handleClick={() => setShowFilter(!showFilter)} label="Filter" title="Filter" iconType={"filter"} />
            {showFilter && (
              <Filter
                filterTerms={searchTerms}
                setFilter={setFilter}
                filter={filter}
                triggerFilter={triggerFilter}
                setTriggerFilter={setTriggerFilter}
                isUsersTable={true}
              />
            )}

            <div className={"table-responsive "}>
              <Table className="table">
                <thead>
                  <tr>
                    <th className="tdWidth16">First Name</th>
                    <th className="tdWidth16">Last Name</th>
                    <th className="tdWidth10">Gender</th>
                    <th className="tdWidth12">DoB</th>
                    <th className="tdWidth16">Phone</th>
                    <th className="tdWidth16">Relations</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {filteredUsers.length > 0 &&
                    filteredUsers.map((emp, index) =>
                      employeesId ? (
                        <ProfileListItems
                          key={emp.id}
                          options={relationOptions}
                          item={emp}
                          employeeId={employeesId}
                          index={index}
                          handleMakeRelation={handleMakeRelation}
                          relations={relations}
                        />
                      ) : (
                        <></>
                      )
                    )}
                </tbody>
              </Table>
            </div>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="modalButtons headerButton btn-fill" onClick={handleClose}>
          Close
        </Button>
        {
          <Button variant="secondary" className="modalButtons headerButton btn-fill" onClick={handleSave}>
            Save
          </Button>
        }
      </Modal.Footer>
      {loading && <Loader />}
    </Modal>
  );
};

export default ProfileRelationModal;
