import api from "api";
import Filter from "components/Filter";
import FilterSelect from "components/FilterSelect";
import Icon from "components/Icon";
import Loader from "components/Loader/Loader";
import ErrorMessageModal from "components/Modal/ErrorMessageModal";
import NotesModal from "components/Modal/NotesModal";
import PersonalizationModal from "components/Modal/PersonalizationModal";
import MFPagination from "components/Pagination/MFPagination";
import MainTable from "components/Table/MainTable";
import TimeFilter from "components/TimeFilter";
import { Storage } from "aws-amplify";
import {
  ClaimInvoiceOptions,
  CLAIM_SUBMIT_STATUS,
  CONFIG,
  EMPLOYEE_CLAIM,
  ERA_VIEW_STATUS,
  PAGE_LIMIT,
  TABLE_QUICK_TOOLS,
} from "constant";
import { CLAIM_INVOICE_PERSONALIZE } from "constants/personalization";
import { AppContext } from "context/app-context";
import { debounce } from "debounce";
import { useCallback, useContext, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { authSelector, selectLoginUser } from "store/features/authentication/authenticationSelectors";
import { fetchAllClaimInvoice } from "store/features/claimInvoice/claimInvoiceActions";
import { claimFilterSelector, claimInvoiceSelector } from "store/features/claimInvoice/claimInvoiceSelectors";
import {
  setInvoicesFilter as setFilter,
  setFilteredClaimInvoice,
  setPageNo,
  updateNotes,
} from "store/features/claimInvoice/claimInvoiceSlice";
import { selectedCompanySetting } from "store/features/companySetting/companySettingSelectors";
import { setClaimReSubmissionModal } from "store/features/employeeClaims/employeeClaimsSlice";
import { t } from "stringConstants";
import "tableStyling.css";
import {
  CapsFirstLetter,
  draggablePersonalizationLocalStorage,
  formatDateMDY,
  formatNumber,
  StatusFormatter,
} from "utils";
import ClaimInvoiceModal from "./ClaimInvoiceModal";
import { setMessage } from "store/features/general/generalAction";
import { MESSAGE_MODES, claimSubmitStatusColorOpts } from "constant";
import Status from "components/Status";
import { formatCurrency } from "utils";
import ExportToExcel from "components/ExportToExcel";
import moment from "moment";
import { CONFIRMATION_TYPE } from "constant";
import { cancelledClaimInvoice } from "store/features/claimInvoice/claimInvoiceActions";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import { setLoader } from "store/features/general/generalSlice";
import { addSelectedInvoice } from "store/features/invoicePreview/invoicePreviewAction";
import InvoiceSettingModal from "views/ClaimInvoice/InvoiceSettingModal";

const ClaimInvoiceAPI = () => {
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [loading, setLoading] = useState(false);
  const [timeFilter, setTimeFilter] = useState({});
  const [showFilter, setShowFilter] = useState(false);

  const appContext = useContext(AppContext);
  const [allSelected, setAllSelected] = useState(false);
  const [personalize, setPersonalize] = useState([]);
  const [openPersonalizationModal, setOpenPersonalizationModal] = useState(false);
  const [openInvoiceSettingModal, setOpenInvoiceSettingModal] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState({ isShow: false });
  const [checkboxes, setCheckboxes] = useState([]);
  const [openOrderDetail, setOpenOrderDetail] = useState(false);
  const [openEraModal, setOpenEraModal] = useState(null);
  const [claimNote, setClaimNote] = useState(null);
  const [editClaim, setEditClaim] = useState(null);

  const location = useLocation();
  const dispatch = useDispatch();

  const { claimInvoice, error, pageNo, filteredClaimInvoice, totalRecord } = useSelector(claimInvoiceSelector);

  const loginUser = useSelector(selectLoginUser);
  const { permission } = useSelector(authSelector);
  const setting = useSelector(selectedCompanySetting);

  const handleViewInvoice = async (invoice, name) => {
    try {
      dispatch(addSelectedInvoice({ selectedInvoice: invoice, isBulk: false, viewOnly: true }));
    } catch (err) {
      console.log("Error", err);
      dispatch(setLoader(false));
    }
  };

  const filter = useSelector(claimFilterSelector);

  const quickTools = [
    TABLE_QUICK_TOOLS.checkbox,
    (permission.claiminvoice?.editInvoices || !CONFIG.isLabType) && TABLE_QUICK_TOOLS.edit,
    (permission.claiminvoice?.editInvoices || !CONFIG.isLabType) && TABLE_QUICK_TOOLS.notes,
  ];
  const { filterDates } = TimeFilter;

  const searchTerms = ["First Name", "Last Name", "Due Date", "Status", "Sent", "Invoice ID", "Paid Date"];

  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(PAGE_LIMIT);
  useEffect(() => {
    if (pageNo > 1) {
      dispatch(
        fetchAllClaimInvoice({
          timeFilter,
          pageNo,
          filter,
          sortBy,
          sortDescending,
          isNewCall: false,
          user: appContext.users,
        })
      );
    }
  }, [pageNo]);

  useEffect(() => {
    setPersonalize(draggablePersonalizationLocalStorage.get(loginUser, "claimInvoice", CLAIM_INVOICE_PERSONALIZE));
  }, [setting]);

  const sortUsers = (users, sortBy) => {
    if (!sortBy) {
      return [...users].sort((a, b) =>
        `${b["pat_name_f"]}${b["pat_name_l"]}` < `${a["pat_name_f"]}${a["pat_name_l"]}`
          ? 1
          : `${a["pat_name_f"]}${a["pat_name_l"]}` < `${b["pat_name_f"]}${b["pat_name_l"]}`
          ? -1
          : 0
      );
    }

    if (sortDescending) {
      return [...users].sort((a, b) => {
        let fieldA = a[sortBy];
        let fieldB = b[sortBy];
        let nameA = `${a["pat_name_f"]}${a["pat_name_l"]}`;
        let nameB = `${b["pat_name_f"]}${b["pat_name_l"]}`;
        if (fieldA && fieldB && fieldA == fieldB) {
          return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
        } else {
          return fieldA < fieldB ? -1 : 1;
        }
      });
    } else {
      return [...users].sort((a, b) => {
        let fieldA = a[sortBy];
        let fieldB = b[sortBy];
        let nameA = `${a["pat_name_f"]}${a["pat_name_l"]}`;
        let nameB = `${b["pat_name_f"]}${b["pat_name_l"]}`;
        if (fieldA && fieldB && fieldA == fieldB) {
          return nameB < nameA ? -1 : nameB > nameA ? 1 : 0;
        } else {
          return fieldB < fieldA ? -1 : 1;
        }
      });
    }
  };

  const onHandleConfirmation = (isConfirm, type) => {
    setOpenConfirmation({ isShow: false });
    if (!isConfirm) {
      return;
    }
    switch (type) {
      case CONFIRMATION_TYPE.CANCELLED_INVOICE:
        dispatch(
          cancelledClaimInvoice(
            filteredClaimInvoice.filter(
              (f) =>
                checkboxes.includes(f.id) &&
                f.status !== CLAIM_SUBMIT_STATUS.paid &&
                f.status !== CLAIM_SUBMIT_STATUS.cancelled
            )
          )
        );
        setCheckboxes([]);
        return;
    }
  };

  const fetchFromApi = useCallback(
    debounce(({ timeFilter, pageNo, filter, isNewCall, sortBy, sortDescending }) => {
      dispatch(fetchAllClaimInvoice({ timeFilter, pageNo, filter, isNewCall, sortBy, sortDescending }));
    }, 1000),
    []
  );

  useEffect(() => {
    fetchFromApi({ timeFilter, pageNo, filter, isNewCall: true, sortBy, sortDescending });
    if (claimInvoice.length > 0) {
      setCurrentPage(1);
      dispatch(setPageNo(1));
    }
  }, [filter, timeFilter, sortBy, sortDescending]);

  const ErrorFallback = ({ error, resetErrorBoundary }) => {
    // Call resetErrorBoundary() to reset the error boundary and retry the render
    return (
      <ErrorMessageModal
        error={error.message}
        handleClose={() => {
          dispatch(setClaimReSubmissionModal(null));
          setOpenOrderDetail(false);
        }}
      />
    );
  };

  const handleCheckboxChange = (e, user) => {
    const filteredList = checkboxes.filter((c) => c !== user.id);
    if (e.target.checked) {
      filteredList.push(user.id);
    }
    setCheckboxes(filteredList);
  };

  const currentUsers = (users) => {
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    return users.slice(indexOfFirstUser, indexOfLastUser);
  };

  let usersToMap = currentUsers(filteredClaimInvoice);

  // usersToMap =
  //   usersToMap &&
  //   usersToMap.map((record) => {
  //     if (record.toData) {
  //       return { ...record, ...record.toData };
  //     } else {
  //       return { ...record };
  //     }
  //   });

  const getPageNumbers = (users) => {
    const pageNumbers = users.length > PAGE_LIMIT ? Math.ceil(users.length / PAGE_LIMIT) : 1;
    return pageNumbers;
  };

  let pageNumbers = getPageNumbers(claimInvoice);

  if (filteredClaimInvoice.length > 0) {
    pageNumbers = getPageNumbers(filteredClaimInvoice);
  }

  const handleChangePage = (page, direction) => {
    setCurrentPage(page);
    const currentRecord = filteredClaimInvoice.length;
    // const currentRecord = claimInvoice.length;
    if (direction === "next" && page === pageNumbers && totalRecord > 0 && currentRecord < totalRecord) {
      dispatch(setPageNo(pageNo + 1));
    }
  };

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
    handleChangePage(1);
  };

  const handlePersonalization = async (data) => {
    setPersonalize([...data]);
    const personalisationData = draggablePersonalizationLocalStorage.saveAs(data, "claimInvoice");
    setOpenPersonalizationModal(false);
    draggablePersonalizationLocalStorage.save(JSON.stringify(personalisationData));
    await api.udpatePersonalization(loginUser.phone_number, personalisationData);
  };

  const toggleCheckboxes = (val) => {
    setCheckboxes(val ? filteredClaimInvoice.map((t) => t.id) : []);
  };

  const tdFormat = (item, row) => {
    return row[item];
  };
  const tdFormatTitle = (item, row) => {
    return row[item];
  };

  const tdStatus = (item, row) => {
    return (
      <td
        key={item.id}
        className="ellipsis"
        style={{
          textAlign: item.textAlign,
          textOverflow: item.textOverflow,
        }}
        title={`${row.message || ""}${row.crossover_carrier ? `\n ${row.crossover_carrier}` : ""}`}
        onClick={() => handleCellClick(item.itemKey, row)}
      >
        <div
          className="statusPill custom-ellipsis d-block"
          style={{
            backgroundColor: item.colorObj[row[item.itemKey]],
          }}
        >
          {StatusFormatter(row.status)}
        </div>
      </td>
    );
  };

  const tdIconStatus = (item, row) => {
    return (
      <td
        key={item.id}
        className="ellipsis"
        style={{
          textAlign: item.textAlign,
          textOverflow: item.textOverflow,
        }}
      >
        <div className="star-icon-wrapper">
          <Status type="circle" size="md" color={claimSubmitStatusColorOpts[row[item.itemKey]] || "grey"} crossIcon />
        </div>
      </td>
    );
  };

  const renderTd = (item, row) => {
    if (item.itemKey === "status") return tdStatus(item, row);

    if (item.itemKey === "postGridStatus") {
      return tdIconStatus(item, row);
    }

    return (
      <td
        key={item.id}
        className="ellipsis"
        style={{
          textAlign: item.textAlign,
          textOverflow: item.textOverflow,
        }}
        title={tdFormatTitle(item.itemKey, row)}
      >
        {tdFormat(item.itemKey, row)}
      </td>
    );
  };

  const isCancelledIconShow = () => {
    return (
      filteredClaimInvoice.filter(
        (f) =>
          checkboxes.includes(f.id) &&
          f.status !== CLAIM_SUBMIT_STATUS.cancelled &&
          f.status !== CLAIM_SUBMIT_STATUS.paid
      ).length === 0
    );
  };

  const handleCellClick = (key, row, event) => {
    switch (key) {
      case TABLE_QUICK_TOOLS.checkbox:
        handleCheckboxChange(event, row);
        break;

      case TABLE_QUICK_TOOLS.edit:
        setEditClaim(row);
        break;

      case TABLE_QUICK_TOOLS.notes:
        setClaimNote(row);
        break;

      case "status":
        row.status === ERA_VIEW_STATUS[row.status] && setOpenEraModal(row);
        break;

      default:
        break;
    }
  };

  return (
    <div style={{ flex: 1 }}>
      {!loading ? (
        <Row>
          <Col>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                    color: "#565656",
                  }}
                >
                  {t("claimInvoice")} ({formatNumber(totalRecord) ?? claimInvoice.length})
                </Card.Title>
                <div className="buttonHeader">
                  <Icon
                    handleClick={() => setShowFilter(!showFilter)}
                    title={"Filter"}
                    label={"Filter"}
                    iconType={"filter"}
                  />

                  <Icon
                    handleClick={() => {
                      if (filteredClaimInvoice && filteredClaimInvoice.length > 0) {
                        setAllSelected(!allSelected);
                        toggleCheckboxes(!allSelected);
                      }
                    }}
                    label={!allSelected ? "Select All" : "Deselect All"}
                    title={!allSelected ? "Select All" : "Deselect All"}
                    iconType={"selectAllIcon"}
                  />

                  <FilterSelect setTimeFilter={setTimeFilter} />
                  <Icon
                    handleClick={() =>
                      setOpenConfirmation({
                        isShow: true,
                        actionType: CONFIRMATION_TYPE.CANCELLED_INVOICE,
                        title: "Cancel Invoice",
                        message: "Are you Sure? to want to cancel this invoice",
                      })
                    }
                    disabled={isCancelledIconShow()}
                    title={t("cancel")}
                    label={t("cancel")}
                    iconType={"binIcon"}
                  />
                  <Icon
                    handleClick={() => setOpenPersonalizationModal(true)}
                    title={t("personalize")}
                    label={t("personalize")}
                    iconType={"personalizeIcon"}
                  />
                  <ExportToExcel
                    selectedItem={
                      personalize.length > 0 && [
                        ...personalize.filter((m) => m.isCheck && m.itemKey),
                        { itemKey: "note" },
                      ]
                    }
                    items={filteredClaimInvoice.map((e) => {
                      return {
                        ...e,
                        paidDate: moment(e.paidDate).format("MM-DD-YYYY"),
                        invoiceDate: moment(e.invoiceDate).format("MM-DD-YYYY"),
                        dueDate: moment(e.dueDate).format("MM-DD-YYYY"),
                        invoiceAmount: formatCurrency(e.invoiceAmount),
                        paidAmount: formatCurrency(e.paidAmount),
                      };
                    })}
                    filter={filter}
                    title={"Invoice Listing"}
                    sortBy={sortBy}
                    exportType="invoices"
                  />

                  <Icon
                    handleClick={() => setOpenInvoiceSettingModal(true)}
                    title={t("settings")}
                    label={t("settings")}
                    iconType={"personalizeIcon"}
                  />
                </div>

                {showFilter && (
                  <Filter
                    filterTerms={searchTerms}
                    setFilter={(obj) => dispatch(setFilter({ ...obj }))}
                    filter={filter}
                    claimInvoice={true}
                  />
                )}
                <MFPagination
                  currentPage={currentPage}
                  handleChangePage={handleChangePage}
                  totalPages={pageNumbers}
                  filter={filter}
                  totalRecord={totalRecord}
                  showSelectedRecord
                  totalSelected={checkboxes.length}
                />
              </Card.Header>
              <Card.Body className="table-full-width desktop-noScroll">
                <MainTable
                  // individualRowCssClass={(row) =>
                  //   row.status &&
                  //   !row.acknowledgeBy &&
                  //   (row.status === CLAIM_SUBMIT_STATUS.rejected || row.status === CLAIM_SUBMIT_STATUS.denied) &&
                  //   "isClaimFalse"
                  // }
                  columns={personalize}
                  rows={usersToMap}
                  flipSort={flipSort}
                  sortBy={sortBy}
                  sortDescending={sortDescending}
                  draggable
                  resizable
                  personalisationKey={"employeeClaim"}
                  handleSaveDragAndResize={(personalizeArr) => setPersonalize(personalizeArr)}
                  widthToSkip={155}
                  tools={quickTools}
                  dropDownOptions={permission.claiminvoice.generateInvoices && ClaimInvoiceOptions}
                  handleDropDownClick={async (type, row) => {
                    if (type === "viewInvoice") {
                      try {
                        handleViewInvoice(row);
                      } catch (error) {
                        console.log("Error:-", error);
                      }
                    }
                  }}
                  selectedRows={checkboxes}
                  handleCellClick={handleCellClick}
                  customColumnCellRenderer={renderTd}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <Loader />
      )}

      {openPersonalizationModal && (
        <PersonalizationModal
          data={JSON.stringify(personalize)}
          handleChange={handlePersonalization}
          show={openPersonalizationModal}
          handleClose={() => setOpenPersonalizationModal(false)}
        />
      )}
      {openConfirmation.isShow && (
        <ConfirmationModal
          show={openConfirmation.isShow}
          actionType={openConfirmation.actionType}
          title={openConfirmation.title}
          message={openConfirmation.message}
          handleConfirm={onHandleConfirmation}
          closeBtn
        />
      )}
      {claimNote && (
        <NotesModal
          handleClose={(obj) => {
            if (obj) {
              dispatch(updateNotes(obj));
              obj.note
                ? dispatch(setMessage("add Notes Successfully", MESSAGE_MODES.success))
                : dispatch(setMessage("Remove Notes Successfully", MESSAGE_MODES.success));
            }

            setClaimNote(null);
          }}
          invoice
          user={loginUser}
          data={claimNote}
        />
      )}
      {editClaim && <ClaimInvoiceModal handleClose={() => setEditClaim(null)} data={editClaim} />}
      {openInvoiceSettingModal && <InvoiceSettingModal handleClose={() => setOpenInvoiceSettingModal(false)} />}
    </div>
  );
};

export default ClaimInvoiceAPI;
