import logo from "assets/img/logo.png";
import mastercard from "assets/img/mastercard.svg";
import payonline from "assets/img/payonline.svg";
import visa from "assets/img/visa.svg";
import applepay from "assets/img/applepay.svg";
import googlepay from "assets/img/googlepay.svg";
import paypal from "assets/img/paypal.svg";

export const invoiceHtmlTemplate = (data, CONFIG) => {
  return `<div id="contentWrapper" style="max-width: 920px; margin: 0 auto;">
          <div class="container" style="max-width: 920px; margin: 0 auto;">
              <div class="top-bar" style="display: flex; align-items: center;
                justify-content: space-between;">
                  <div class="left-side" style="">
                      <div class="logo" style="">
                      <img src=${require(`assets/img/${CONFIG.labName}.png`).default} width="300px" height="87px"/>
                      </div>
                      <div class="content-b" style="margin-top:10px">
                          <p
                              style="font-size: 14px; font-weight: 500; line-height: 16px; color: #012552; margin-bottom:2px">
                              Laboratory Bill</p>
                          <img alt="barcode" height='35px' width='130px' src="{barcode}"/>
                      </div>
                  </div>
                  <div class="right-side" style="">
                      <div class="content-b" style="text-align: right; padding-right: 20px;">
                          <h1 style="font-size: 40px; letter-spacing: 3px; margin:0">Invoice</h1>
                          <p
                              style="font-size: 14px; font-weight: 500; line-height: 16px; color: #2A2C30; margin-bottom: 3px;">
                              NO.<span>{invoiceNo}</span></p>
                          <p style="font-size: 14px; line-height: 16px; color: #2A2C30; margin-bottom: 3px;">Billing Date:
                              <span>{billingDate}</span>
                          </p>
                          <p style="font-size: 14px; line-height: 16px; color: #2A2C30; margin-bottom: 3px;">Due Date:
                              <span>{dueDate}</span>
                          </p>
                      </div>
                  </div>
              </div>
              <div class="pay-online"
                  style="background-color: #F0F2F5; padding: 0 10px 0 10px; display: flex; align-items: center; justify-content: space-between;">
                  <div class="pay-left">
                      <div class="content" style="display: flex; align-items: center;">
                          <div class="img-box" style="height: 57px;">
                              <img width="60px" src=${payonline} alt="payonline" />
                          </div>
                          <div class="content-boxx" style="padding-left: 15px;">
                              <h2 style="line-height: 0px; text-transform: uppercase; text-transform: uppercase; font-size: 16px;
                              font-weight: 400; margin-bottom:10px">Easy to pay online</h2>
                              <p style="font-size: 14px;
                              line-height: normal;
                              font-weight: 400;">Pay your medical bill at patient.medflowllc.com or Scan QR Code</p>
                          </div>
                      </div>
      
      
                  </div>
                  <div class="pay-right">
                      <div class="img-box" style="height: 60px;">
                          <img width="60px" src="{scanner}" alt="scanner" />
                      </div>
                  </div>
      
              </div>
              <div class="total-due" style="background-color: #25A8E0; padding: 10px; margin-top: 9px;">
                  <div class="total-due-left" style="position: relative;">
                      <div class="contnet" style="padding-left: 20px; max-width: 700px;s">
                          <p style="line-height: 25px; font-size: 12px; color: #ffff; margin-bottom: 0;">This is your bill for Physician care you received from
                              an emergency room, or urgent care
                              visit.</p>
                          <p style="color: #ffff; font-size: 14px; line-height: normal; margin-bottom: 0;">YOUR TOTAL DUE CHARGES
                              <span>{dueCharges}</span></p>
      
                      </div>
                      <div
                        style="position: absolute; top: -19px; right: -10px; background-color: #012552; padding: 5px 11px; max-width: 200px; text-align: center; ">
                        <p
                            style="font-size: 14px; line-height: 20px; font-weight: 400; color: #BAC4D0; text-transform: uppercase; margin-bottom: 0;">
                            TOTAL AMOUNT DUE:</p>
                        <span style="display: block; color: #fff; font-size: 26px; line-height: 33px;  font-weight: 600;">{dueCharges2}</span>
                        <p
                            style="font-size: 14px; line-height: 20px; font-weight: 400; color: #BAC4D0; text-transform: uppercase; margin:0;">
                            DUE BY:<span> UPON RECEIPT</span></p>
    
                    </div>
                  </div>
      
              </div>
              <div class="bill-method"
                  style="padding-top: 9px; display: flex; align-items: center; justify-content: space-between;">
                  <div class="col-4">
                      <div class="content-box">
                          <p style="font-size: 14px; line-height: normal; color: #6E6F72; font-weight: 500; margin-bottom:5px;">Pay By Phone
                          </p>
                          <p style="font-size: 14px; line-height: normal; color: #6E6F72; font-weight: 500; margin-bottom:0;">{invoiceSettingPhone}</p>
                          <p style="font-size: 14px; line-height: normal; color: #6E6F72; font-weight: 500; margin-bottom:0;">Weekdays
                              8 AM to 5 PM {timezone}</p>
      
                      </div>
                  </div>
                  <div class="col-4">
                      <p style="font-size: 14px; line-height: normal; color: #6E6F72; font-weight: 500; margin-bottom:5px;">Pay By Mail</p>
                      <p style="font-size: 14px; line-height: normal; color: #6E6F72; font-weight: 500; margin-bottom:-2px;">Use the from
                          below to send in your<br>
                          Payment in the envelope provided.</p>
                  </div>
                  <div class="col-4">
                      <p style="font-size: 14px; line-height: normal; color: #6E6F72; font-weight: 500; margin-bottom:5px; margin-top:10px; float:right">
                          Need help or have a question?
                      </p style="font-size: 14px; line-height: normal; color: #6E6F72; font-weight: 500; margin-bottom:0; float:right">
                      <p style="font-size: 14px; line-height: normal; color: #6E6F72; font-weight: 500; margin-bottom:0; float:right"> you can call us at: {helpPhone}</p>
                      <p style="font-size: 14px; line-height: normal; color: #6E6F72; font-weight: 500; margin-bottom:10px; float:right">Weekdays 8 AM to
                          5 PM {timezone}</p>
                  </div>
      
              </div>
              <div class="tbale-data" style="">
                  <table style="width: 100%; border-collapse: collapse; border: none;">
                      <thead>
                      ${
                        data.sendTo === "Client" && data.invoicePreviewType === "detail"
                          ? `
                        <tr>
                            <th style="background-color: #B3BECB; font-size: 11px; font-weight: 600; padding: 8px;color: #04060B;">Patient Name</th>
                            <th style="background-color: #B3BECB; font-size: 11px; font-weight: 600; padding: 8px;color: #04060B;">Referring Physician</th>
                            <th style="background-color: #B3BECB; font-size: 11px; font-weight: 600; padding: 8px;color: #04060B;">Date of Service</th>
                            <th style="background-color: #B3BECB; font-size: 11px; font-weight: 600; padding: 8px;color: #04060B;">Accession No</th>
                            <th style="background-color: #B3BECB; font-size: 11px; font-weight: 600; padding: 8px;color: #04060B;">CPT</th>
                            <th style="background-color: #B3BECB; font-size: 11px; font-weight: 600; padding: 8px;color: #04060B; text-align:center;">Unit</th>
                            <th style="background-color: #B3BECB; font-size: 11px; font-weight: 600; padding: 8px;color: #04060B;">Description</th>
                            <th style="background-color: #B3BECB; font-size: 11px; font-weight: 600; padding: 8px;color: #04060B; text-align: right;">Amount</th>
                        </tr>
                        `
                          : data.sendTo === "Client" && data.invoicePreviewType === "summary"
                          ? `
                        <tr>
                        <th style="background-color: #B3BECB; font-size: 11px; font-weight: 600; padding: 8px;color: #04060B;">Patient Name</th>
                        <th style="background-color: #B3BECB; font-size: 11px; font-weight: 600; padding: 8px;color: #04060B;">Referring Physician</th>
                            <th style="background-color: #B3BECB; font-size: 11px; font-weight: 600; padding: 8px;color: #04060B;">Date of Service</th>
                            <th style="background-color: #B3BECB; font-size: 11px; font-weight: 600; padding: 8px;color: #04060B;">Accession No</th>
                            <th style="background-color: #B3BECB; font-size: 11px; font-weight: 600; padding: 8px;color: #04060B; text-align: right;">Amount</th>
                        </tr>
                        `
                          : `
                        <tr>
                        <th style="background-color: #B3BECB; font-size: 11px; font-weight: 600; padding: 8px;color: #04060B;">#</th>
                        <th style="background-color: #B3BECB; font-size: 11px; font-weight: 600; padding: 8px;color: #04060B;">DOS</th>
                        <th style="background-color: #B3BECB; font-size: 11px; font-weight: 600; padding: 8px;color: #04060B;">Referring Physician</th>
                            <th style="background-color: #B3BECB; font-size: 11px; font-weight: 600; padding: 8px;color: #04060B;">Accession #</th>
                            <th style="background-color: #B3BECB; font-size: 11px; font-weight: 600; padding: 8px;color: #04060B;">CPT</th>
                            <th style="background-color: #B3BECB; font-size: 11px; font-weight: 600; padding: 8px;color: #04060B; text-align:center;">Units</th>
                            <th style="background-color: #B3BECB; font-size: 11px; font-weight: 600; padding: 8px;color: #04060B;">Description</th>
                            <th style="background-color: #B3BECB; font-size: 11px; font-weight: 600; padding: 8px;color: #04060B; text-align: right;">Amount</th>
                        </tr>
                        `
                      }
                         
                      </thead>
                      <tbody>
                         {tableRows}
                      </tbody>
                  </table>
      
              </div>
              <div class="Total-bill"
                  style="display: flex; justify-content: space-between; align-items: center;">
                  <div class="col-6" style="max-width: 45%; ">
                      <div class="content-n" style="padding: 5px;
                       border: 1px solid #00000038; height:90px ">
                          <p style="color: #191A1F; font-size: 11px; ">{message}</p>
                      </div>
                  </div>
                  <div style='width:34%'>
                  <div style='display:flex; justify-content: space-between; align-items: center;'>
                      <div class="content">
                          <p style="font-size: 14px; line-height: 25px; color: #97989A;
                          font-weight: 600; margin-bottom:0;">Total Charges:</p>

                          <p style="font-size: 14px; line-height: 25px; color: #97989A;
                          font-weight: 600; margin-bottom:0;">Billed to plan:</p>

                          <p style="font-size: 14px; line-height: 25px; color: #F05557;
                          font-weight: 600; margin-bottom:0;">Discount:</p>

                          <p style="font-size: 14px; line-height: 35px; color:  #97989A;
                          font-weight: 600; margin-bottom:0;">Deductibles & Copayments:</p>
                      </div>
                      <div class="content" style="width: 110px;">
                    
                        <div style="display: flex; justify-content: space-between; width:100%; column-gap:4px">
                          <span style="font-size: 14px; line-height: 25px; color: #97989A;font-weight: 600; margin-bottom:0;">$</span>
                          <span style="text-align: right; flex-grow: 1;font-size: 14px; line-height: 25px; color: #97989A;font-weight: 600; margin-bottom:0;">
                          {billedPlan}</span>
                        </div>
                    
                        <div style="display: flex; justify-content: space-between; width:100%; column-gap:4px">
                          <span style="font-size: 14px; line-height: 25px; color: #97989A;font-weight: 600; margin-bottom:0;">$</span>
                          <span style="text-align: right; flex-grow: 1;font-size: 14px; line-height: 25px; color: #97989A;font-weight: 600; margin-bottom:0;">
                          {coveredPlan}</span>
                        </div>

                        <div style="display: flex; justify-content: space-between; width:100%; column-gap:4px">
                        <span style="font-size: 14px; line-height: 25px; color: #F05557;font-weight: 600; margin-bottom:0;">$</span>
                        <span style="text-align: right; flex-grow: 1;font-size: 14px; line-height: 25px; color: #F05557;font-weight: 600; margin-bottom:0;">
                        -{discount_amount}</span>
                        </div>

                        <div style="display: flex; justify-content: space-between; width:100%; column-gap:4px">
                        <span style="font-size: 14px; line-height: 25px; color: #97989A;font-weight: 600; margin-bottom:0;">$</span>
                        <span style="text-align: right; flex-grow: 1;font-size: 14px; line-height: 25px; color: #97989A;font-weight: 600; margin-bottom:0;">
                        {discount_amount}</span>
                        </div>

                      </div>
                  </div>
                  </div>
      
              </div>
              <div class="payment-methods" style="">
                  <div>
                      <h3 style="font-size: 14px;
                      font-weight: 600;
                      ">
                          We Accept The Following Payment Methods
                      </h3>
                      <div class="Payment-methods-details"
                          style="display: flex; align-items: center; padding-left: 40px;
                          padding-bottom: 13px;">
                          <div class="img-box" style="padding-right: 10px;">
                              <img style="max-width: 100%; height: auto; width: 55px;" src=${mastercard} alt="mastercard">
                          </div>
                          <div class="img-box" style="padding-right: 10px;">
                              <img style="max-width: 100%; height: auto; width: 55px;" src=${visa} alt="visa">
                          </div>
                          <div class="img-box" style="padding-right: 10px;">
                              <img style="max-width: 100%; height: auto; width: 80px;" src=${paypal} alt="paypal">
                          </div>
                          <div class="img-box" style="padding-right: 10px;">
                              <img style="max-width: 100%; height: auto; width: 55px;" src=${applepay} alt="applepay">
                          </div>
                          <div class="img-box" style="padding-right: 10px;">
                              <img style="max-width: 100%; height: auto; width: 55px;" src=${googlepay} alt="googlepay">
                          </div>
                      </div>
                  </div>
      
              </div>
              <div class="detch" style="padding-bottom: 5px;">
                  <P style="font-size: 14px; text-align: center; line-height: 16px;">-  - - - - - - - - - - - - - - - - - - -PLEASE DETACH AND RETURN THIS
                      PORTION WITH YOUR PAYMENT - - - - - - - - - - - - - - - - - - - - </P>
      
              </div>
              <div class="acc-table">
                  <table style="width:100%; border-collapse: collapse;">
                      <tbody>
                          <tr>
                              <td style="text-align: center; border: 1px solid #00000038; font-size: 14px;
                      font-weight: 600; padding: 5px;">
                                 ${data.sendTo === "Client" ? "CL" : "PT"}.ACC# <span>{pcn}</span></td>
                              <td style="text-align: center; border: 1px solid #00000038; font-size: 14px;
                      font-weight: 600; padding: 5px;">NAME: <span>{firstName}</span> {lastName}</td>
                              <td style="text-align: center; border: 1px solid #00000038; font-size: 14px;
                      font-weight: 600; padding: 5px;">Amount: <span>{dueCharges3}</span></td>
                          </tr>
                      </tbody>
                  </table>
              </div>
              <div class="payment-coupon">
                  <p style="font-size: 14px;
                  font-weight: 600; padding-top: 5px; padding-bottom: 5px; margin-bottom:0px">Payment Coupon</p>
                  <div class="main-b" style="display: flex; align-items: center; justify-content: space-between;">
                      <div class="left-side">
                          <p style="font-size: 13px;
                          line-height: 25px;
                          color: #000;
                          font-weight: 500;
                          margin-bottom: 0;

                      ">Please make your check payable to:</p>
                          <p style="text-transform: uppercase;     font-size: 13px;
                          line-height: 16px;
                          color: #000;
                          font-weight: 500;
                          margin-bottom:0;
                      ">{companyName}</p>
                          <p style="text-transform: uppercase;     font-size: 13px;
                          line-height: 16px;
                          color: #000;
                          font-weight: 500;
                          margin-bottom:0;
                      ">{companyStreet}.</p>
                          <p style="text-transform: uppercase;     font-size: 13px;
                          line-height: 16px;
                          color: #000;
                          font-weight: 500;
                          margin-bottom:0;
                      ">{companyState}, {companyZip}</p>
      
                      </div>
                      <div class="right-side">
                          <p style="    font-size: 13px;
                          line-height: 30px;
                          color: #494A4E;
                          font-weight: 600;
                          margin-bottom: 0;
                      ">Be sure to include the Invoice Number on your check</p>
                          <p style="text-transform: uppercase;     font-size: 13px;
                          line-height: 16px;
                          color: #000;
                          font-weight: 500;
                          margin-bottom:0;
                      ">{pat_name_f} {pat_name_l}</p>
                          <p style="text-transform: uppercase;     font-size: 13px;
                          line-height: 16px;
                          color: #000;
                          font-weight: 500;
                          margin-bottom:0;
                      ">{pat_addr_1}.</p>
                          <p style="text-transform: uppercase;     font-size: 13px;
                          line-height: 16px;
                          color: #000;
                          font-weight: 500;
                          margin-bottom:0;
                      ">{pat_state} {pat_zip}</p>
                      </div>
      
                  </div>
                  <div class="bottom-footer" style="display: flex; align-items: center; padding-top: 5px;">
                      <div class="left" style="max-width: 76%; background: #F0F2F5; padding: 10px 5px;">
                          <p style="font-size: 13px;
                          padding-left: 20px;
                          padding-right: 30px;">If you received an explanation of benefits showing your
                              responsibility is less than the
                              amount shown on this bill, Please pay the lesser amount. To fully resolve your invoice,
                              Please provide a copy</p>
                      </div>
                      <div class="right" style="border: 1px solid gray; padding: 37px 5px 10px 5px;">
                          <div class="contain" style="display: flex;">
                              <p style="font-size: 13px;">Amount Paid:</p>
                              <p style="width: 90px; border-bottom: 1px solid #000;"></p>
                          </div>
                      </div>
                  </div>
                  <div class="top-bar" style="display: flex; align-items: center;justify-content: space-between; margin-top:30px">
                  <div class="left-side" style="">
                  </div>
              </div>
          </div>
      </div>
      `;
};

export const invoiceHtmlTemplate1 = (data) => {
  return `
  <div id="contentWrapper" style="max-width: 920px; margin: auto; height:1122px">
      <div class="container" style="max-width: 920px; margin: 0 auto;">
              <div class="top-bar" style="display: flex; align-items: center;justify-content: space-between; margin-top:30px">
              <div class="left-side" style="">
              </div>
              <div class="right-side" style="">
                  <div class="content-b" style="text-align: right; padding-right: 20px;">
                          <h1 style="font-size: 40px; letter-spacing: 3px; margin:0">Invoice</h1>
                          <p
                              style="font-size: 14px; font-weight: 500; line-height: 16px; color: #2A2C30; margin-bottom: 3px;">
                              NO.<span>{invoiceNo1}</span></p>
                          <p style="font-size: 14px; line-height: 16px; color: #2A2C30; margin-bottom: 3px;">Billing Date:
                              <span>{billingDate1}</span>
                          </p>
                          <p style="font-size: 14px; line-height: 16px; color: #2A2C30; margin-bottom: 3px;">Due Date:
                              <span>{dueDate1}</span>
                          </p>
                      </div>
                  </div>
              </div>
               <div class="tbale-data" style="margin-top:20px;">
                  <table style="width: 100%; border-collapse: collapse; border: none;">
                  <thead>
                  <tr>
                      <th style="background-color: #B3BECB; font-size: 11px; font-weight: 600; color: #04060B; padding:12px; text-align:left">#
                      </th>
                      <th style="background-color: #B3BECB; font-size: 11px; font-weight: 600; color: #04060B;">
                          Service Date</th>
                              <th style="background-color: #B3BECB; font-size: 11px; font-weight: 600; color: #04060B;">
                              Accesion No</th>
                       
                          
                       ${
                         (data.sendTo === "Client" &&
                           `
                          <th style="background-color: #B3BECB; font-size: 11px; font-weight: 600; color: #04060B;">
                          Name</th>
                          `) ||
                         ""
                       }   
                    
                      <th style="background-color: #B3BECB; font-size: 11px; font-weight: 600; color: #04060B; padding:12px; text-align:left">
                          CPT</th>
                      <th style="background-color: #B3BECB; font-size: 11px; font-weight: 600; color: #04060B; padding:12px; text-align:left">
                          UNITS</th>
                      <th style="background-color: #B3BECB; font-size: 11px; font-weight: 600; color: #04060B; width:40%; padding:12px; text-align:left width: 20%;">
                          DESCRIPTION</th>
                      <th style="background-color: #B3BECB; font-size: 11px; font-weight: 600; color: #04060B; padding:12px; text-align:left">
                          AMOUNT</th>
                  </tr>
              </thead>
                      <tbody>
                        {tableRows1}
                      </tbody>
                  </table>
             </div>
          </div>
      </div>
  </div>
  `;
};

export const labFormTemplate3 = (CONFIG) => `

    <div id="wrapper">
        <div class="container" style="max-width: 920px; margin: 0 auto; padding: 5px 10px;">
            <div class="top-bar" style="display: flex; align-items: flex-end;
            justify-content: space-between;">
                <div class="left-side" style="">
                    <div class="logo" style="">
                        <img width="220px" src=${require(`assets/img/${CONFIG.labName}.png`).default} alt="logo" />

                    </div>
                </div>
                <div class="right-side" style="">
                    <div class="content-b">
                        <!-- <p
                          style="font-size: 14px; font-weight: 500; line-height: 16px; color: #000; margin-bottom: 3px;">
                          Recipt For Payments</p> -->


                    </div>
                </div>
            </div>
            <div class="trnsaction-details" style="background: #012552; padding: 15px 20px 15px 30px;">
                <h3 style="line-height: 33px; color: #fff; text-transform: capitalize;font-weight: 500; margin: 0;
                ">
                    Update Patient Demographic Information</h3>
                <p style="font-size: 12px; line-height: 21px; color: #fff; font-weight: 300; margin-bottom: 0;">Please Fill In Only if
                    Your Information Changed</p>
            </div>
            <div class="information">


                <div class="clients-details" style="padding-top: 10px; display: flex; align-items: center;">
                    <div class="left-side" style="flex: 0 0 50%; max-width: 50%;">
                        <div class="content" style="display: flex;align-items: center;">
                            <div>
                                <label style="font-size: 12px;
                            line-height: 18px;
                            font-weight: 500;
                            color: #000;">Patient's First Name</label>
                                <div class="box-data" style="display: flex; border-right: none;">
                                    <p
                                        style="width: 20px; height: 20px; border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p
                                        style="width: 20px; height: 20px; border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p
                                        style="width: 20px; height: 20px; border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p
                                        style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p
                                        style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p
                                        style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p
                                        style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p
                                        style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p
                                        style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p style="width: 20px; height: 20px;  border: 1px solid #00000094; margin-bottom: 0;"></p>

                                </div>
                            </div>


                            <div style="margin-left: 30px;">
                                <label style="text-align: center; font-size: 12px;
                            line-height: 18px;
                            font-weight: 500;
                            color: #000;">M.I.</label>
                                <div class="box-data" style=" display: flex; border-right: none;">
                                    <p style="width: 20px; height: 20px;  border: 1px solid #00000094; margin-bottom: 0;"></p>

                                </div>
                            </div>

                        </div>


                    </div>
                    <div class="left-side" style="flex: 0 0 50%; max-width: 50%;">
                        <div class="content">
                            <label style="font-size: 12px;
                        line-height: 18px;
                        font-weight: 500;
                        color: #000;">Patient's Last Name</label>
                            <div class="box-data" style=" display: flex; align-items: center; border-right: none;">
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; margin-bottom: 0;"></p>

                            </div>

                        </div>

                    </div>
                </div>
                <div class="clients-details" style="padding-top: 10px; display: flex; align-items: center;">
                    <div class="left-side" style="flex: 0 0 50%; max-width: 50%;">
                        <div class="content" style="">

                            <label style="font-size: 12px;
                            line-height: 18px;
                            font-weight: 500;
                            color: #000;">Patient's Birthdate (MM-DD-YYYY)</label>
                            <div class="box-data" style=" display: flex; border-right: none;">
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; margin-bottom: 0;"></p>
                                <p style=" color: gray; margin-bottom: 0;">--</p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; margin-bottom: 0;"></p>
                                <p style=" color: gray; margin-bottom: 0;">--</p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; margin-bottom: 0;"></p>


                            </div>



                        </div>


                    </div>
                    <div class="left-side" style="flex: 0 0 50%; max-width: 50%;">
                        <div class="content">

                            <label style="font-size: 12px;
                        line-height: 18px;
                        font-weight: 500;
                        color: #000;">Invoice Number (found on front of main page)</label>
                            <div class="box-data" style=" display: flex; border-right: none; align-items: center;">
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; margin-bottom: 0;"></p>
                            </div>


                        </div>

                    </div>
                </div>

                <div class="clients-details"
                    style="padding-top: 10px; padding-bottom: 15px; display: flex; align-items: center;">
                    <div class="left-side" style="flex: 0 0 50%; max-width: 50%;">
                        <div class="content" style="">

                            <label style="font-size: 12px;
                            line-height: 18px;
                            font-weight: 500;
                            color: #000;">Telephone number (please include area code)</label>
                            <div class="box-data" style=" display: flex; border-right: none;">
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; margin-bottom: 0;"></p>
                                <p style=" color: gray; margin-bottom: 0;">--</p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; margin-bottom: 0;"></p>
                                <p style=" color: gray; margin-bottom: 0;">--</p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; margin-bottom: 0;"></p>


                            </div>



                        </div>


                    </div>
                    <div class="left-side" style="flex: 0 0 50%; max-width: 50%;">
                        <div class="content">

                            <label style="font-size: 12px;
                        line-height: 18px;
                        font-weight: 500;
                        color: #000;">Patient's Sex</label>
                            <div class="box-data" style=" display: flex; border-right: none; align-items: center;">
                                <div class="male" style="display: flex; align-items: center;">
                                    <input type="checkbox" name="male" id="male"
                                        style="border: 2px solid #00000094; width: 20px; height: 20px;">
                                    <label style="font-size: 13px;
                        line-height: 18px;
                        font-weight: 500;
                        color: #000; padding-left: 15px;">Male</label>

                                </div>
                                <div class="female" style="display: flex; align-items: center; margin-left: 20px;">
                                    <input type="checkbox" name="male" id="male"
                                        style="border: 2px solid #00000094; width: 20px; height: 20px;">
                                    <label style="font-size: 13px;
                        line-height: 18px;
                        font-weight: 500;
                        color: #000; padding-left: 15px;">Female</label>

                                </div>
                            </div>


                        </div>

                    </div>
                </div>
                <p style="color: #29AAE1; font-size: 12px; font-weight: 600; margin-bottom: 0;">Web payment and insurance filing options
                    are available at patient.medflowllc.com/</p>
                <div class="medical" style="margin-top: 10px;">
                    <p
                        style="padding: 10px; font-weight: 500; background-color: #F0F2F5; color: #000; text-align: center;">
                        Medicare Information</p>
                    <div class="medicare-name" style="padding-top: 10px;">
                        <label style="font-size: 12px;
                    line-height: 18px;
                    font-weight: 500;
                    color: #000;">MedicareNumber (Include letters and/or alpha suffix)</label>
                        <div class="box-data" style=" display: flex; align-items: center; border-right: none;">
                            <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;"></p>
                            <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;"></p>
                            <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;"></p>
                            <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;"></p>
                            <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;"></p>
                            <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;"></p>
                            <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;"></p>
                            <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;"></p>
                            <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;"></p>
                            <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;"></p>
                            <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;"></p>
                            <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;"></p>
                            <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;"></p>
                            <p style="width: 20px; height: 20px;  border: 1px solid #00000094; margin-bottom: 0;"></p>

                        </div>

                    </div>
                    <div class="left-side" style="padding-top: 10px;">
                        <div class="content">

                            <div class="box-data"
                                style=" display: flex; border-right: none; align-items: center; justify-content: space-between;">
                                <div class="male" style="display: flex; align-items: center;">
                                    <input type="checkbox" name="male" id="male"
                                        style="border: 2px solid #00000094; width: 20px; height: 20px;">
                                    <label style="font-size: 13px;
                        line-height: 18px;
                        font-weight: 500;
                        color: #000; padding-left: 15px;">Primary Insurance</label>

                                </div>
                                <div class="male" style="display: flex; align-items: center;">
                                    <input type="checkbox" name="male" id="male"
                                        style="border: 2px solid #00000094; width: 20px; height: 20px;">
                                    <label style="font-size: 13px;
                        line-height: 18px;
                        font-weight: 500;
                        color: #000; padding-left: 15px;">Secondary Insurance</label>

                                </div>
                                <div class="male" style="display: flex; align-items: center;">
                                    <input type="checkbox" name="male" id="male"
                                        style="border: 2px solid #00000094; width: 20px; height: 20px;">
                                    <label style="font-size: 13px;
                        line-height: 18px;
                        font-weight: 500;
                        color: #000; padding-left: 15px;">General</label>

                                </div>
                                <div class="male" style="display: flex; align-items: center;">
                                    <input type="checkbox" name="male" id="male"
                                        style="border: 2px solid #00000094; width: 20px; height: 20px;">
                                    <label style="font-size: 13px;
                        line-height: 18px;
                        font-weight: 500;
                        color: #000; padding-left: 15px;">United Mine Workers</label>

                                </div>
                                <div class="female" style="display: flex; align-items: center; margin-left: 20px;">
                                    <input type="checkbox" name="male" id="male"
                                        style="border: 2px solid #00000094; width: 20px; height: 20px;">
                                    <label style="font-size: 13px;
                        line-height: 18px;
                        font-weight: 500;
                        color: #000; padding-left: 15px;">Railroad</label>

                                </div>
                            </div>


                        </div>

                    </div>

                </div>

                
                <div class="insurance" style="margin-top: 10px;">
                    <p
                        style="padding: 10px; font-weight: 500; background-color: #F0F2F5; color: #000; text-align: center; margin-bottom: 0;">
                        Insurance Information</p>
                    <div class="clients-details" style="padding-top: 10px; display: flex; align-items: center;">
                        <div class="left-side" style="flex: 0 0 50%; max-width: 50%;">
                            <div class="content" style="display: flex;align-items: center;">
                                <div>
                                    <label style="font-size: 12px;
                                    line-height: 18px;
                                    font-weight: 500;
                                    color: #000;">Policyholder's First Name</label>
                                    <div class="box-data" style="display: flex; border-right: none;">
                                        <p
                                            style="width: 20px; height: 20px; border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                        </p>
                                        <p
                                            style="width: 20px; height: 20px; border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                        </p>
                                        <p
                                            style="width: 20px; height: 20px; border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                        </p>
                                        <p
                                            style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                        </p>
                                        <p
                                            style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                        </p>
                                        <p
                                            style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                        </p>
                                        <p
                                            style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                        </p>
                                        <p
                                            style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                        </p>
                                        <p
                                            style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                        </p>
                                        <p style="width: 20px; height: 20px;  border: 1px solid #00000094; margin-bottom: 0;"></p>

                                    </div>
                                </div>


                                <div style="margin-left: 30px;">
                                    <label style="text-align: center; font-size: 12px;
                                    line-height: 18px;
                                    font-weight: 500;
                                    color: #000;">M.I.</label>
                                    <div class="box-data" style=" display: flex; border-right: none;">
                                        <p style="width: 20px; height: 20px;  border: 1px solid #00000094; margin-bottom: 0;"></p>

                                    </div>
                                </div>

                            </div>


                        </div>
                        <div class="left-side" style="flex: 0 0 50%; max-width: 50%;">
                            <div class="content">
                                <label style="font-size: 12px;
                                line-height: 18px;
                                font-weight: 500;
                                color: #000;">Policyholder's Last Name</label>
                                <div class="box-data" style=" display: flex; align-items: center; border-right: none;">
                                    <p
                                        style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p
                                        style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p
                                        style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p
                                        style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p
                                        style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p
                                        style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p
                                        style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p
                                        style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p
                                        style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p
                                        style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p
                                        style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p
                                        style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p
                                        style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p style="width: 20px; height: 20px;  border: 1px solid #00000094; margin-bottom: 0;"></p>

                                </div>

                            </div>

                        </div>
                    </div>
                    <div class="clients-details" style="padding-top: 10px; display: flex; align-items: center;">
                        <div class="left-side" style="flex: 0 0 50%; max-width: 50%;">
                            <div class="content" style="">

                                <label style="font-size: 12px;
                                    line-height: 18px;
                                    font-weight: 500;
                                    color: #000;">Insurance Group Number</label>
                                <div class="box-data" style=" display: flex; border-right: none;">
                                    <p
                                        style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p
                                        style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p
                                        style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p
                                        style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p
                                        style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p
                                        style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p
                                        style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p
                                        style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p
                                        style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p
                                        style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p
                                        style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p
                                        style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p style="width: 20px; height: 20px;  border: 1px solid #00000094; margin-bottom: 0;"></p>


                                </div>



                            </div>


                        </div>
                        <div class="left-side" style="flex: 0 0 50%; max-width: 50%;">
                            <div class="content">

                                <label style="font-size: 12px;
                                line-height: 18px;
                                font-weight: 500;
                                color: #000;">Insurance Identification Number</label>
                                <div class="box-data" style=" display: flex; border-right: none; align-items: center;">
                                    <p
                                        style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p
                                        style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p
                                        style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p
                                        style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p
                                        style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p
                                        style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p
                                        style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p
                                        style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p
                                        style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p
                                        style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p
                                        style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p style="width: 20px; height: 20px;  border: 1px solid #00000094; margin-bottom: 0;"></p>
                                </div>


                            </div>

                        </div>
                    </div>



                </div>
                <div class="clients-details"
                    style="padding-top: 10px; padding-bottom: 15px; display: flex; align-items: center;">
                    <div class="left-side" style="flex: 0 0 50%; max-width: 50%;">
                        <div class="content" style="">

                            <label style="font-size: 12px;
                            line-height: 18px;
                            font-weight: 500;
                            color: #000;">Insurance Company Name</label>
                            <div class="box-data" style=" display: flex; border-right: none;">
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094;margin-bottom: 0;"></p>


                            </div>



                        </div>


                    </div>
                    <div class="left-side" style="flex: 0 0 50%; max-width: 50%;">
                        <div class="content">

                            <label style="font-size: 12px;
                        line-height: 18px;
                        font-weight: 500;
                        color: #000;">Patient's Relationship to insu#00000094</label>
                            <div class="box-data" style=" display: flex; border-right: none; align-items: center;">
                                <div class="male" style="display: flex; align-items: center;">
                                    <input type="checkbox" name="Self" id="Self"
                                        style="border: 2px solid #00000094; width: 20px; height: 20px;">
                                    <label style="font-size: 13px;
                        line-height: 18px;
                        font-weight: 500;
                        color: #000; padding-left: 15px;">Self</label>

                                </div>
                                <div class="female" style="display: flex; align-items: center; margin-left: 20px;">
                                    <input type="checkbox" name="Spouse" id="Spouse"
                                        style="border: 2px solid #00000094; width: 20px; height: 20px;">
                                    <label style="font-size: 13px;
                        line-height: 18px;
                        font-weight: 500;
                        color: #000; padding-left: 15px;">Spouse</label>

                                </div>
                                <div class="female" style="display: flex; align-items: center; margin-left: 20px;">
                                    <input type="checkbox" name="Other" id="Other"
                                        style="border: 2px solid #00000094; width: 20px; height: 20px;">
                                    <label style="font-size: 13px;
                        line-height: 18px;
                        font-weight: 500;
                        color: #000; padding-left: 15px;">Other</label>

                                </div>
                            </div>


                        </div>

                    </div>
                </div>
                <div class="clients-details"
                    style="padding-top: 10px; padding-bottom: 15px; display: flex; align-items: center;">
                    <div class="left-side" style="flex: 0 0 50%; max-width: 50%;">
                        <div class="content" style="">

                            <label style="font-size: 12px;
                            line-height: 18px;
                            font-weight: 500;
                            color: #000;">Insurance Company Address</label>
                            <div class="box-data" style=" display: flex; border-right: none;">
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; margin-bottom: 0;"></p>


                            </div>



                        </div>


                    </div>
                    <div class="left-side" style="flex: 0 0 50%; max-width: 50%;">
                        <div class="content">

                            <label style="font-size: 12px;
                        line-height: 18px;
                        font-weight: 500;
                        color: #000;">Patient's Relationship to Insu#00000094</label>
                            <div class="box-data"
                                style=" display: flex;justify-content: space-between; border-right: none; align-items: center;">
                                <div class="male" style="display: flex; align-items: center;">
                                    <input type="checkbox" name="Primary" id="Primary"
                                        style="border: 2px solid #00000094; width: 20px; height: 20px;">
                                    <label style="font-size: 13px;
                        line-height: 18px;
                        font-weight: 500;
                        color: #000; padding-left: 15px;">Primary Insurance</label>

                                </div>
                                <div class="female" style="display: flex; align-items: center; margin-left: 20px;">
                                    <input type="checkbox" name="Spouse" id="Spouse"
                                        style="border: 2px solid #00000094; width: 20px; height: 20px;">
                                    <label style="font-size: 13px;
                        line-height: 18px;
                        font-weight: 500;
                        color: #000; padding-left: 15px;">Secondry Insurance</label>

                                </div>

                            </div>


                        </div>

                    </div>
                </div>
                <div class="clients-details" style="padding-top: 10px; display: flex; align-items: center;">
                    <div class="left-side" style="flex: 0 0 50%; max-width: 50%;">
                        <div class="content">
                            <label style="font-size: 12px;
                        line-height: 18px;
                        font-weight: 500;
                        color: #000;">City</label>
                            <div class="box-data" style=" display: flex; align-items: center; border-right: none;">
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                </p>
                                <p style="width: 20px; height: 20px;  border: 1px solid #00000094; margin-bottom: 0;"></p>

                            </div>

                        </div>

                    </div>
                    <div class="right-side" style="flex: 0 0 50%; max-width: 50%;">
                        <div class="content" style="display: flex;align-items: center;">

                            <div style="margin-right: 30px; text-align: center;">
                                <label style="text-align: center; font-size: 12px;
                            line-height: 18px;
                            font-weight: 500;
                            color: #000;">State</label>
                                <div class="box-data" style=" display: flex;">
                                    <p
                                        style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p style="width: 20px; height: 20px;  border: 1px solid #00000094;margin-bottom: 0;"></p>

                                </div>
                            </div>
                            <div>
                                <label style="font-size: 12px;
                            line-height: 18px;
                            font-weight: 500;
                            color: #000;"> Zip Code</label>
                                <div class="box-data" style="display: flex; border-right: none;">
                                    <p
                                        style="width: 20px; height: 20px; border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p
                                        style="width: 20px; height: 20px; border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p
                                        style="width: 20px; height: 20px; border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>
                                    <p
                                        style="width: 20px; height: 20px;  border: 1px solid #00000094; border-right: none; margin-bottom: 0;">
                                    </p>

                                    </p>
                                    <p style="width: 20px; height: 20px;  border: 1px solid #00000094; margin-bottom: 0;"></p>

                                </div>
                            </div>



                        </div>


                    </div>

                </div>
                <div class="address" style="padding-top: 40px; display: flex; align-items: center;">
                    <div class="left-side" style="display: flex; align-items: flex-end; width: 65%;">
                        <p style="font-size: 12px;
line-height: 18px;
font-weight: 500;
color: #000; margin-bottom: 0;">Street Address:&nbsp;</p>
                        <p style="border-bottom: 2px solid #00000094; width: 70%; margin-bottom: 0;"></p>
                    </div>
                    <div class="right-side"
                        style="padding-left: 15px; display: flex; align-items: flex-end; width: 30%;">
                        <p style="font-size: 12px;
                        line-height: 18px;
                        font-weight: 500;
                        color: #000; margin-bottom: 0;">Date:&nbsp;</p>
                        <p style="border-bottom: 2px solid #00000094; width: 70%; margin-bottom: 0;"></p>
                    </div>

                </div>
                <p style="font-size: 12px;
                text-align: center;
                padding-top: 20px;
                line-height: 25px;
                margin-bottom: 0;">
                    To pay your bill online, visit patient.medflowllc.com <br>
                    Payment arrangements can be made with no additional fee by calling {invoiceSettingPhone} from 8 AM - 8 PM,
                    Monday - Friday
                </p>

            </div>


        </div>

    </div>


`;

export const biolab2 = (data) => {
  return `

    <div id="wrapper">
        <div class="container" style="max-width: 920px; margin: 0 auto; padding: 5px 10px;">
            <div class="pay-online"
                style="background-color: #F0F2F5; padding: 10px; margin-top: 10px; display: flex; align-items: center; justify-content: space-between;">
                <div class="pay-left">
                    <div class="content" style="display: flex; align-items: center;">

                        <div>
                            <h2 style="margin: 0; text-transform: uppercase; text-transform: uppercase; font-size: 15px;
                            font-weight: 500;">GUIDE TO UDERSTANDING YOUR BILL</h2>

                        </div>
                    </div>


                </div>
                <div class="pay-right">
                    <div style="">
                        <img width="40px" src=${data.qr} alt="scanner" />
                    </div>
                </div>

            </div>
            <p style="font-size: 10px;
            padding-top: 10px;
            line-height: 18px;
            margin-bottom: 0;">
                Depending upon the portion of cost collected at check-in and any additional services you received, you
                may receive another bill.
            </p>
            <p style="font-size: 10px;
            line-height: 18px;
            margin-bottom: 0;">
                This sample hospital bill explains some of the key terms and illustrates how services you received for
                medical care and subsequent payments may be reflected.
            </p>
            <div class="information" style="margin-top: 10px;">

                <div class="table">
                    <table style="border: 1px solid #ccc; width: 100%;
                    border-collapse: collapse;">
                        <tr>
                            <th rowspan="2"
                                style="border-left: 1px solid #ccc;
                                border-right: 1px solid #ccc;
                                background: #E1E4EB;
                                text-align: center;
                                font-size: 10px;
                                font-weight: 500;
                                padding: 5px;">#</th>
                            <th rowspan="2"
                                style="border-left: 1px solid #ccc;
                                border-right: 1px solid #ccc;
                                background: #E1E4EB;
                                text-align: center;
                                font-size: 10px;
                                font-weight: 500;
                                padding: 5px;">Service Date</th>
                            <th rowspan=" 2"
                                style="border-left: 1px solid #ccc;
                                border-right: 1px solid #ccc;
                                background: #E1E4EB;
                                text-align: center;
                                font-size: 10px;
                                font-weight: 500;
                                padding: 5px;"
                                >Test Discription</th>
                            <th rowspan=" 2"
                                style="border-left: 1px solid #ccc;
                                border-right: 1px solid #ccc;
                                background: #E1E4EB;
                                text-align: center;
                                font-size: 10px;
                                font-weight: 500; padding: 5px;">Billed to Plan</th>
                            <th rowspan=" 2"
                                style="border-left: 1px solid #ccc;
                                border-right: 1px solid #ccc;
                                background: #E1E4EB;
                                text-align: center;
                                font-size: 10px;
                                font-weight: 500; padding: 5px;">Covered by Plan</th>
                            <th colspan=" 2" style="border-left: 1px solid #ccc;
                            border-right: 1px solid #ccc;
                            text-align: center;
                            font-size: 11px;
                            font-weight: 500; padding: 5px;">Your Share</th>
                        </tr>
                        <tr>
                            <td style="border-left: 1px solid #ccc;
                            border-right: 1px solid #ccc;
                            background: #E1E4EB;
                            text-align: center;
                            font-size: 10px;
                            font-weight: 500; padding: 1px 2px;">
                                Paid by you</td>
                            <td style="border-left: 1px solid #ccc;
                            border-right: 1px solid #ccc;
                            background: #E1E4EB;
                            text-align: center;
                            font-size: 11px;
                            font-weight: 500; padding: 1px 2px;">Your owe</td>
                        </tr>
                        <tr>
                            <td colspan=" 7"
                                style="background: #27A7DD; border-left: 1px solid #ccc;
                                border-right: 1px solid #ccc;
                                text-align: center;
                                font-size: 11px;
                                font-weight: 500;">DOE,JNAE Q</td>
                            
                        </tr>
                        <tr style=" padding-top: 20px; border-bottom: none;">
                            <td rowspan="5" style="text-align: center; padding: 3px 1px;
                            border-left: 1px solid #ccc;
                            border-right: 1px solid #ccc;
                            font-size: 10px;
        line-height: 12px;">A</td>
                            <td style="text-align: center; padding: 3px 1px;
                            border-left: 1px solid #ccc; border-right: 1px solid #ccc; font-size: 10px;
                            line-height: 12px;">02/12/23 - 04/3/2024</td>

                            <td style=" padding: 2px 1px 2px 6px; border-left: 1px solid rgb(204, 204, 204);font-size: 10px;
                            line-height: 12px; border-right: 1px solid rgb(204, 204, 204);
                        ">INPATIENT SERVICES</td>
                            <td style="text-align: right;
                            padding: 2px 1px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204); font-size: 10px;
                            line-height: 12px;">$7555.00</td>
                            <td style="text-align: right; padding: 2px 1px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204); font-size: 10px;
                            line-height: 12px;"></td>
                            <td style="text-align: right; padding: 2px 1px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204); font-size: 10px;
                            line-height: 12px;"></td>
                            <td style="text-align: right; padding: 2px 1px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204); font-size: 10px;
                            line-height: 12px;"></td>
                        </tr>
                        <tr style="border-bottom: none;">

                            <td style="text-align: center;  padding: 3px 1px;
                            border-left: 1px solid #ccc; border-right: 1px solid #ccc;font-size: 10px;
                            line-height: 12px;">07/17/2023</td>
                            <td style="padding: 2px 1px 2px 9px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);font-size: 10px;
                            line-height: 12px;">PATIENT PAYMENT (AT CHECK-IN)</td>
                            <td style="text-align: right; padding: 2px 1px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);font-size: 10px;
                            line-height: 12px;"></td>
                            <td style="text-align: right; padding: 2px 1px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);font-size: 10px;
                            line-height: 12px;"></td>
                            <td style="text-align: right; padding: 2px 1px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);font-size: 10px;
                            line-height: 12px;"><span
                                    style="font-size: 12px; color: #27A7DD;">B</span>-$500.00</td>
                            <td style="text-align: right; padding: 2px 1px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);font-size: 10px;
                            line-height: 12px;"></td>
                        </tr>
                        <tr style="border-bottom: none;">

                            <td style="text-align: center;  padding: 3px 1px;
                            border-left: 1px solid #ccc; border-right: 1px solid #ccc; font-size: 10px;
                            line-height: 12px;">07/19/2024</td>
                            <td style="padding: 2px 1px 2px 13px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);font-size: 10px;
                            line-height: 12px;">PATIENT PAYMENT</td>
                            <td style="text-align: right; padding: 2px 1px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);font-size: 10px;
                            line-height: 12px;"></td>
                            <td style="text-align: right; padding: 2px 1px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);font-size: 10px;
                            line-height: 12px;"></td>
                            <td style="text-align: right; padding: 2px 1px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);font-size: 10px;
                            line-height: 12px;"><span
                                    style="font-size: 12px; color: #27A7DD;">E</span>-$500.00</td>
                            <td style="text-align: right; padding: 2px 1px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);font-size: 10px;
                            line-height: 12px;"></td>
                        </tr>
                        <tr style="border-bottom: none;">

                            <td style="text-align: center; padding: 3px 1px;
                            border-left: 1px solid #ccc; border-right: 1px solid #ccc;font-size: 10px;
                            line-height: 12px;"></td>
                            <td style=" padding: 2px 1px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);font-size: 10px;
                            line-height: 12px;">HOSPITAL ACCOUNT# 123000896 TOTAL</td>
                            <td style="text-align: right; padding: 2px 1px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);font-size: 10px;
                            line-height: 12px;"><span
                                    style="font-size: 12px; color: #27A7DD;">E</span>-$7550.00</td>
                            <td style="text-align: right; padding: 2px 1px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);font-size: 10px;
                            line-height: 12px;"><span
                                    style="font-size: 12px; color: #27A7DD;">E</span>-$7550.00</td>
                            <td style="text-align: right; padding: 2px 1px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);font-size: 10px;
                            line-height: 12px;">-$600.00</td>
                            <td style="text-align: right; padding: 2px 1px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);font-size: 10px;
                            line-height: 12px;"><span
                                    style="font-size: 12px; color: #27A7DD;font-size: 10px;
                                    line-height: 12px;">F</span>$7550.00</td>
                        </tr>
                        <tr style="border-bottom: 0;">

                            <td style="text-align: center;  padding: 3px 1px;
                            border-left: 1px solid #ccc; border-right: 1px solid #ccc;font-size: 10px;
                            line-height: 12px;"></td>
                            <td
                                style="background: #AADBF6; text-align: right; font-weight: 500;  padding: 2px 10px 2px 1px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);font-size: 10px;
                                line-height: 12px;">
                                TOTAL FOR DOE,JANE Q</td>
                            <td style="background: #AADBF6; text-align: right; padding: 2px 1px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);font-size: 10px;
                            line-height: 12px;"><span
                                    style="font-size: 12px; color: #27A7DD;"></span>-$7550.00</td>
                            <td style="background: #AADBF6; text-align: right; padding: 2px 1px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);font-size: 10px;
                            line-height: 12px;"><span
                                    style="font-size: 12px; color: #27A7DD;"></span>$7550.00</td>
                            <td style="background: #AADBF6; text-align: right; padding: 2px 1px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);font-size: 10px;
                            line-height: 12px;">-$600.00</td>
                            <td style="background: #AADBF6; text-align: right; padding: 2px 1px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);font-size: 10px;
                            line-height: 12px;"></td>
                        </tr>
                        <tr>
                            <td colspan="7"
                                style="background: rgb(39, 167, 221);
                                border-left: 1px solid rgb(204, 204, 204);
                                border-right: 1px solid rgb(204, 204, 204);
                                text-align: center;
                                font-size: 11px;
                                font-weight: 500;">DOE,JNAE Q</td>
                            
                        </tr>
                        <tr style=" padding-top: 20px; border-bottom: 0;">
                            <td rowspan="5" style="text-align: center; text-align: center; padding: 3px 1px;
                            border-left: 1px solid #ccc; border-right: 1px solid #ccc;font-size: 10px;
                            line-height: 12px;">G</td>
                            <td style="text-align: center; padding: 3px 1px;
                            border-left: 1px solid #ccc; border-right: 1px solid #ccc;font-size: 10px;
                            line-height: 12px;"></td>
                            <td style="padding: 2px 1px 2px 6px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);font-size: 10px;
                            line-height: 12px;"> LAB CREATININE BLOOD MEASUREMENT</td>
                            <td style="text-align: right;  padding: 2px 1px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);font-size: 10px;
                            line-height: 12px;">$7555.00</td>
                            <td style="text-align: right; padding: 2px 1px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);font-size: 10px;
                            line-height: 12px;"></td>
                            <td style="text-align: right; padding: 2px 1px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);font-size: 10px;
                            line-height: 12px;"></td>
                            <td style="text-align: right; padding: 2px 1px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);font-size: 10px;
                            line-height: 12px;"></td>
                        </tr>
                        <tr style="border-bottom: 0;">

                            <td style="text-align: center; padding: 3px 1px;
                            border-left: 1px solid #ccc; border-right: 1px solid #ccc;font-size: 10px;
                            line-height: 12px;">02/17/2023</td>
                            <td style=" padding: 2px 1px 2px 9px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);font-size: 10px;
                            line-height: 12px;">PATIENT PAYMENT (AT CHECK-IN)</td>
                            <td style="text-align: right; padding: 2px 1px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);"></td>
                            <td style="text-align: right; padding: 2px 1px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);font-size: 10px;
                            line-height: 12px;"></td>
                            <td style="text-align: right; padding: 2px 1px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);font-size: 10px;
                            line-height: 12px;"><span
                                    style="font-size: 12px; color: #27A7DD;">B</span>-$500.00</td>
                            <td style="text-align: right; padding: 2px 1px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);font-size: 10px;
                            line-height: 12px;"></td>
                        </tr>

                        <tr style="border-bottom: 0;">

                            <td style="text-align: center; padding: 3px 1px;
                            border-left: 1px solid #ccc; border-right: 1px solid #ccc;font-size: 10px;
                            line-height: 12px;">03/19/2024</td>
                            <td style=" padding: 2px 1px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);font-size: 10px;
                            line-height: 12px;">HOSPITAL ACCOUNT# 123000896 TOTAL</td>
                            <td style="text-align: right;  padding: 2px 1px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);font-size: 10px;
                            line-height: 12px;"><span
                                    style="font-size: 12px; color: #27A7DD;">E</span>-$7550.00</td>
                            <td style="text-align: right; padding: 2px 1px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);font-size: 10px;
                            line-height: 12px;"><span
                                    style="font-size: 12px; color: #27A7DD;">E</span>-$7550.00</td>
                            <td style="text-align: right; padding: 2px 1px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);font-size: 10px;
                            line-height: 12px;">-$600.00</td>
                            <td style="text-align: right; padding: 2px 1px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);font-size: 10px;
                            line-height: 12px;"><span
                                    style="font-size: 12px; color: #27A7DD;">J</span>$7550.00</td>
                        </tr>
                        <tr>

                            <td style="text-align: center; padding: 3px 1px;
                            border-left: 1px solid #ccc; border-right: 1px solid #ccc;font-size: 10px;
                            line-height: 12px;"></td>
                            <td
                                style="background: #AADBF6; text-align: right; font-weight: 500;  padding: 2px 1px 2px 10px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);font-size: 10px;
                                line-height: 12px;">
                                TOTAL FOR DOE,JANE Q</td>
                            <td style="background: #AADBF6; text-align: right; padding: 2px 1px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);font-size: 10px;
                            line-height: 12px;"><span
                                    style="font-size: 12px; color: #27A7DD;"></span>-$7550.00</td>
                            <td style="background: #AADBF6; text-align: right; padding: 2px 1px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);font-size: 10px;
                            line-height: 12px;"><span
                                    style="font-size: 12px; color: #27A7DD;"></span>$7550.00</td>
                            <td style="background: #AADBF6; text-align: right; padding: 2px 1px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);font-size: 10px;
                            line-height: 12px;">-$600.00</td>
                            <td style="background: #AADBF6; text-align: right; padding: 2px 1px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);font-size: 10px;
                            line-height: 12px;"><span
                                    style="font-size: 12px; color: #27A7DD;"></span>$00</td>
                        </tr>
                        <tr style="border-bottom: none;">

                            <td style="text-align: center; padding: 2px 1px;
                            border-left: 1px solid #ccc; border-right: 1px solid #ccc;font-size: 10px;
                            line-height: 12px;"></td>
                            <td
                                style="border-top: 1px solid #ccc; background: #AADBF6; text-align: right; font-weight: 500;  padding: 2px 10px 2px 1px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);font-size: 10px;
                                line-height: 12px;">
                                TOTAL</td>
                            <td style="border-top: 1px solid #ccc; background: #AADBF6; text-align: right; padding: 2px 1px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);font-size: 10px;
                            line-height: 12px;"><span
                                    style="font-size: 12px; color: #27A7DD;"></span>-$7550.00</td>
                            <td style="border-top: 1px solid #ccc; background: #AADBF6; text-align: right; padding: 2px 1px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);font-size: 10px;
                            line-height: 12px;"><span
                                    style="font-size: 12px; color: #27A7DD;"></span>$7550.00</td>
                            <td style="border-top: 1px solid #ccc; background: #AADBF6; text-align: right;  padding: 2px 1px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);font-size: 10px;
                            line-height: 12px;">-$600.00
                            </td>
                            <td style="border-top: 1px solid #ccc; background: #AADBF6; text-align: right; padding: 2px 1px; border-left: 1px solid rgb(204, 204, 204); border-right: 1px solid rgb(204, 204, 204);font-size: 10px;
                            line-height: 12px;"><span
                                    style="font-size: 12px; color: #27A7DD;">K</span>$780.00</td>
                        </tr>
                    </table>
                </div>
                <div class="example-box" style="display: flex; margin-top: 10px;">
                    <div style="width: 50%; padding-right: 5px; ">
                        <div style="border: 1px solid #ccc; padding: 12px; height: 230px;">
                            <p style="font-size: 9px; line-height: 18px;  margin-bottom: 0;">
                                <span style="font-size: 12px; color: #27A7DD;">A</span><strong> Example 1:</strong> Jane
                                Doe was admitted to Sample
                            </p>
                            <p style="font-size: 9px; line-height: 15px;  margin-bottom: 0;">
                                City Medical Center on February 17, 2024 and discharged on February 22, 2024.
                                She was assigned a hospital account number, which tracks the services received,
                                as well as any payments made throughout the course of her stay.
                            </p>
                            <p style="font-size: 9px; line-height: 15px;  margin-bottom: 0; padding-top: 5px;">
                                <span style="font-size: 12px; color: #27A7DD;">B</span> Jane paid $500 when she checked into the hospital, which was posted to her hospital account the following day.
                            </p>
                            <p style="font-size: 9px; line-height: 15px; padding-top: 5px;  margin-bottom: 0;">
                                <span style="font-size: 12px; color: #27A7DD;">C</span> The total cost of the services Jane received during her hospital stay was $75,500.
                            </p>
                            <p style="font-size: 9px; line-height: 15px; padding-top: 5px;  margin-bottom: 0;">
                                <span style="font-size: 12px; color: #27A7DD;">D</span> Based on Jane's benefit plan, her medical insurance paid $72,500 of her inpatient costs, leaving a balance of $2,500 ($75,500-$72,500-$500).
                            </p>
                            <p style="font-size: 9px; line-height: 15px; padding-top: 5px;  margin-bottom: 0;">
                                <span style="font-size: 12px; color: #27A7DD;">E</span> On March 15, Jane paid $100 toward her stay.
                            </p>
                            <p style="font-size: 9px; line-height: 15px; padding-top: 5px;  margin-bottom: 0;">
                                <span style="font-size: 12px; color: #27A7DD;">F</span> Jane still owes $2,400 for her
                                hospital stay.
                            </p>
                        </div>

                    </div>
                    <div style="width: 50%; padding-left: 5px;">
                        <div style="border: 1px solid #ccc; padding: 12px; height: 230px;">
                            <p style="font-size: 9px; line-height: 18px;  margin-bottom: 0;">
                                <span style="font-size: 12px; color: #27A7DD;">G</span><strong> Example 2:</strong> On February 15, Jae's sixteen years old son, John Doe, visited his primary care physician, who sent him for lab tests. Although he visited the lab in his doctor's office, his lab test was processed at Sample City Medical Center, so the tests appear on the Doe family's hospital bill. He was assigned a hospital account number, even though John was never admitted to hospital.
                            </p>
                            <!-- <p style="font-size: 9px; line-height: 15px;  margin-bottom: 0;">
                                account number, which tracks the services received, as well as any payments made
                                throughout the course of her stay. Lorem Ipsum is simply dummy text of the printing
                                and typesetting
                            </p> -->
                            <p style="font-size: 9px; line-height: 15px; padding-top: 5px; margin-bottom: 0;">
                                <span style="font-size: 12px; color: #27A7DD;">H</span> John's benefit plan has a $40 copay for lab tests. He paid the $40 at the lab and it was posted to his hospital account.
                            </p>
                            <p style="font-size: 9px; line-height: 15px; padding-top: 5px; margin-bottom: 0;">
                                <span style="font-size: 12px; color: #27A7DD;">I</span> His insurance paid the remaining charges, $110.
                            </p>
                            <p style="font-size: 9px; line-height: 15px; padding-top: 5px; margin-bottom: 0;">
                                <span style="font-size: 12px; color: #27A7DD;">J</span> John's services were paid in full, so the current bill reflects a $0 balance.
                            </p>
                            <p style="font-size: 9px; line-height: 15px; padding-top: 5px; margin-bottom: 0;">
                                <span style="font-size: 12px; color: #27A7DD;">K</span> The Doe family currently owes $2,400 on this hospital bill. Charges are due to Kaiser Permanente within 30 days of the bill date
                                </p>
                        </div>

                    </div>

                </div>
                <div class="terms">
                    <div style="margin-top: 10px; border: 1px solid #ccc; padding: 10px; ">
                        <h2 style="font-size: 16px; line-height: 22px; margin-top: 5px; margin-bottom: 5px; font-weight: 600;">Key Terms
                            and Definitions:</h2>
                        <p style="font-size: 9px; line-height: 12px; margin-bottom: 4px;">
                            <strong>Date:</strong> The date you or a family member received hospital services, as well as the date Kaiser Permanente processed payments and adjustments related to services.
                        </p>
                        <p style="font-size: 9px; line-height: 12px; margin-bottom: 4px;">
                            <strong>Hospital Account #:</strong> A unique Kaiser Permanente identification number assigned to a patient to track services received and the billing (charges, payments and adjustments) associated with that activity. Each hospital visit is assigned a different hospital account number.
                        </p>
                        <p style="font-size: 9px; line-height: 12px; margin-bottom: 4px;">
                            <strong>Billed to Plan:</strong> The total cost of services received. These charges reflect the cost of Kaiser Permanente services before any consideration of insurance coverage.
                        </p>
                        <p style="font-size: 9px; line-height: 12px; margin-bottom: 4px;">
                            <strong>Covered by Plan:</strong> The amount that your insurance paid for the services provided to you, based on your plan benefits.Adjustments and discounts applied by Kaiser Permanente will also be reflected here.
                        </p>
                        <p style="font-size: 9px; line-height: 12px; margin-bottom: 4px;">
                            <strong>Paid by You:</strong> The amount you have paid to-date for services received.
                        </p>
                        <p style="font-size: 9px; line-height: 12px; margin-bottom: 4px;">
                            <strong>Past Due (page 1):</strong>This reflects balances over 30 days old and not paid since your last bill.
                        </p>
                        <p style="font-size: 9px; line-height: 12px; margin-bottom: 4px;">
                            <strong>Billing Details (page 3):</strong> Includes all medical services and payments processed since your last bill, as well as previous medical services not yet paid in full.
                        </p>
                        <p style="font-size: 9px; line-height: 12px; margin-bottom: 4px;">
                            <strong>Pending Hospital Accounts:</strong> Hospital accounts with patient payments awaiting completion of insurance billing activity.
                        </p>
                    </div>

                </div>
                <div style="background: #F1F2F6; padding: 15px 10px; text-align: center; margin-top: 10px;">
                    <p style="font-size: 9px; line-height: 12px; margin-bottom: 4px; font-weight: 500;">
                        STATE-MANDATED DISCLOSURE: THE STATE OF CALIFORNIA REQUIRES THE FOLLOWING BILLING DISCLOSURE FOR
                    </p>
                    <p style="font-size: 9px; line-height: 12px; margin-bottom: 4px;">
                        PATIENTS WHO WERE TREATED BY AN OUT OF NETWORK PROVIDER: This communication is not a bill but an
                        estimate of what is owed.
                    </p>
                </div>


            </div>


        </div>

    </div>

  `;
};

export const biolab1 = (CONFIG, data) => `

    <div id="wrapper">
        <div class="container" style="max-width: 920px; margin: 0 auto; padding: 5px 10px;">
            <div class="top-bar" style="display: flex; align-items: flex-end;
            justify-content: space-between;">
                <div class="left-side" style="">
                    <div class="logo" style="">
                        <img width="220px" src=${require(`assets/img/${CONFIG.labName}.png`).default} alt="logo" />

                    </div>
                </div>
                <div class="right-side" style="">
                    <div class="content-b">



                    </div>
                </div>
            </div>
            <div class="pay-online"
                style="background-color: #F0F2F5; padding: 10px; margin-top: 10px; display: flex; align-items: center; justify-content: space-between;">
                <div class="pay-left">
                    <div class="content" style="display: flex; align-items: center;">

                        <div>
                            <h2 style="margin: 0; text-transform: uppercase; text-transform: uppercase; font-size: 15px;
                            font-weight: 500;">IMPORTANT NOTICES ABOUT YOUR BILL</h2>

                        </div>
                    </div>


                </div>


            </div>

            <div class="information" style="">

                <div class="example-box" style="display: flex; ">
                    <div style="width: 50%; padding-right: 5px;">
                        <div style="border: 1px solid #ccc; padding: 12px 12px 74px 12px;">
                            <div class="para">
                                <p style="font-size: 9px; line-height: 11px; margin-bottom: 4px; font-weight: 600;">
                                    What if I have a question about my medical bill?
                                </p>
                                <p style="font-size: 9px; line-height: 11px; margin-bottom: 4px; ">
                                    For questions about your bill, or need an itemized bill, contact Patient
                                    Financial Services (PFS) at {invoiceSettingPhone} weekdays from 8 AM to 8
                                    PM or write to us at
                                    <br>

                                    {companyName}<br>
                                    zipcode: {companyZip}<br>
                                    weekdays 8 AM to 5 PM ${data.timezone}<br>
                                    This is important information from {companyName}. if you need help
                                    understanding this information, please call {invoiceSettingPhone} and ask for
                                    language assistance. Help is available 24 hours a day, 7 days a week,
                                    excluding holidays.
                                </p>
                            </div>
                            <div class="para">
                                <p style="font-size: 9px; line-height: 11px; margin-bottom: 4px; font-weight: 600;">
                                    I paid when i checked in for my appointment. why did i get a bill later for
                                    additional costs?
                                </p>
                                <p style="font-size: 9px; line-height: 11px; margin-bottom: 4px;">
                                    If you receive any unscheduled services during your visit, what you paid
                                    at check-in usually won't cover your total costs. This is because the
                                    amount you pay when checking in is based on the services you'r
                                    scheduled to receive. During your visit, your doctor may arrange for
                                    additional services (like a blood test or an X-ray), so the actual cost of
                                    your visit may be higher.
                                </p>
                            </div>
                            <div class="para">
                                <p style="font-size: 9px; line-height: 11px; margin-bottom: 4px; font-weight: 600;">
                                    I went in for a preventive care visit that was supposed to be provided at little or
                                    no charge, Why did | get a bill later?
                                </p>
                                <p style="font-size: 9px; line-height: 11px; margin-bottom: 4px;">

                                    During a preventive care visit, you may receive both preventive and non-
                                    preventive care services. if so, you'll probably need to pay a copay or
                                    coinsurance for the non-preventive treatment or services. For example,
                                    during a routine physical exam your doctor might decide that a mole
                                    needs to be removed for testing. Because mole removal and testing are
                                    considered non-preventive, you'd probably have to pay a copay or
                                    coinsurance for these services.
                                </p>
                            </div>
                            <div class="para">
                                <p style="font-size: 9px; line-height: 11px; margin-bottom: 4px; font-weight: 600;">
                                    What if I have a flexible spending account?
                                </p>
                                <p style="font-size: 9px; line-height: 11px; margin-bottom: 4px;">If you have a flexible spending account
                                    (FSA), please keep this bill for
                                    reimbursement and tax purpose.
                                </p>
                            </div>


                        </div>
                        <div style="padding: 10px; background: #002552;">
                            <p style="font-size: 11px;
                            line-height: 18px;
                            margin-bottom: 4px;
                            font-weight: 500;
                            text-align: center;
                            color: #Ffff;">
                            </p>

                        </div>

                    </div>
                    <div style="width: 50%; padding-left: 5px;">
                        <div style="border: 1px solid #ccc; padding: 12px;">

                            <div class="para">
                                <p style="font-size: 9px; line-height: 11px; margin-bottom: 4px; font-weight: 600;">
                                    Why am i receiving multiple bill?
                                </p>
                                <p style="font-size: 9px; line-height: 11px; margin-bottom: 4px;">
                                    Depending upon where you received your services, you may receive a
                                    professional bill, a hospital bill or both. For example, if your doctor
                                    admits you to the hospital, you can expect to receive a hospital bill for
                                    services (inpatient hospital stay, lab fees, etc.) and a separate physician
                                    bill for services provided by your doctor.
                                </p>
                            </div>
                            <div class="para">
                                <p style="font-size: 9px; line-height: 11px; margin-bottom: 4px; font-weight: 600;">
                                    Why am I not seeing a service I received or payment i made?
                                </p>
                                <p style="font-size: 9px; line-height: 11px; margin-bottom: 4px;">
                                    Any services received or billed after the statement date will not appear
                                    on this bill. Services and related payments may take up to 125 days to
                                    appear, but occasionally some services and payments will take longer. If
                                    so, these services and payments will appear on a future bill.
                                </p>
                            </div>
                            <div class="para">
                                <p style="font-size: 9px; line-height: 11px; margin-bottom: 4px; font-weight: 600;">
                                    What if my healthcare coverage has changed? What if I have other healthcare
                                    coverage?
                                </p>
                                <p style="font-size: 9px; line-height: 11px; margin-bottom: 4px;">
                                    If you have change, please contact our member service Call Center at
                                    {invoiceSettingPhone} weekdays from 8 AM to 8 PM ${
                                      data.timezone
                                    }, or TTY for deaf, hard
                                    of hearing or speech impaired call 511. if you don’t have health insurance
                                    coverage, you may be eligible for coverage through medicare, California
                                    Health Benefit Exchange or other state or county funded coverage such
                                    as Healthy Families, Medi-Cal, and California Children’s Services
                                    Program.You can get an application for the Medi-Cal program and the
                                    Healthy Families Program from the Financial Counseling Department at
                                    any {companyName}. For coverage through the California Health Benefit
                                    Exchange, go to www.coveredca.com or contact a {companyName} representative
                                    at {invoiceSettingPhone} for more information.
                                </p>
                            </div>
                            <div class="para">
                                <p style="font-size: 9px; line-height: 11px; margin-bottom: 4px; font-weight: 600;">
                                    What if I have a question about my {companyName} Permanente Health Plan benefits?
                                </p>
                                <p style="font-size: 9px; line-height: 11px; margin-bottom: 4px;">
                                    You may view your membership status and benefits on-line at
                                    portal.medflowllc.com or you may call member Service Call Center at
                                    {invoiceSettingPhone}
                                </p>
                            </div>
                            <div class="para">
                                <p style="font-size: 9px; line-height: 11px; margin-bottom: 4px; font-weight: 600;">

                                    What if I need help paying?
                                </p>
                                <p style="font-size: 9px; line-height: 11px; margin-bottom: 4px;">

                                    If you meet certain income requirements or have a special circumstance,
                                    you may qualify for {companyName} discounted payment or charity care. for more
                                    information and to apply, please see portal. medflowllc.com or call the
                                    Medical Financial Assistance program (MFAP) Hotline at
                                    1-833-294-8002 weekdays 8 AM to 8 PM ${data.timezone}.
                                </p>
                            </div>
                            <div class="para">
                                <p style="font-size: 9px; line-height: 11px; margin-bottom: 4px; font-weight: 600;">
                                    What do i do if I think i am due a refund?
                                </p>
                                <p style="font-size: 9px; line-height: 11px; margin-bottom: 4px;">
                                    Once we determine all services have been processed and charges billed,
                                    should our records indicate you have overpaid, a refund will be issued.
                                    if you feel you are due a refund, please contact Member Services at
                                    the number listed above.
                                </p>
                            </div>
                            <div class="para">
                                <p style="font-size: 9px; line-height: 11px; margin-bottom: 4px; font-weight: 600;">
                                    Will I be charged a service fee for a returned check?
                                </p>
                                <p style="font-size: 9px; line-height: 11px; margin-bottom: 4px;">
                                    Yes, you will be charged a minimum $25 service fee.
                                </p>
                            </div>

                        </div>

                    </div>

                </div>
                <div class="detch" style="padding-top: 20px;">
                    <P
                        style="font-size: 14px; text-align: center; margin-bottom: 10px; line-height: 16px; text-transform: uppercase;">
                        - - - - - - - - - - - - - - - - - - - - - -PLEASE DETACH AND RETURN THIS PORTION WITH YOUR PAYMENT - - - - - - - - - - - - -
                        - - - - - - </P>
                    <p style="font-size: 9px; text-align: center; line-height: 21px;">Rosenthal fair debt Collection
                        Practices Act:
                        In the event your account has been assigned to a collection agency, state and federal law requires debt collectors to 
                        treat you fairly and prohibits debt collectors from making false statements or threats of violence, using obscene or profane 
                        language, and making improper communications with third parties, including Your employer. Except under usual circumstances, 
                        debt collectors may not contact you before 8a.m. or after 9p.m. In general. A debt collector may not give information about 
                        your debt to another person. Other than your attorney or spouse. A debt collector my contact another person to confirm your 
                        location or to enforce a judgment. For more information about debt collection activities, you may contact the Federal 
                        Trade Commission by telephone at 1-877-FTC-HELP (382-4357) or online at www.ftc.gov Non Profit credit counseling services 
                        may be available in your area.</p>

                </div>

                <div class="example-box" style="display: flex; margin-top: 15px; ">
                    <div style="width: 50%; padding-right: 5px;">
                        <div class="pay-online"
                            style="background-color: #F0F2F5; padding: 8px;display: flex; align-items: center; justify-content: space-between;">
                            <h2 style="margin: 0; text-transform: uppercase; text-transform: uppercase; font-size: 12px;
                            font-weight: 500;">New mailing address:</h2>
                        </div>
                        <div
                            style="border: 1px solid #ccc; padding: 12px 12px 12px 12px; border-top: none; height: 200px;">
                            <div style="margin-bottom: 10px;">

                                <label style="font-size: 12px; line-height: 11px; margin-bottom: 4px; font-weight: 500;"
                                    for="street">Street Address:</label>
                                <input type="text" id="street" name="street"
                                    style="width: 100%;
                                 max-width: 72%; outline: none; border: none; font-size: 16px; border-bottom: 1px solid #333;">
                            </div>
                            <div style="margin-bottom: 10px;">

                                <label style="font-size: 12px; line-height: 11px; margin-bottom: 4px; font-weight: 500;"
                                    for="street">Apt #:</label>
                                <input type="text" id="street" name="street"
                                    style="width: 100%;
                                 max-width: 88%; outline: none; border: none; font-size: 16px; border-bottom: 1px solid #333;">
                            </div>
                            <div style="margin-bottom: 10px; display: flex;">
                                <div style="width: 33.33%;">
                                    <label
                                        style="font-size: 12px; line-height: 11px; margin-bottom: 4px; font-weight: 500;"
                                        for="street">City:</label>
                                    <input type="text" id="street" name="street"
                                        style="
                                 max-width: 66%; outline: none; border: none; font-size: 16px; border-bottom: 1px solid #333;">
                                </div>
                                <div style="width: 33.33%;">
                                    <label
                                        style="font-size: 12px; line-height: 11px; margin-bottom: 4px; font-weight: 500;"
                                        for="street">State:</label>
                                    <input type="text" id="street" name="street"
                                        style="
                                 max-width: 60%; outline: none; border: none; font-size: 16px; border-bottom: 1px solid #333;">
                                </div>
                                <div style="width:33.33% ; ">
                                    <label
                                        style="font-size: 12px; line-height: 11px; margin-bottom: 4px; font-weight: 500;"
                                        for="street">Zip code:</label>
                                    <input type="text" id="street" name="street"
                                        style="
                                 max-width: 48%; outline: none; border: none; font-size: 16px; border-bottom: 1px solid #333;">
                                </div>
                            </div>
                            <div style="margin-bottom: 10px;">

                                <label style="font-size: 12px; line-height: 11px; margin-bottom: 4px; font-weight: 500;"
                                    for="street">Phone number:</label>
                                <input type="text" id="street" name="street"
                                    style="width: 100%;
                                 max-width: 72%; outline: none; border: none; font-size: 16px; border-bottom: 1px solid #333;">
                            </div>



                        </div>


                    </div>
                    <div style="width: 50%; padding-left: 5px;">
                        <div class="pay-online"
                            style="background-color: #F0F2F5; padding: 8px;display: flex; align-items: center; justify-content: space-between;">
                            <h2 style="margin: 0; text-transform: uppercase; text-transform: uppercase; font-size: 12px;
                        font-weight: 500;">New mailing address:</h2>
                        </div>
                        <div style="border: 1px solid #ccc; padding: 12px 12px 12px 12px; border-top: none;">
                            <div style="margin-bottom: 10px;">

                                <label style="font-size: 12px; line-height: 11px; margin-bottom: 4px; font-weight: 500;"
                                    for="street">Insurance carrier:</label>
                                <input type="text" id="street" name="street"
                                    style="width: 100%;
                                 max-width: 68%; outline: none; border: none; font-size: 16px; border-bottom: 1px solid #333;">
                            </div>
                            <div style="margin-bottom: 10px; display: flex;">
                                <div style="width: 60%;">
                                    <label
                                        style="font-size: 12px; line-height: 11px; margin-bottom: 4px; font-weight: 500;"
                                        for="street">Member id:</label>
                                    <input type="text" id="street" name="street"
                                        style="
                                 max-width: 62%; outline: none; border: none; font-size: 16px; border-bottom: 1px solid #333;">
                                </div>
                                <div style="width: 40%;">
                                    <label
                                        style="font-size: 12px; line-height: 11px; margin-bottom: 4px; font-weight: 500;"
                                        for="street">Group:</label>
                                    <input type="text" id="street" name="street"
                                        style="
                                 max-width: 68%; outline: none; border: none; font-size: 16px; border-bottom: 1px solid #333;">
                                </div>

                            </div>
                            <div style="margin-bottom: 10px;">

                                <label style="font-size: 12px; line-height: 11px; margin-bottom: 4px; font-weight: 500;"
                                    for="street">Claim address:</label>
                                <input type="text" id="street" name="street"
                                    style="width: 100%;
                                 max-width: 72%; outline: none; border: none; font-size: 16px; border-bottom: 1px solid #333;">
                            </div>
                            <div style="margin-bottom: 10px; display: flex;">
                                <div style="width: 33.33%;">
                                    <label
                                        style="font-size: 12px; line-height: 11px; margin-bottom: 4px; font-weight: 500;"
                                        for="street">City:</label>
                                    <input type="text" id="street" name="street"
                                        style="
                                 max-width: 66%; outline: none; border: none; font-size: 16px; border-bottom: 1px solid #333;">
                                </div>
                                <div style="width: 33.33%;">
                                    <label
                                        style="font-size: 12px; line-height: 11px; margin-bottom: 4px; font-weight: 500;"
                                        for="street">State:</label>
                                    <input type="text" id="street" name="street"
                                        style="
                                 max-width: 60%; outline: none; border: none; font-size: 16px; border-bottom: 1px solid #333;">
                                </div>
                                <div style="width:33.33% ; ">
                                    <label
                                        style="font-size: 12px; line-height: 11px; margin-bottom: 4px; font-weight: 500;"
                                        for="street">Zip code:</label>
                                    <input type="text" id="street" name="street"
                                        style="
                                 max-width: 48%; outline: none; border: none; font-size: 16px; border-bottom: 1px solid #333;">
                                </div>
                            </div>
                            <div>

                                <label style="font-size: 12px; line-height: 11px; margin-bottom: 4px; font-weight: 500;"
                                    for="street">Claim phone number:</label>
                                <input type="text" id="street" name="street"
                                    style="width: 100%;
                                 max-width: 61%; outline: none; border: none; font-size: 16px; border-bottom: 1px solid #333;">
                            </div>

                        </div>

                    </div>

                </div>



            </div>


        </div>

    </div>

`;
