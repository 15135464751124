import Loader from "components/Loader/Loader";
import { useEffect, useState } from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { renderingProviderSettingData } from "store/features/companySetting/companySettingSelectors";
import "../../../src/tableStyling.css";
import { t } from "../../stringConstants";
import { draggablePersonalizationLocalStorage } from "../../utils";
import MainTable from "components/Table/MainTable";
import { REDERING_PROVIDER_PERSONALIZE } from "constants/personalization";
import { selectLoginUser } from "store/features/authentication/authenticationSelectors";
import Icon from "../../components/Icon";
import RenderingProviderModal from "./RenderingProviderModal";
import { newUserProviderSelector } from "store/features/providers/providersSelectors";
import { setRenderProviderList } from "store/features/companySetting/companySettingSlice";
import Status from "components/Status";
import { PAGE_LIMIT, TABLE_QUICK_TOOLS } from "constant";
import { showConfirmationModal } from "store/features/general/generalAction";
import { createRenderingProviders } from "store/features/companySetting/companySettingSlice";
import ViewCheckBox from "components/CheckBox/ViewCheckBox";

const RenderingProviderSettings = (props) => {
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [loading, setLoading] = useState(false);
  const newProviderInfo = useSelector(newUserProviderSelector);

  const [selectedItems, setSelctedItems] = useState(newProviderInfo);
  const dispatch = useDispatch();
  const [personalize, setPersonalize] = useState(REDERING_PROVIDER_PERSONALIZE);
  const user = useSelector(selectLoginUser);
  const [openCreateModal, setOpenCreateModal] = useState(false);

  const renderingProviderSettingInfo = useSelector(renderingProviderSettingData);

  const sortUsers = (users, sortParam) => {
    if (sortDescending) {
      return [...users].sort((a, b) => (a[sortParam] < b[sortParam] ? 1 : b[sortParam] < a[sortParam] ? -1 : 0));
    } else {
      return [...users].sort((a, b) => (b[sortParam] < a[sortParam] ? 1 : a[sortParam] < b[sortParam] ? -1 : 0));
    }
  };
  const quickTools = [TABLE_QUICK_TOOLS.edit, TABLE_QUICK_TOOLS.delete];

  useEffect(() => {
    if (renderingProviderSettingInfo.length > 0)
      dispatch(setRenderProviderList(sortUsers(renderingProviderSettingInfo, sortBy)));
  }, [sortBy, sortDescending]);

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  const tdFormat = (item, row) => {
    if (item === "isActive")
      return row?.isActive ? (
        <div className="star-icon-wrapper">
          <Status type="circle" size="md" color="green" tickIcon />
        </div>
      ) : (
        <div className="star-icon-wrapper">
          <Status type="circle" size="md" color="maroon" crossIcon />
        </div>
      );
    else if (item === "isDefault") {
      return row?.isDefault ? <ViewCheckBox id="filter" bgColor="green" /> : "";
    }
    return row[item];
  };

  const renderTd = (item, row) => {
    return (
      <td
        key={item.id}
        className="ellipsis"
        style={{
          textAlign: item.textAlign,
          textOverflow: item.textOverflow,
        }}
      >
        {tdFormat(item.itemKey, row)}
      </td>
    );
  };

  const handleCellClick = (key, row) => {
    switch (key) {
      case TABLE_QUICK_TOOLS.delete:
        const confirmationModalData = {
          showModal: true,
          onConfirm: () => dispatch(createRenderingProviders({ params: row, operationType: "delete" })),
          title: "Delete Rendering Provider",
          message: "Are you sure, you want to delete that Rendering Provider?",
        };
        dispatch(showConfirmationModal(confirmationModalData));
        break;
      case TABLE_QUICK_TOOLS.edit:
        setOpenCreateModal(true);
        setSelctedItems({ ...row, isUpdate: true });
        break;
    }
  };

  return (
    <div style={{ flex: 1 }}>
      {!loading ? (
        <>
          <Row>
            <Col md={12}>
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <div className="d-flex justify-content-between align-items-center px-2 pe-4">
                    <Card.Title
                      as="h4"
                      style={{
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      {t("rederingProviders")}
                    </Card.Title>
                    <i
                      className="fas fa-plus-circle fa-lg cursor-pointer"
                      alt={t("addCli")}
                      title={t("addCli")}
                      onClick={() => {
                        setSelctedItems(newProviderInfo);
                        setOpenCreateModal(true);
                      }}
                    />
                  </div>
                </Card.Header>
                <Card.Body className="table-full-width desktop-noScroll">
                  <MainTable
                    columns={personalize}
                    rows={renderingProviderSettingInfo}
                    flipSort={flipSort}
                    sortBy={sortBy}
                    sortDescending={sortDescending}
                    widthToSkip={120}
                    draggable
                    resizable
                    personalisationKey={"rederingProviders"}
                    handleCellClick={handleCellClick}
                    customColumnCellRenderer={renderTd}
                    handleSaveDragAndResize={(personalizeArr) => setPersonalize(personalizeArr)}
                    tools={quickTools}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {openCreateModal && (
            <RenderingProviderModal
              handleClose={() => setOpenCreateModal(false)}
              user={selectedItems}
              show={openCreateModal}
            />
          )}
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default RenderingProviderSettings;
