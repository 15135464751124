import { downloadDataAsCSV, importPhoneNoFormat } from "utils";

export const LAB_CLIENT_CSV_COLUMNS = [
  "client_payer_id",
  "client_name",
  "client_address",
  "client_city",
  "client_state",
  "client_zip",
  "client_phone",
];

export const ASSOCIATED_CLIENT_CSV_COLUMNS = ["provider_code", "client_code"];


export const convertLabClientObject = (loc, client) => {
  const phone_number = importPhoneNoFormat(loc.client_phone);
  const fax = importPhoneNoFormat(loc.client_fax);
  const contactFax = importPhoneNoFormat(loc.client_main_contact_fax);

  const obj = {
    uuid: uuidv4(),
    clientID: client.id,
    practiceID: loc.client_payer_id || "",
    name: loc.client_name || "",
    street: loc.client_address || "",
    street2: loc.client_address_2 || "",
    city: loc.client_city || "",
    state: loc.client_state || "",
    zip: loc.client_zip || "",
    phone_number: phone_number || null,
    fax: fax || null,
    email: loc.client_email || null,
    contact_name: `${loc.client_main_contact_first_name || ""} ${loc.client_main_contact_last_name || ""}`,
    contact_fax: contactFax || null,
    contact_email: loc.client_main_contact_email || null,
    commission: loc.percent_claims_commission || null,
    discount: loc.percent_discount_list_price || null,
    setting: {
      primaryBillType: loc.primary_invoice_method || "email",
      ...(loc.secondary_invoice_method && { secondaryBillType: loc.secondary_invoice_method }),
    },
  };

  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, typeof value === "string" ? value.trim() : value])
  );
};


export const onHandleDownloadSample = async (type = 2) => {
    // if template info
    if (type === 1) {
        const data = [
            { "Field Name": "client_payer_id", "STATUS": "( required )", "VALUE": "221" },
            { "Field Name": "client_name", "STATUS": "( required )", "VALUE": "Client 1" },
            { "Field Name": "client_address", "STATUS": "( required )", "VALUE": "7365 Douglas Drives" },
            { "Field Name": "client_address_2", "STATUS": "( optional )", "VALUE": "" },
            { "Field Name": "client_city", "STATUS": "( required )", "VALUE": "Bechtelarland" },
            { "Field Name": "client_state", "STATUS": "( required )", "VALUE": "UT" },
            { "Field Name": "client_zip", "STATUS": "( required )", "VALUE": "10013" },
            { "Field Name": "client_phone", "STATUS": "( required )", "VALUE": "561-555-7689" },
            { "Field Name": "client_email", "STATUS": "( optional )", "VALUE": "" },
            { "Field Name": "client_main_contact_first_name", "STATUS": "( optional )", "VALUE": "" },
            { "Field Name": "client_main_contact_last_name", "STATUS": "( optional )", "VALUE": "" },
            { "Field Name": "client_main_contact_email", "STATUS": "( optional )", "VALUE": "" },
            { "Field Name": "percent_claims_commission", "STATUS": "( optional )", "VALUE": "" },
            { "Field Name": "percent_discount_list_price", "STATUS": "( optional )", "VALUE": "" },
            { "Field Name": "primary_invoice_method", "STATUS": "( optional )", "VALUE": "email" },
            { "Field Name": "secondary_invoice_method", "STATUS": "( optional )", "VALUE": "" }
        ];
  
      await downloadDataAsCSV(data, "Import Client Template Info");
    } else {
      const data = [
          {
              "client_payer_id": "payer_id_1",
              "client_name": "Acme Corporation",
              "client_address": "123 Main St",
              "client_address_2": "",
              "client_city": "Springfield",
              "client_state": "IL",
              "client_zip": "62701",
              "phone_number": "555-1234",
              "fax": "555-5678",
              "email": "contact@acme.com",
              "client_main_contact_first_name": "John",
              "client_main_contact_last_name": "Doe",
              "contact_fax": "",
              "contact_email": "john.doe@acme.com",
              "percent_claims_commission": 10,
              "percent_discount_list_price": 5,
              "primary_invoice_method": "email",
              "secondary_invoice_method": ""
          },
          {
              "client_payer_id": "payer_id_2",
              "client_name": "Global Tech",
              "client_address": "456 Elm St",
              "client_address_2": "Apt 5",
              "client_city": "Metropolis",
              "client_state": "NY",
              "client_zip": "10001",
              "phone_number": "555-9876",
              "fax": "555-4321",
              "email": "info@globaltech.com",
              "client_main_contact_first_name": "Jane",
              "client_main_contact_last_name": "Smith",
              "contact_fax": "",
              "contact_email": "jane.smith@globaltech.com",
              "percent_claims_commission": 15,
              "percent_discount_list_price": 7,
              "primary_invoice_method": "email",
              "secondary_invoice_method": "phone"
          },
          {
              "client_payer_id": "payer_id_3",
              "client_name": "Example LLC",
              "client_address": "789 Oak St",
              "client_address_2": "",
              "client_city": "Gotham",
              "client_state": "CA",
              "client_zip": "90210",
              "phone_number": "555-2468",
              "fax": "",
              "email": "support@example.com",
              "client_main_contact_first_name": "Bob",
              "client_main_contact_last_name": "Johnson",
              "contact_fax": "",
              "contact_email": "bob.johnson@example.com",
              "percent_claims_commission": 20,
              "percent_discount_list_price": 10,
              "primary_invoice_method": "email",
              "secondary_invoice_method": ""
          }
      ];
  
      await downloadDataAsCSV(data, "Import Client Sample Info");
    }
  };
  