import API from "api";
import logo from "assets/img/logo.jpg";
import { Auth } from "aws-amplify";
import Loader from "components/Loader/Loader";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import MFPagination from "components/Pagination/MFPagination";
import LabClientModal from "./LabClientModal";
import { PAGE_LIMIT, TEST_ORDER_LIMIT_CATEGORY_VALUE, newLabClientObject, IMPORT_TYPE } from "constant";
import { AppContext } from "context/app-context";
import { useContext, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { QRCode } from "react-qrcode-logo";
import {
  checkValidity,
  emailMsgToCreatedFacility,
  getPreRegistrationLink,
  sortingFilterInLC,
  parseInvoiceData,
  downloadDataAsCSV,
  formatNumber,
  getValidReg,
  draggablePersonalizationLocalStorage,
  tdLink,
  formatCurrency,
} from "utils";
import { ErrorBoundary } from "react-error-boundary";
import OrderLogsModal from "components/Modal/Order/OrderLogsModal";

import { customIsEmpty } from "util/customLodash";

import Filter from "../../components/Filter";
import { t } from "../../stringConstants";
import ImportErrorModal from "components/Modal/ImportErrorModal";
import LocationImportModal from "components/Modal/LocationImportModal";
import PersonalizationModal from "components/Modal/PersonalizationModal";
import MainTable from "components/Table/MainTable";
import { MESSAGE_MODES, TABLE_QUICK_TOOLS, LAB_CLIENT_DROPDOWN_OPTIONS } from "constant";
import { LAB_CLIENT_PERSONALIZE } from "constants/personalization";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "store/features/general/generalAction";

import Icon from "../../components/Icon";
import { selectLoginUser } from "store/features/authentication/authenticationSelectors";
import { fetchAllLocations, createLabClient, updateLabClient } from "store/features/locations/locationsSlice";
import { locationsSelectors, selectedLocation } from "store/features/locations/locationsSelectors";
import { userCompanyID } from "utils";
import NotesModal from "components/Modal/NotesModal";
import ClientProviderModal from "components/Modal/ClientProviderModal";
import { providerList } from "store/features/providers/providersSelectors";
import moment from "moment";

import RedirectLink from "components/RedirectLink/RedirectLink";
import {
  onHandleDownloadSample,
  convertLabClientObject,
  LAB_CLIENT_CSV_COLUMNS,
  ASSOCIATED_CLIENT_CSV_COLUMNS,
} from "views/LabClient/LabClientConstant";
import api, { getCompanySetting } from "api";
import { setSelectedInvoiceClient } from "../../store/features/invoicePreview/invoicePreviewSlice";
import { claimFilterSelector } from "store/features/claimInvoice/claimInvoiceSelectors";
import { setInvoicesFilter } from "store/features/claimInvoice/claimInvoiceSlice";
import {
  setClaimsIDs,
  setOpenPaymentProcess,
  setShowFromClientSetting,
  setShowFromBarCode,
  setLastRemoteClaimId,
} from "store/features/processPayment/processPaymentSlice";
import { setLoader, showMessage } from "store/features/general/generalSlice";
import ErrorMessageModal from "components/Modal/ErrorMessageModal";

const LabClient = ({ onSendData }) => {
  const appContext = useContext(AppContext);
  const [locations, setLocations] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [selectItem, setSelectItem] = useState(newLabClientObject);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [triggerFilter, setTriggerFilter] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [showCopyText, setShowCopyText] = useState("");
  const [personalize, setPersonalize] = useState([]);
  const [openPersonalizationModal, setOpenPersonalizationModal] = useState(false);
  const [orders, setOrders] = useState([]);
  const [openImportModal, setOpenImportModal] = useState(null);
  const [openErrModal, setOpenErrModal] = useState(false);
  const [title, setTitle] = useState("");
  const [errorData, setErrorData] = useState([]);
  const [successData, setsuccessData] = useState([]);
  const [checkboxes, setCheckboxes] = useState([]);
  const [noteModal, setNoteModal] = useState(null);
  const [providerModal, setProviderModal] = useState(null);
  const dispatch = useDispatch();
  const quickTools = [TABLE_QUICK_TOOLS.checkbox, TABLE_QUICK_TOOLS.edit, TABLE_QUICK_TOOLS.notes, "provider"];
  const user = useSelector(selectLoginUser);
  const labLocations = useSelector(locationsSelectors);
  const PROVIDER_OPTIONS = useSelector(providerList);

  const [openOrderDetail, setOpenOrderDetail] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState("");

  // console.log(labLocations)

  const { locations: clientLocations } = useSelector(selectedLocation);
  const invoiceFilter = useSelector(claimFilterSelector);

  const searchTerms = ["Name", "Contact Name", "Contact Email", "Contact Phone", "practiceID"];

  const currentItems = (locs) => {
    if (!locs || locs.length === 0) return [];
    const indexOfLastUser = currentPage * PAGE_LIMIT;
    const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
    return locs.slice(indexOfFirstUser, indexOfLastUser);
  };

  let locationsToMap = currentItems(filteredLocations);

  const sortData = (schs, sortParam) => {
    if (sortDescending) {
      return [...schs].sort((a, b) => (a[sortParam] < b[sortParam] ? 1 : b[sortParam] < a[sortParam] ? -1 : 0));
    } else {
      return [...schs].sort((a, b) => (b[sortParam] < a[sortParam] ? 1 : a[sortParam] < b[sortParam] ? -1 : 0));
    }
  };

  useEffect(() => {
    const sortLS = sortingFilterInLC.get();
    if (sortLS.facilities && sortLS.facilities.sortBy) {
      setSortBy(sortLS.facilities.sortBy);
      setSortDescending(sortLS.facilities.sortDescending);
    }
    // if (sortLS.facilities && sortLS.facilities.filter) {
    //   setFilter(sortLS.facilities.filter);
    // }
  }, [appContext?.company]);

  useEffect(() => {
    setPersonalize(draggablePersonalizationLocalStorage.get(user, "labClient", LAB_CLIENT_PERSONALIZE));
  }, []);

  const getPageNumbers = (users) => {
    const pageNumbers = users.length > PAGE_LIMIT ? Math.ceil(users.length / PAGE_LIMIT) : 1;
    return pageNumbers;
  };

  let pageNumbers = getPageNumbers(locations);

  if (filteredLocations && filteredLocations.length > 0) {
    pageNumbers = getPageNumbers(filteredLocations);
  }

  useEffect(() => {
    if (labLocations && labLocations.length > 0) {
      formatFacility();
    }
    // setLocations(
    //   appContext.locations?.filter((f) => f.id !== CONFIG.generalFacility)
    // );
    // setFilteredLocations(
    //   appContext.locations?.filter((f) => f.id !== CONFIG.generalFacility)
    // );
  }, [labLocations, orders]);

  const getAssociateClient = (id) => {
    let res = PROVIDER_OPTIONS.filter((provider) => {
      return provider.associatedClient === id;
    });
    return res.length;
  };

  const formatFacility = () => {
    let facilities = labLocations.map((item) => ({ ...item, associatedClients: getAssociateClient(item.id) }));

    setLocations(facilities);
    setFilteredLocations(sortData(nestedFilter(facilities, filter), sortBy));
  };

  const handleSaveFilter = () => {
    const pervSortLS = sortingFilterInLC.get();
    pervSortLS.facilities = { sortBy, sortDescending, filter };
    sortingFilterInLC.save(pervSortLS);
  };

  useEffect(() => {
    if (locations.length > 0) {
      setFilteredLocations(sortData(nestedFilter(locations, filter), sortBy));
      setCurrentPage(1);
    }
  }, [filter]);

  useEffect(() => {
    if (filteredLocations.length > 0) {
      handleSaveFilter();
      setFilteredLocations(sortData(filteredLocations, sortBy));
    }
  }, [sortBy, sortDescending]);

  const handleConfirmDelete = async (isConfirm) => {
    if (!isConfirm) {
      setItemToDelete(null);
      return;
    }
    try {
      setLoading(true);
      const isDeleted = await API.deleteLocation(itemToDelete.id);
      if (isDeleted) {
        // await API.deleteUser(itemToDelete.phone_number);
        appContext.resetLocations();
        dispatch(setMessage("Facility/Agent deleted successfully", MESSAGE_MODES.success));
      } else {
        dispatch(
          setMessage("Facility/Agent can't be deleted becuase few members are linked with it", MESSAGE_MODES.success)
        );
      }
      setItemToDelete(null);
    } catch (error) {
      dispatch(setMessage(error.message, MESSAGE_MODES.error));
    }
    setLoading(false);
  };

  const OnHandleLocation = async (loc) => {
    const findVal = locations.find((f) => loc.id !== f.id && checkValidity(f.name) == checkValidity(loc.name));
    if (findVal) {
      dispatch(setMessage("Client already exist", MESSAGE_MODES.error));
      return;
    }
    setOpenCreateModal(false);

    try {
      setLoading(true);
      if (loc.isNew) {
        // const res = await API.newLocations(loc, appContext.user);
        const res = await dispatch(createLabClient(loc));
        if (res) {
          // const userCreated = await createUser({ ...res, ...loc });
          // if (userCreated) {

          // } else {
          //   await API.deleteLocation(res.id);
          // }
          dispatch(setMessage("Client Created Successfully", MESSAGE_MODES.success));
        }
      } else {
        // await API.updateLocation(loc);

        await dispatch(updateLabClient(loc));

        await dispatch(fetchAllLocations());
        dispatch(setMessage("Client Updated Successfully", MESSAGE_MODES.success));
        appContext.resetEmployees();
      }
      appContext.resetLocations();
      setSelectItem(newLabClientObject);
      setLoading(false);
    } catch (err) {
      console.log("Error", err);
      dispatch(setMessage(err.message, MESSAGE_MODES.error));
    }
  };

  const createUser = async (newUser) => {
    const firstName = newUser.contact_name.split(" ")[0] || "";
    const lastName = newUser.contact_name.split(" ")[1] || "";
    const attributePayload = {
      preferred_username: newUser.phone_number,
      email: newUser.contact_email,
      phone_number: newUser.phone_number,
      "custom:role": "Employers",
      "custom:autoConfirm": "true",
      "custom:firstName": `${firstName}`,
      "custom:lastName": `${lastName}`,
      "custom:note": `${newUser.contact_name}`,
      "custom:labID": newUser.id,
      "custom:personalisation": JSON.stringify({
        personalize: "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17",
      }),
    };

    try {
      const signUp = await Auth.signUp({
        username: newUser.phone_number,
        password: newUser.password,
        attributes: attributePayload,
      });
      if (signUp) {
        const message = emailMsgToCreatedFacility(
          newUser.contact_name,
          newUser.phone_number.replaceAll(/[^0-9]/gi, ""),
          newUser.password
        );
        await API.sendEmail([
          {
            email: newUser.contact_email,
            subject: "SafeCamp LTC Team",
            msg: message,
          },
        ]);
      }
      return true;
    } catch (error) {
      console.log("ERROR createUser: ", error);
      dispatch(setMessage(error.message, MESSAGE_MODES.error));
      return false;
    }
  };

  const handleEdit = (item) => {
    const client = appContext.getClientById(item.clientID);
    setSelectItem({ ...item, isNew: false, client });
    setOpenCreateModal(true);
  };

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  const downloadQRCode = (site) => {
    const canvas = document.getElementById("qr-gen");
    const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${site.name}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  const handleCopyPRLink = (id) => {
    navigator.clipboard.writeText(getPreRegistrationLink(id));
    setShowCopyText(id);
    setTimeout(() => setShowCopyText(null), 2000);
  };

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filters).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    const models = targetArray.filter((obj) => {
      return filterKeys.every((key) => {
        if (!filters[key].length) {
          return true;
        }

        return obj[key] && obj[key].toLowerCase().includes(filters[key]);
      });
    });
    return models;
  };

  const handleChangePage = (number) => {
    setCurrentPage(number);
  };

  const handlePersonalization = async (data) => {
    setPersonalize([...data]);
    setOpenPersonalizationModal(false);
    const personalisationData = draggablePersonalizationLocalStorage.saveAs(data, "labClient");
    draggablePersonalizationLocalStorage.save(JSON.stringify(personalisationData));
    await API.udpatePersonalization(user.phone_number, personalisationData);
  };

  const parseClient = (item) => {
    if (item.client) item.client.name;
    if (!item.clientID) return "N/A";

    if (appContext.companies) {
      const client = appContext.companies.find((c) => c.id === item.clientID);
      if (client) {
        return client.name;
      }
    }
    return "N/A";
  };

  const handleImport = async (locData, clientID, importType) => {
    if (locData.length === 0) {
      dispatch(setMessage(t("emptyFile"), MESSAGE_MODES.error));
      setOpenImportModal(null);
      return;
    }
    if (!clientID) {
      dispatch(setMessage("Client was not selected", MESSAGE_MODES.error));
      return;
    }
    if (importType === IMPORT_TYPE.labClient) {
      handleClientImport({ locData, clientID, importType });
    } else {
      handleAssociatedClientImport({ locData, clientID });
    }
  };

  const convertIntoKey = (items, key) => {
    return items
      .filter((item) => item[key])
      .reduce((newObj, obj) => {
        newObj[obj[key]] = obj;
        return newObj;
      }, {});
  };

  const parseCode = (input) => {
    if (!input) return "";

    const regex = /\(\s*(\d+)\s*\)/;

    // Use regex to match against the input
    const match = input.match(regex);

    // Check if there was a match and extract it
    const number = match ? match[1].trim() : input.trim();

    // Try to parse the number, and check if it is a valid integer
    const parsedNumber = parseInt(number);
    return isNaN(parsedNumber) ? "" : parsedNumber;
  };

  const handleAssociatedClientImport = async ({ locData, clientID }) => {
    const client = appContext.getClientById(clientID);

    const arr = [];
    const arrdata = [];
    const locList = [...locations];
    const locationKeysArr = Object.keys(locData[0].data);
    if (locationKeysArr.length > 0) {
      let err = false;
      ASSOCIATED_CLIENT_CSV_COLUMNS.forEach((f, i) => {
        if (!locationKeysArr.includes(f)) {
          dispatch(setMessage(`Column Header must be ${f}`, MESSAGE_MODES.error));
          setOpenImportModal(null);
          err = true;
          return;
        }
      });
      if (err) return;
    }

    setOpenImportModal(null);
    setLoading(true);
    const ttlLength = locData.length;

    const clientData = convertIntoKey(labLocations, "practiceID");
    const providerData = convertIntoKey(PROVIDER_OPTIONS, "code");

    for (let index = 0; index < ttlLength; index++) {
      const { data } = locData[index];

      if (Object.values(data).every((c) => c.length == 0)) continue;

      const clientObj = clientData[parseCode(data.client_code)];
      const provObj = providerData[parseCode(data.provider_code)];

      if (!clientObj) {
        arr.push({
          message: `Client record not exists of row ${index + 2}`,
          data: data,
        });
        continue;
      }
      if (!provObj) {
        arr.push({
          message: `Provider record not exists of row ${index + 2}`,
          data: data,
        });
        continue;
      }

      const loc = {
        clientID: userCompanyID.get(),
        requestedBy: user.sub,
        requestedByName: user.name,
        providerID: provObj.id,
        labClientID: clientObj.id,
        ...data,
      };

      arrdata.push(loc);
      locList.push(loc);
    }

    setOpenImportModal(null);
    if (arrdata.length == 0 && arr.length === 0) {
      dispatch(setMessage(t("emptyFile"), MESSAGE_MODES.error));
      setLoading(false);
      return;
    }

    if (arr.length === 0) {
      try {
        const fileName = `provider/associated_client_import${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}.csv`;
        await API.saveFileOnBucket(arrdata, fileName);
      } catch (ex) {
        console.log("Ex", ex);
        dispatch(setMessage(t("failtoimportdata"), MESSAGE_MODES.error));
        setLoading(false);
        return;
      }
    }
    setLoading(false);
    if (arr.length > 0 || arrdata.length > 0) {
      setErrorData(arr);
      setTitle("Client Associated Provider");
      setsuccessData(arrdata);
      setOpenErrModal(true);
    }
  };

  const handleClientImport = async ({ locData, clientID, importType }) => {
    const client = appContext.getClientById(clientID);
    const arr = [];
    const arrdata = [];
    const locList = [...locations];
    const locationKeysArr = Object.keys(locData[0].data);
    if (locationKeysArr.length > 0) {
      let err = false;
      LAB_CLIENT_CSV_COLUMNS.forEach((f, i) => {
        if (!locationKeysArr.includes(f)) {
          dispatch(setMessage(`Column Header must be ${f}`, MESSAGE_MODES.error));
          setOpenImportModal(null);
          err = true;
          return;
        }
      });
      if (err) return;
    }

    setOpenImportModal(null);
    setLoading(true);
    const ttlLength = locData.length;

    for (let index = 0; index < ttlLength; index++) {
      const { data } = locData[index];

      if (Object.values(data).every((c) => c.length == 0)) continue;

      const loc = convertLabClientObject(data, client);

      if (loc.name) {
        loc.name = getValidReg(loc.name);
        const findVal = locList.find((f) => {
          const isNameExists = false; // checkValidity(f.name) == checkValidity(loc.name);
          const isPracticeID = f.practiceID === loc.practiceID;
          return isNameExists || isPracticeID;
        });

        if (findVal) {
          arr.push({
            message: `Client name record already exists on row ${index + 2}`,
            data: loc,
          });
          continue;
        }
      } else {
        arr.push({
          message: `name is required on row ${index + 2}`,
          data: loc,
        });
        continue;
      }

      // if (!loc.phone_number) {
      //   arr.push({
      //     message: `phone number is required on row ${index + 2}.`,
      //     data: loc,
      //   });
      //   continue;
      // }
      if (loc.phone_number) {
        const phone = formatPhoneNumberIntl(loc.phone_number);

        const phoneArr = phone.split(" ");
        loc.countryCode = phoneArr[0];
      }

      // if (loc.phone_number && !validatePhone(loc.phone_number)) {
      //   arr.push({
      //     message: `phone number is invalid ${loc.phone_number} on row ${index + 2}`,
      //     data: loc,
      //   });
      //   continue;
      // }

      arrdata.push(loc);
      locList.push(loc);
    }

    setOpenImportModal(null);
    if (arrdata.length == 0 && arr.length === 0) {
      dispatch(setMessage(t("emptyFile"), MESSAGE_MODES.error));
      setLoading(false);
      return;
    }
    let sucessfullyProcessed = [];
    try {
      const res = await API.importLabClient({ clients: arrdata });
      // const res = [];
      arrdata.forEach((item) => {
        if (!res[item.uuid].status) {
          arr.push({
            message: `Fail to save the record`,
            data: item,
          });
        } else {
          sucessfullyProcessed.push(item);
        }
      });
    } catch (ex) {
      console.log("Ex", ex);
      dispatch(setMessage(t("failtoimportdata"), MESSAGE_MODES.error));
    }
    setLoading(false);
    if (arr.length > 0 || sucessfullyProcessed.length > 0) {
      setErrorData(arr);
      setTitle("Client");
      setsuccessData(sucessfullyProcessed);
      setOpenErrModal(true);
    }
  };

  const handleDownloadSample = async () => {
    await downloadDataAsCSV(
      // departments.map((d) => {
      //   return {
      //     name: d.name,
      //     callTime: formatCallTime(d.callTime),
      //     deptTimeZone: formatTimeZone(d.deptTimeZone),
      //     reminderOne: d.reminderOne,
      //     reminderTwo: d.reminderTwo,
      //   };
      // }),
      [
        {
          name: "",
          street: "",
          street2: "",
          city: "",
          state: "",
          zip: "",
          phoneNumber: "",
          webSite: "",
          contact_first_name: "",
          contact_last_name: "",
          contact_email: "",
          main_phone_number: "",
        },
      ],
      t("locCsvSampleName")
    );
  };

  const tdPreRegisteration = (user) => {
    return (
      <td className="ellipsis position-relative" style={{ textAlign: "center" }}>
        {user.preRegistration && (
          <div className="d-flex align-items-center justify-content-evenly qr-code-download">
            {showCopyText && showCopyText === user.id && (
              <div className="copy-link-text clipBoard-copy">Copied to clipboard!</div>
            )}
            <div onClick={() => handleCopyPRLink(user.id)}>
              <i className="fas fa-copy maroon" />
            </div>
            <div onClick={() => downloadQRCode(user)}>
              <i className="fas fa-download maroon"></i>
            </div>
            <span style={{ display: "none" }}>
              <QRCode
                id="qr-gen"
                ecLevel={"H"}
                value={getPreRegistrationLink(user.id)}
                enableCORS={true}
                logoImage={logo}
                size="250"
                logoHeight={"70"}
                logoWidth={"70"}
                fgColor={"#000"}
                bgColor={"#fff"}
              />
            </span>
          </div>
        )}
      </td>
    );
  };

  const tdFormat = (item, user) => {
    if (item === "testOrderCategory") return TEST_ORDER_LIMIT_CATEGORY_VALUE[user.testOrderCategory];
    if (item === "practiceID") {
      return (
        <RedirectLink
          pathname="/admin/employeeclaim"
          state={{
            name: user.id,
            term: "locationID",
          }}
        >
          {user.practiceID}
        </RedirectLink>
      );
    }
    if (item === "name") {
      return (
        <RedirectLink
          pathname="/admin/employeeclaim"
          state={{
            name: user.id,
            term: "locationID",
          }}
        >
          {user.name}
        </RedirectLink>
      );
    }

    if (item === "paid") {
      return (
        <RedirectLink
          pathname="/admin/employeeclaim"
          state={{
            name: user.id,
            term: "locationID",
            filterTerm: "status",
            filterName: "paid",
          }}
        >
          {formatCurrency(user.paid)}
        </RedirectLink>
      );
    }
    if (item === "totalBilledAmount") {
      return (
        <RedirectLink
          pathname="/admin/employeeclaim"
          state={{
            name: user.id,
            term: "locationID",
            filterTerm: "status",
            filterName: "billed",
          }}
        >
          {formatCurrency(user.totalBilledAmount)}
        </RedirectLink>
      );
    }

    if (item === "claimAmount") {
      return (
        <RedirectLink
          pathname="/admin/employeeclaim"
          state={{
            name: user.id,
            term: "locationID",
            filterTerm: "status",
            filterName: "open",
          }}
        >
          {formatCurrency(user.claimAmount)}
        </RedirectLink>
      );
    }

    return user[item];
  };

  const renderTd = (item, user) => {
    if (item.itemKey === "monthToDate") return tdLink(user.monthToDate, "month", user.id, "siteID");
    if (item.itemKey === "yearToDate") return tdLink(user.yearToDate, "year", user.id, "siteID");
    if (item.itemKey === "totalOrders") return tdLink(user.totalOrders, "", user.id, "siteID");
    if (item.itemKey === "preRegisterationLink") return tdPreRegisteration(user);
    return (
      <td
        className="ellipsis"
        style={{
          textAlign: item.textAlign,
          textOverflow: item.textOverflow,
        }}
        title={tdFormat(item.itemKey, user)}
      >
        {tdFormat(item.itemKey, user)}
      </td>
    );
  };
  const handleCheckboxChange = (e, row) => {
    const filteredList = checkboxes.filter((c) => c !== row.id);
    if (e.target.checked) {
      filteredList.push(row.id);
    }
    setCheckboxes(filteredList);
  };

  const handleCellClick = (key, row, event) => {
    switch (key) {
      case TABLE_QUICK_TOOLS.edit:
        if (row.id !== "596daf9b-8f9d-451c-a28f-a458bac57ce4" && row.id !== "a081f285-4949-45bd-9516-ba2ff3e8d0b1") {
          handleEdit(row);
        }
        break;
      case TABLE_QUICK_TOOLS.checkbox:
        handleCheckboxChange(event, row);
        break;
      case TABLE_QUICK_TOOLS.notes:
        setNoteModal(row);
        break;
      case "associatedClients":
        if (row["associatedClients"]) setProviderModal(row);
        break;
      default:
        break;
    }
  };

  const generateInvoiceForClient = async (row) => {
    try {
      const payload = {
        id: row.id,
        type: "client",
      };

      const { claim, records } = await api.getInvoiceDetails(payload);

      const setting = await getCompanySetting();

      if (!customIsEmpty(records)) {
        const selectedUsersClaims = parseInvoiceData([claim], user, clientLocations, setting);
        const selectedInvoice = { ...selectedUsersClaims[0], details: records };

        dispatch(setSelectedInvoiceClient({ selectedInvoice, isBulk: false }));
        return;
      }
      appContext.showErrorMessage("This client has not any invoice yet.");
    } catch (error) {
      console.log("Error", error);
    }
  };

  const sendInvoice = (row) => {
    dispatch(setInvoicesFilter({ ...invoiceFilter, companyID: row.id }));
    onSendData(row);
  };

  const processPayment = async (row) => {
    try {
      dispatch(setLoader(true));
      const findClaim = await api.getInvoiceClaims({
        id: row.id,
        type: "client",
      });

      if (findClaim && findClaim.length > 0) {
        // const ids = findClaim?.map((i) => i.id);
        dispatch(setClaimsIDs(findClaim));
        dispatch(setOpenPaymentProcess(true));
        dispatch(setShowFromClientSetting(true));
        dispatch(setShowFromBarCode(false));

        dispatch(setLastRemoteClaimId(findClaim[findClaim.length - 1].remote_claimid));
      }
      dispatch(setLoader(false));
    } catch (error) {
      console.log("Error:", error);
      dispatch(setLoader(false));
    }
  };

  const archiveUser = async (row) => {
    try {
      const updateClientObj = { ...row, isActive: false };

      await dispatch(updateLabClient(updateClientObj));
      await dispatch(fetchAllLocations());
      dispatch(setMessage("Client Updated Successfully", MESSAGE_MODES.success));
    } catch (error) {
      console.log("error", error);
    }
  };

  const ErrorFallback = ({ error, resetErrorBoundary }) => {
    // Call resetErrorBoundary() to reset the error boundary and retry the render
    return (
      <ErrorMessageModal
        error={error.message}
        handleClose={() => {
          dispatch(setClaimReSubmissionModal(null));
          setOpenOrderDetail(false);
        }}
      />
    );
  };

  return (
    <div style={{ flex: 1 }}>
      <Row>
        <Col md="12">
          {!loading ? (
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  {t("labClient")} ({filteredLocations ? formatNumber(filteredLocations.length) : 0})
                </Card.Title>
                <div className="buttonHeader">
                  <Icon
                    handleClick={() => setShowFilter(!showFilter)}
                    title={"Filter"}
                    label={"Filter"}
                    iconType={"filter"}
                  />

                  {/* <Icon
                    handleClick={handleDownloadSample}
                    title={"Download Csv Sample"}
                    iconType="downloadIcon"
                    label="Download"
                  /> */}

                  <Icon
                    handleClick={() => setOpenPersonalizationModal(true)}
                    title={t("personalize")}
                    iconType="personalizeIcon"
                    label={t("personalize")}
                  />
                  <Icon
                    handleClick={() => {
                      const obj = { ...newLabClientObject };

                      setSelectItem({ ...obj, clientID: userCompanyID.get() });

                      setOpenCreateModal(true);
                    }}
                    label={"Create"}
                    title={"Create Facility"}
                    iconType={"addUserIcon"}
                  />
                  <Icon
                    handleClick={() => {
                      setOpenImportModal(IMPORT_TYPE.labClient);
                    }}
                    title={"Import Client"}
                    iconType="importIcon"
                    label="Load Clients"
                  />
                  <Icon
                    handleClick={() => {
                      setOpenImportModal(IMPORT_TYPE.associatedClient);
                    }}
                    title={"Import Associated Provider"}
                    iconType="importIcon"
                    label="Load Assoc. Providers"
                  />
                </div>
                {showFilter && (
                  <Filter
                    filterTerms={searchTerms}
                    setFilter={setFilter}
                    filter={filter}
                    triggerFilter={triggerFilter}
                    setTriggerFilter={setTriggerFilter}
                  />
                )}
                <MFPagination currentPage={currentPage} handleChangePage={handleChangePage} totalPages={pageNumbers} />
              </Card.Header>
              <Card.Body className="table-full-width desktop-noScroll">
                <MainTable
                  columns={personalize}
                  rows={locationsToMap}
                  flipSort={flipSort}
                  sortBy={sortBy}
                  sortDescending={sortDescending}
                  widthToSkip={160}
                  draggable
                  resizable
                  personalisationKey={"labClient"}
                  handleSaveDragAndResize={(personalizeArr) => setPersonalize(personalizeArr)}
                  selectedRows={checkboxes}
                  tools={quickTools}
                  dropDownOptions={LAB_CLIENT_DROPDOWN_OPTIONS}
                  handleDropDownClick={(type, row) => {
                    // if (type === "delete") {
                    //   if (
                    //     row.id !== "596daf9b-8f9d-451c-a28f-a458bac57ce4" &&
                    //     row.id !== "a081f285-4949-45bd-9516-ba2ff3e8d0b1"
                    //   ) {
                    //     setItemToDelete(row);
                    //   }
                    // }
                    if (type === "generateInvoice") {
                      generateInvoiceForClient(row);
                    }
                    if (type === "sendInvoice") {
                      sendInvoice(row);
                    }
                    if (type === "processPayment") {
                      processPayment(row);
                    }
                    if (type === "delete") {
                      archiveUser(row);
                    }
                    if (type === "logs") {
                      setOpenOrderDetail(true);
                      setSelectedOrder({ ...row, logType: "settingLogs", title: "Client Logs" });
                    }
                  }}
                  handleCellClick={handleCellClick}
                  customColumnCellRenderer={renderTd}
                />
              </Card.Body>
            </Card>
          ) : (
            <Loader />
          )}

          {openOrderDetail && (
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <OrderLogsModal
                order={selectedOrder}
                show={openOrderDetail}
                user={user}
                handleClose={() => {
                  setOpenOrderDetail(false);
                  setSelectedOrder("");
                }}
                claim
              />
            </ErrorBoundary>
          )}

          {itemToDelete && (
            <ConfirmationModal
              show={itemToDelete ? true : false}
              title="Delete Facility/Agent"
              message="Are you sure, you want to remove Facility/Agent?"
              handleConfirm={handleConfirmDelete}
            />
          )}

          {noteModal && (
            <NotesModal
              handleClose={(obj) => {
                setNoteModal(null);
              }}
              labClient={true}
              user={user}
              data={noteModal}
            />
          )}
          {providerModal && (
            <ClientProviderModal
              handleClose={(obj) => {
                setProviderModal(null);
              }}
              user={user}
              data={providerModal}
            />
          )}
          {openCreateModal && (
            <LabClientModal
              handleClose={() => {
                setSelectItem(newLabClientObject), setOpenCreateModal(false);
              }}
              selectItem={selectItem}
              handleSave={OnHandleLocation}
              appContext={appContext}
            />
          )}

          {openPersonalizationModal && (
            <PersonalizationModal
              data={JSON.stringify(personalize)}
              handleChange={handlePersonalization}
              show={openPersonalizationModal}
              handleClose={() => setOpenPersonalizationModal(false)}
            />
          )}
          {openImportModal && (
            <LocationImportModal
              handleClose={() => setOpenImportModal(null)}
              handleImport={handleImport}
              appContext={appContext}
              importType={openImportModal}
              handleDownloadSample={onHandleDownloadSample}
            />
          )}

          {openErrModal && (
            <ImportErrorModal
              location
              title={title}
              errData={errorData}
              successData={successData}
              handleClose={async () => {
                setOpenErrModal(false);
                setErrorData([]);
                setsuccessData([]);
                setTitle("");
                await appContext.resetLocations();
              }}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default LabClient;
