import { useCallback, useEffect, useState } from "react";
// import logoImage from "../../assets/img/safecamp_BRAND.1-nobg.png";
import API from "api";
import Loader from "components/Loader/Loader";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import ShowAlertMessage from "components/ShowAlertMessage";
import { ADMIN_USERS, CONFIG, MESSAGE_MODES, PERSONALIZATION } from "constant";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getAllCompanies, getCurrentUser, signInUser } from "store/features/authentication/authenticationActions";
import { authSelector, isAuthorizedUserSelector } from "store/features/authentication/authenticationSelectors";
import { messageSelector } from "store/features/general/generalSelectors";
import { showMessage } from "store/features/general/generalSlice";
import { loggedInUser, medFlowInLocalStorage, personalizationLocalStorage, userCompanyID, getUserName } from "utils";
import logoImage from "../../assets/img/login_logo.png";
import { Auth } from "aws-amplify";
import { setIsAuthorized } from "store/features/authentication/authenticationSlice";
import { setLoading } from "store/features/authentication/authenticationSlice";
import viewIcon from "../../assets/img/eye-open-icon.png";
import hideIcon from "../../assets/img/eye-not-open-icon.png";

const Login = () => {
  const [phone, setPhone] = useState("+1");
  const [password, setPassword] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const messageObj = useSelector(messageSelector);

  const { companies, loading, error, data } = useSelector(authSelector);
  const isAuthorized = useSelector(isAuthorizedUserSelector);

  let history = useHistory();
  let url = window.location.hostname;

  useEffect(() => {
    dispatch(getCurrentUser());
    dispatch(getAllCompanies());
  }, [dispatch]);

  useEffect(() => {
    addLocalStorageData(data);
  }, [data]);

  useEffect(() => {
    if (error) {
      setPhoneError(error);
      setDisableBtn(false);
    }
  }, [error]);

  const addLocalStorageData = useCallback(
    (data) => {
      if (data && data?.user?.attributes) {
        const { response, user } = data;
        if (!response) {
          setPhoneError("User Does not Exist");
          setDisableBtn(false);
          return;
        }

        let labID = null;

        if (response.clientID) {
          labID = response.clientID;
        }

        if (labID) {
          userCompanyID.save(labID);
        }
        medFlowInLocalStorage.save();

        loggedInUser.save({
          name: `${data?.user.attributes["custom:firstName"] || response.firstName} ${
            data?.user.attributes["custom:lastName"] || response.lastName
          }`,
        });

        personalizationLocalStorage.save(
          response?.personalization ? response.personalization : JSON.stringify(PERSONALIZATION)
        );
        // personalizationLocalStorage.save(JSON.stringify(PERSONALIZATION));
        if (data?.user.attributes["custom:is2FaEnabled"] === "y" && !isAuthorized) {
          redirectToTwoFa();
        } else {
          dispatch(setIsAuthorized(true));
          localStorage.setItem("isAuthorized", true);
          redirectToAdmin();
        }
      }
    },
    [data]
  );

  const handleError = () => {
    setPhoneError("This Company has been InActive");
    setDisableBtn(false);
  };

  const handleSubmit = async () => {
    if (!phone) {
      setPhoneError("please enter the phone");
      return;
    }
    if (!password) {
      setPasswordError("please enter the password");
      return;
    }

    try {
      setDisableBtn(true);
      dispatch(setLoading(true));
      const username = await getUserName(phone, companies, handleError);

      await dispatch(signInUser(username, password));
      setDisableBtn(false);
      dispatch(setLoading(false));
    } catch (error) {
      setDisableBtn(false);
      dispatch(setLoading(false));

      console.log("error", error);
      setPhoneError(error.message);
    }
  };

  const handleKeyDownEvent = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };
  const isLoggedIn = medFlowInLocalStorage.get();

  const redirectToTwoFa = () => {
    if (history) history.push("/twofa-auth");
    else window.location.reload();
  };

  const handleForgotPassword = async () => {
    if (history) history.push("/forgot-pass");
  };

  const redirectToAdmin = () => {
    if (history) history.push("/admin");
    else window.location.reload();
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  if (isLoggedIn && isAuthorized) redirectToAdmin();

  return (
    <div className="loginWrapper">
      <div className="login-left-side">
        <div className="text-center">{<img src={logoImage} alt="logo" className="logo-width" />}</div>
      </div>

      {loading && <Loader />}

      <div className="login-right-side">
        <h3 className="mt-0 mb-4">Login</h3>
        {/* <p className="tag-line">Sign in to your account</p> */}
        <div className="form-group">
          <label className="mb-2 login-input-label">Phone Number</label>
          <PhoneNoInput handleChange={(e) => setPhone(e)} value={phone} />
        </div>
        <div className="mt-3">
          <label className="login-input-label mb-2">Password</label>
          <div className="d-flex position-relative align-items-stretch w-100">
            <input
              style={{
                width: "100%",
                paddingRight: "40px",
              }}
              placeholder="Enter your  password"
              minLength={8}
              value={password}
              className="form-control"
              onKeyDown={handleKeyDownEvent}
              type={showPassword ? "text" : "password"}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span
              onClick={togglePasswordVisibility}
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
              }}
            >
              {showPassword ? (
                <img
                  className="header-btn-img object-fit-contain"
                  width={20}
                  height={20}
                  src={viewIcon}
                  alt={`viewIcon`}
                />
              ) : (
                <img
                  className="header-btn-img object-fit-contain"
                  width={20}
                  height={20}
                  src={hideIcon}
                  alt={`hideIcon`}
                />
              )}
            </span>
          </div>
        </div>
        <div className="mt-3 d-flex justify-content-end cursor-pointer">
          <p onClick={handleForgotPassword} className="text-primary font-base tracking-tight font-semibold">
            Forgot Password?
          </p>
        </div>
        <div className="text-right w-100 mt-3">
          <button
            type="submit"
            disabled={disableBtn}
            className="text-uppercase w-100 btn signin-btn"
            onClick={handleSubmit}
          >
            Sign In
          </button>
        </div>
        {phoneError && <ShowAlertMessage message={phoneError} handleClose={() => setPhoneError("")} error />}
        {messageObj.text && messageObj.mode === MESSAGE_MODES.error && (
          <ShowAlertMessage message={messageObj.text} handleClose={() => dispatch(showMessage())} error />
        )}
      </div>
    </div>
  );
};

export default Login;
